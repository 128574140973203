import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
const SendQuestionModal = ({ openModal, setOpenModal }) => {
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");

  const [loader, setLoader] = useState(false);

  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const makeAuthorizedRequest = useAuthorizedHttp();
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      borderRadius: "15px",
      zIndex: 9999,
    },
  };
  function closeModal() {
    setOpenModal(false);
  }

  const handleSubmit = () => {
    setLoader(true);
    makeAuthorizedRequest(
      {
        url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/fund/magnet/risk/profile/lead/save
`,
        data: {
          email: email,
          name: name,
          mobile: mobile,
        },
        method: "post",
      },
      (data) => {
        setLoader(false);
        toast.success("Questionnaire send successful");
        closeModal();
      },
      (error) => {
        setLoader(false);
        toast.error("Failed to send questionnaire");
      },
      () => {}
    );
  };

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="relative  ">
        <button
          className="font-medium text-2xl absolute top-0 right-0"
          onClick={closeModal}
        >
          X
        </button>
      </div>

      <div className="flex items-center justify-center py-2 overflow-hidden">
        <div className="mx-auto w-full max-w-[400px] bg-white px-5">
          <p className="text-center font-bold text-2xl my-5">
            Send questionnaire
          </p>
          <div>
            <div className="-mx-3 flex flex-wrap">
              <div className="w-full px-3 ">
                <div className="mb-5">
                  <label
                    for="fName"
                    className="mb-3 block text-base font-medium text-[#07074D]"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    placeholder="Enter  first name"
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
                </div>
              </div>

              <div className="w-full px-3 ">
                <div className="mb-5">
                  <label
                    for="fName"
                    className="mb-3 block text-base font-medium text-[#07074D]"
                  >
                    Email:
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="Enter Email"
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
                </div>
              </div>
              <div className="w-full px-3">
                <div className="mb-5">
                  <label
                    for="fName"
                    className="mb-3 block text-base font-medium text-[#07074D]"
                  >
                    Mobile
                  </label>
                  <input
                    type="number"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    required
                    placeholder="Enter Mobile Number"
                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-center">
              <button
                disabled={!name || !email || !mobile}
                onClick={() => {
                  handleSubmit();
                }}
                className="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
              >
                Send
                <span>
                  {loader && (
                    <CircularProgress
                      size={20}
                      className="ml-[5px] text-black"
                    />
                  )}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SendQuestionModal;
