import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import styles from "./TaleWalletOnRampModal.module.css";
import { useSelector } from "react-redux";
import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { nativeToken } from "../../config";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useWalletView } from "../../hooks/useWalletView";
import { toast } from "react-toastify";
import { useParams } from "react-router";

const TaleWalletOnRampModal = ({
  openModal,
  setOpenModal,
  amount,
  basketId,
  tokenPercentArray,
  tokenArray,
  basketBlockchain,
  useraddress,
  clientDetails,
  symbol,
  blockchain,
}) => {
  const appCtx = useSelector((state) => state.app);
  const [sendToken, setSendToken] = useState("INR");
  const [receiveTokenPrice, setReceiveTokenPrice] = useState(null);
  const [sendAmount, setSendAmount] = useState(amount || 5000);
  const [isCopied, setIsCopied] = useState(false);
  const [isBankDataCopied, setIsBankDataCopied] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [receiveAmountLoading, setReceiveAmountLoading] = useState(false);

  const [receiveToken, setReceiveToken] = useState(
    symbol || nativeToken[blockchain || appCtx.blockchain]
  );

  const networks = {
    POLYGON: "matic20",
    BSC: "bep20",
    ETHEREUM: "erc20",
    ALGORAND: "algo",
    SOLANA: "sol",
  };

  var network;
  network = networks[blockchain || appCtx.blockchain];

  const [receiveAmount, setReceiveAmount] = useState("");
  const [currentComponent, setCurrentComponent] = useState(1);
  const [upiClicked, setUpiClicked] = useState(false);
  const [bankClicked, setBankClicked] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [fourDigit, setFourDigit] = useState(null);
  const [loader, setLoader] = useState(false);
  const [networkFee, setNetworkFee] = useState(0);
  const [isBasketDetailsPage, setIsBasketDetailsPage] = useState(false);
  const [tokenPrices, setTokenPrices] = useState({});
  const [isManager, setIsManager] = useState(false);

  const { getAllCoin, getAllCoinPrice, allCoin, allCoinPrice } =
    useWalletView();

  const makeRequest = useAuthorizedHttp();
  const queryParams = new URLSearchParams(window.location.search);
  const { userId, tokenid, tokoenname } = useParams();

  let isOpenedfromMobile = queryParams.get("address");

  function closeModal() {
    setOpenModal(false);
  }
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      top: "53%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      borderRadius: "50px",
      padding: "0",
      transform: "translate(-50%, -50%)",
      overflow: "inherit",
      // width: width > 1200 ? "600px" : "370px",
    },
  };
  const formattedDate = moment().format("D MMM'YY, h:mm a");

  const walletAddress = isBasketDetailsPage
    ? appCtx?.walletAddress?.find(
        (addr) => addr?.blockchain === appCtx?.blockchain
      )?.address
    : useraddress;

  useEffect(() => {
    if (userId == appCtx?.userDetails?.userId) {
      setIsManager(true);
    }
  }, []);

  useEffect(() => {
    getAllCoin(blockchain || appCtx.usersblockchain);
    getAllCoinPrice(blockchain || appCtx.usersblockchain);
  }, []);

  useEffect(() => {
    const url = window.location.href;
    const searchStrings = ["subscribe-crypto-basket", "crypto-basket-details"];
    let isPageFound = false;

    for (const searchString of searchStrings) {
      if (url.includes(searchString)) {
        isPageFound = true;
        break;
      }
    }

    setIsBasketDetailsPage(isPageFound);
  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleBankDataCopy = (value, index) => {
    navigator.clipboard.writeText(value);
    const updatedCopiedState = [...isBankDataCopied];
    updatedCopiedState[index] = true;
    setIsBankDataCopied(updatedCopiedState);
  };

  const handleReceiveTokenChange = (event) => {
    setReceiveToken(event.target.value);
  };

  // fetching receive token details

  useEffect(() => {
    if (sendAmount && receiveToken) {
      setReceiveAmountLoading(true);
      if (sendAmount < 5000) {
        toast.error("Minimum amount is 5000");
      } else {
        makeRequest(
          {
            url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/quote`,
            data: {
              fiatAmount: sendAmount,
              token: receiveToken,
              network: network,
            },
            method: "post",
            headers: {
              "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
              "Content-Type": "application/json",
            },
          },
          (data) => {
            setReceiveTokenPrice(data?.data?.rate);
            setReceiveAmount(data?.data?.quantity);
            setNetworkFee(data?.data?.gasFee);
            setReceiveAmountLoading(false);
          },
          (error) => {
            setReceiveAmountLoading(false);
          }
        );
      }
    }
  }, [sendAmount, receiveToken]);

  useEffect(() => {
    const min = 1000; // Minimum 4-digit number
    const max = 9999; // Maximum 4-digit number
    const fourDigit = Math.floor(Math.random() * (max - min + 1)) + min;
    setFourDigit(fourDigit);
  }, []);

  const handleSendAmountChange = (event) => {
    setSendAmount(event.target.value);
  };

  const availableTokens = allCoin?.map((token) => token);
  const filteredTokens = availableTokens.filter(
    (token) => token.tokenShortName !== sendToken
  );
  console.log("filteredTokens", filteredTokens);

  let platformFee;
  let totalFee;

  if (sendAmount !== null && sendAmount !== undefined) {
    platformFee = ((sendAmount * 0.4) / 100)?.toFixed(2);
  } else {
    // Handle the case when sendAmount is null or undefined
    // For example:
    platformFee = "0.00";
  }

  if (networkFee !== null && networkFee !== undefined) {
    totalFee = (parseFloat(platformFee) + parseFloat(networkFee))?.toFixed(2);
  } else {
    // Handle the case when networkFee is null or undefined
    // For example:
    totalFee = platformFee;
  }

  const handlePaid = () => {
    setLoader(true);
    makeRequest(
      {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/inhouse/onramp/transaction`,
        data: {
          fiatAmount: sendAmount,

          fiatCurrency: "INR",

          cryptoToken: isBasketDetailsPage
            ? nativeToken[basketBlockchain]
            : receiveToken,

          cryptoAmount: receiveAmount,

          blockchain: isBasketDetailsPage
            ? basketBlockchain
            : isManager
            ? blockchain
            : appCtx.usersblockchain,

          note: fourDigit,
          platformFee: platformFee,
          networkFee: networkFee,
          tokenPrice: receiveTokenPrice,
          address: walletAddress,
          ...(isBasketDetailsPage && { basketId: basketId }),
        },
        method: "post",
      },
      (data) => {
        setLoader(false);
        setCurrentComponent(3);
        // setOpenModal(false);
      },
      (error) => {
        // setLoader(false);
      },
      () => {}
    );
  };

  const handleSendPaymentLink = () => {
    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/fundMagnet/client/send/payment/link`,
        data: {
          address: useraddress,
          amount: sendAmount,
          blockchain: clientDetails?.blockchain || "BSC",
          clientName:
            (clientDetails?.firstName || clientDetails?.lastName) === null
              ? "user"
              : `${clientDetails?.firstName} ${clientDetails?.lastName}`,
          email: clientDetails?.email,
          mobile: clientDetails?.mobile,
          token: receiveToken,
          type: "subscribe/token",
          wmName: appCtx?.wealthManager?.name,
        },
        method: "post",
      },
      (data) => {
        // setLoader(false);
        toast.success("Payment link Send Successful");
        closeModal();
      },
      (error) => {
        toast.error(" Payment link Send failed !");
        // setLoader(false);
      },
      () => {}
    );
  };

  return (
    <>
      <Modal
        isOpen={openModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        className={`${styles.popup}  rounded-md`}
      >
        <div
          className="w-[400px] flex flex-row justify-between items-center bg-[#9376B1] py-3 relative px-5  rounded-t-lg

"
        >
          <div className="w-[15%]">
            <img src="/images/talelogo.png" alt="" />
          </div>

          <p className="w-full text-center text-lg font-semibold">Talewallet</p>
          <div className="w-[15%]">
            <button
              className={`font-medium   text-2xl ${
                isOpenedfromMobile ? "hidden" : "block"
              } `}
              onClick={closeModal}
            >
              X
            </button>
          </div>
        </div>
        <div className="w-[400px] h-[550px] overflow-x-hidden overflow-y-visible bg-[#EEEEEE] rounded-xl ">
          {currentComponent === 1 && (
            <div>
              <div className="px-5 py-2">
                <p className="text-md  my-2">Enter Amount</p>
                <div className="flex flex-row w-full rounded-md  bg-white overflow-hidden border hover:border-violet-500 border-[#EEEEEE]">
                  <div className=" w-[50%]">
                    <input
                      id="sendAmount"
                      placeholder="Enter amount"
                      name="sendAmount"
                      type="number"
                      min="0"
                      step="any"
                      value={sendAmount}
                      disabled={isBasketDetailsPage}
                      onChange={handleSendAmountChange}
                      className="block   shadow-sm  h-12 px-3 text-black text-lg leading-tight focus:outline-none p "
                    />
                  </div>
                  <div className="w-[2px] h-12   flex justify-center items-center">
                    <div className="w-[2px] h-10  border border-r border-gray-300"></div>
                  </div>
                  <div className=" w-[48%]">
                    <div
                      id="sendToken"
                      name="sendToken"
                      className=" h-12   leading-tight focus:outline-none   shadow-xs  w-full flex flex-row pl-5  items-center gap-3"
                    >
                      <img
                        src="/images/indianflag.png"
                        alt=""
                        className="w-6"
                      />
                      <div className="">
                        <p className="text-sm">INR</p>
                        <p className="text-xs">Indian Rupee</p>
                      </div>
                    </div>
                  </div>
                </div>
                <p className=" text-md mt-5 mb-2">Amount you will receive</p>
                {/* receive token for others pages except basket details  */}
                {!isBasketDetailsPage && (
                  <div className="flex flex-row w-full rounded-md  bg-white overflow-hidden border hover:border-violet-500 border-[#EEEEEE]">
                    <div className=" w-[50%]">
                      {!receiveAmountLoading ? (
                        <input
                          id="receiveAmount"
                          placeholder="you will receive"
                          name="receiveAmount"
                          type="number"
                          min="0"
                          step="any"
                          value={receiveAmount}
                          readOnly
                          className="block      shadow-sm  h-12 px-3 text-black text-lg leading-tight focus:outline-none p "
                        />
                      ) : (
                        <div className="h-12 px-3 flex  items-center">
                          <CircularProgress size={20} className="" />
                        </div>
                      )}
                    </div>
                    <div className="w-[2px] h-12   flex justify-center items-center">
                      <div className="w-[2px] h-10  border border-r border-gray-300"></div>
                    </div>
                    <div className=" w-[48%]">
                      <div className=" h-12   leading-tight focus:outline-none     shadow-xs  w-full flex flex-row  pl-5 items-center gap-3">
                        <img
                          src={
                            allCoin
                              ? allCoin.find(
                                  (obj) => obj.tokenShortName === receiveToken
                                )?.tokenIcon
                              : ""
                          }
                          alt=""
                          className="w-6"
                        />
                        <div className="flex flex-col">
                          <select
                            id="receiveToken"
                            name="receiveToken"
                            value={receiveToken}
                            onChange={handleReceiveTokenChange}
                            className="block  bg-white text-sm leading-tight focus:outline-none  w-full"
                          >
                            {filteredTokens?.map((token) => (
                              <option
                                key={token?.tokenId}
                                value={token?.symbol}
                              >
                                {token?.tokenName}
                              </option>
                            ))}
                          </select>
                          <p className="text-xs">On {network}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* receive token for basket details page */}
                {isBasketDetailsPage && (
                  <>
                    {allCoinPrice.length === 0 ? (
                      <div className="flex justify-center my-5">
                        <CircularProgress size={20} className="mx-10" />
                      </div>
                    ) : (
                      <>
                        {tokenArray?.map((token, index) => (
                          <div
                            className="flex flex-row w-full rounded-md bg-white overflow-hidden border hover:border-violet-500 border-[#EEEEEE] mb-2"
                            key={index}
                          >
                            <div className="w-[50%] pl-2 text-sm font-medium flex flex-col justify-center">
                              <div className="flex gap-2">
                                <p className="">
                                  {(
                                    (sendAmount * tokenPercentArray[index]) /
                                    100 /
                                    (allCoinPrice[token]?.price * 84)
                                  )?.toFixed(2)}
                                </p>
                                <p className="">
                                  ({tokenPercentArray[index]}%)
                                </p>
                              </div>
                              <p className="text-xs font-normal">
                                1 {token} ={" "}
                                {allCoinPrice[token]?.price?.toFixed(2)}{" "}
                                {allCoinPrice[token]?.currency}
                              </p>
                            </div>
                            <div className="w-[2px] h-12 flex justify-center items-center">
                              <div className="w-[2px] h-10 border border-r border-gray-300"></div>
                            </div>
                            <div className="w-[48%]">
                              <div className="h-12 leading-tight focus:outline-none shadow-xs w-full flex flex-row pl-5 items-center gap-3">
                                <img
                                  src={
                                    allCoin
                                      ? allCoin.find(
                                          (obj) => obj.tokenShortName === token
                                        )?.tokenIcon
                                      : ""
                                  }
                                  alt=""
                                  className="w-6"
                                />
                                <div className="flex flex-col">
                                  <p>{token}</p>
                                  <p className="text-xs">On {network}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </>
                )}
                {!isBasketDetailsPage && (
                  <p className=" text-sm mt-2  ">
                    {receiveTokenPrice && (
                      <>
                        1 {receiveToken} = ₹{receiveTokenPrice}
                      </>
                    )}
                  </p>
                )}
                <div className="mt-5 mb-3">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className="text-[#3B0076] font-medium	 text-md ">
                        You Pay ₹{sendAmount}
                      </p>
                      <p className="ml-10">View Breakup</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <hr />
                      <div className="my-2 text-sm font-medium">
                        <div className="flex justify-between items-center mb-2 ">
                          <p>Platform Fee(0.4%)</p>
                          <p>₹{platformFee || 0}</p>
                        </div>

                        <div className="flex justify-between items-center">
                          <p>Network Fee</p>
                          <p>₹{networkFee || 0}</p>
                        </div>
                      </div>
                      <hr />
                      <div className="flex justify-between items-center mt-3 text-sm font-medium	">
                        <p>Total Fee</p>
                        <p>₹{totalFee || 0}</p>
                      </div>
                      {isBasketDetailsPage && (
                        <p className="text-sm font-medium mt-1">
                          Network fee includes{" "}
                          {tokenArray?.map((token, index) => {
                            const isLastToken = index === tokenArray.length - 1;
                            return (
                              <span
                                key={index}
                                className="text-sm font-medium -ml-[2px]"
                              >
                                {nativeToken[basketBlockchain]} to {token}
                                {!isLastToken &&
                                  index !== tokenArray.length - 2 &&
                                  ", "}
                                {!isLastToken &&
                                  index === tokenArray.length - 2 &&
                                  " and "}
                              </span>
                            );
                          })}
                        </p>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div class="flex justify-between my-3">
                  <button
                    onClick={() => {
                      setCurrentComponent(2);
                      setUpiClicked(true);
                    }}
                    disabled={!sendAmount || receiveAmountLoading}
                    className="  w-[45%]  text-sm font-bold text-violet-800 border-2 border-violet-900 rounded hover:bg-violet-900 hover:text-white active:bg-indigo-500 focus:outline-none focus:ring"
                  >
                    UPI
                  </button>
                  <button
                    onClick={() => {
                      setCurrentComponent(2);
                      setBankClicked(true);
                    }}
                    disabled={!sendAmount || receiveAmountLoading}
                    className=" w-[45%] py-2 text-md font-bold text-white bg-violet-900 border border-violet-900 rounded active:text-violet-500 hover:bg-transparent hover:text-violet-900 focus:outline-none focus:ring"
                  >
                    BANK
                  </button>
                </div>
                <div>
                  <p className=" text-lg font-medium mb-2">Payment Link: </p>
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-row justify-between items-center">
                      <input
                        type="text"
                        value={`https://www.talewallet.com/inhouse/onramp?address=${useraddress}&blockchain=${clientDetails?.blockchain}&symbol=${receiveToken}&amount=${sendAmount}`}
                        readOnly
                        className="outline-none border border-gray-300 rounded-lg p-2 mr-2 w-[90%]"
                        onClick={(e) => e.target.select()}
                      />
                      <button
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `https://www.talewallet.com/inhouse/onramp?address=${useraddress}&blockchain=${clientDetails?.blockchain}&symbol=${receiveToken}&amount=${sendAmount}`
                          );
                          setIsCopied(true);
                        }}
                        className="bg-gray-100 hover:bg-gray-200 active:bg-gray-300 rounded-lg px-3 py-2 text-gray-600"
                      >
                        <FontAwesomeIcon icon={isCopied ? faCheck : faCopy} />
                      </button>
                    </div>

                    <button
                      onClick={() => {
                        handleSendPaymentLink();
                      }}
                      className="px-5 py-2 relative rounded group overflow-hidden font-medium bg-purple-100 text-purple-600 inline-block"
                    >
                      <span className="absolute top-0 left-0 flex w-full h-0 mb-0 transition-all duration-200 ease-out transform translate-y-0 bg-purple-600 group-hover:h-full opacity-90"></span>
                      <span className="relative group-hover:text-white text-sm">
                        Send Link Via Email
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {currentComponent === 2 && (
            <div className="px-5 py-5">
              {upiClicked && (
                <div className="bg-white border  flex flex-col gap-3 justify-center items-center p-5 mb-4 ">
                  <p className="text-violet-900 font-bold text-md">
                    Send ₹{sendAmount}
                  </p>
                  <img
                    src="/images/onrampqrcode.jpg"
                    alt="qrcode"
                    className="w-[120px]"
                  />
                  <div className="flex items-center gap-3">
                    <p className="text-violet-600 font-semibold">
                      UPI-ID:
                      <span className="text-violet-900 font-bold">
                        talewallet@kotak
                      </span>
                    </p>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(`talewallet@kotak`);
                        setIsCopied(true);
                      }}
                      className="px-3  text-gray-600"
                    >
                      <FontAwesomeIcon icon={isCopied ? faCheck : faCopy} />
                    </button>
                  </div>
                </div>
              )}
              {bankClicked && (
                <div className="bg-white border  flex flex-col gap-3 justify-center items-center p-2 mb-2 ">
                  <p className="text-violet-900 font-bold text-md">
                    Send ₹{sendAmount}
                  </p>
                  <div className="text-violet-700 text-xs">
                    <table className="border-collapse border-0">
                      <tbody>
                        <tr>
                          <td className="py-1 pl-2 ">Name</td>
                          <td className="py-1 pl-2">
                            Nextales Technology Private Limited
                          </td>
                          <td className="py-2 px-2">
                            <button
                              onClick={() =>
                                handleBankDataCopy(
                                  " Nextales Technology Private Limited",
                                  0
                                )
                              }
                              className=" px-3  text-gray-600"
                            >
                              <FontAwesomeIcon
                                icon={isBankDataCopied[0] ? faCheck : faCopy}
                              />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td className="py-1 pl-2 ">Account Number</td>
                          <td className="py-1 pl-2">1947549911</td>
                          <td className="py-1 px-2">
                            <button
                              onClick={() =>
                                handleBankDataCopy("1947549911", 1)
                              }
                              className="px-3  text-gray-600"
                            >
                              <FontAwesomeIcon
                                icon={isBankDataCopied[1] ? faCheck : faCopy}
                              />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td className="py-1 pl-2 ">IFSC Code</td>
                          <td className="py-1 pl-2">KKBK0000958</td>
                          <td className="py-2 px-2">
                            <button
                              onClick={() =>
                                handleBankDataCopy("KKBK0000958", 2)
                              }
                              className="px-3  text-gray-600"
                            >
                              <FontAwesomeIcon
                                icon={isBankDataCopied[2] ? faCheck : faCopy}
                              />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td className="py-1 pl-2 ">Type</td>
                          <td className="py-1 pl-2">Current</td>
                          <td className="py-2 px-2">
                            <button
                              onClick={() => handleBankDataCopy("Current", 3)}
                              className="px-3  text-gray-600"
                            >
                              <FontAwesomeIcon
                                icon={isBankDataCopied[3] ? faCheck : faCopy}
                              />
                            </button>
                          </td>
                        </tr>

                        <tr>
                          <td className="py-1 pl-2 ">Bank</td>
                          <td className="py-1 pl-2">Kotak Mahindra Bank</td>
                          <td className="py-2 px-2">
                            <button
                              onClick={() =>
                                handleBankDataCopy("Kotak Mahindra Bank", 5)
                              }
                              className="px-3  text-gray-600"
                            >
                              <FontAwesomeIcon
                                icon={isBankDataCopied[5] ? faCheck : faCopy}
                              />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              <div className="text-sm text-violet-900 flex flex-col gap-2 ">
                <p>After making the payment, click on "I HAVE PAID" button </p>
              </div>

              {!loader ? (
                <button
                  onClick={() => {
                    handlePaid();
                  }}
                  className=" w-full py-2 my-3 text-md font-bold text-white bg-violet-900 border border-violet-900  focus:outline-none  "
                >
                  I HAVE PAID
                </button>
              ) : (
                <button className=" w-full py-2 my-3 text-2xl font-bold text-white bg-violet-900 border border-violet-900  focus:outline-none ">
                  <CircularProgress size={16} />
                </button>
              )}
            </div>
          )}
          {currentComponent === 3 && (
            <div className="px-5 py-5">
              <p className="text-lg font-semibold text-center mb-2">
                Verifying your payment
              </p>
              <p className="text-sm font-medium text-center mb-2">
                ₹{sendAmount} * {formattedDate}
              </p>
              <p className="text-sm font-medium text-center">
                Expected time duration 24 hour
              </p>
              <div className="bg-white border flex flex-col gap-3 p-5 my-5">
                <div className="w-6 relative">
                  <div className="flex items-center justify-center w-6 h-6 bg-green-500 rounded-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-4 h-4 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <p className="absolute top-0 left-[40px] w-[300px] text-sm font-medium">
                    Payment confirmation by the user
                  </p>
                  <p className="absolute top-[65px] left-[40px] w-[300px] text-sm font-medium">
                    Payment verification is in progress
                  </p>
                  <p className="absolute top-[130px] left-[40px] w-[300px] text-sm font-medium">
                    Token credited to wallet
                  </p>
                  <div className="h-[40px] flex items-center justify-center">
                    <div className="w-1 mr-1 h-full border-r border-green-500"></div>
                  </div>
                  <div className="flex items-center justify-center w-6 h-6 border-2 border-green-500 rounded-full"></div>
                  <div className="h-[40px] flex items-center justify-center">
                    <div className="w-1 mr-1 h-full border-r border-gray-400 border-dotted"></div>
                  </div>
                  <div className="flex items-center justify-center w-6 h-6 border-2 border-gray-400 rounded-full border-dotted"></div>
                </div>
              </div>

              <button
                onClick={() => {
                  closeModal();
                }}
                className=" w-full py-2 my-3 text-md font-bold text-white bg-violet-900 border border-violet-900  focus:outline-none  "
              >
                Okay
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default TaleWalletOnRampModal;