import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import { Card, CardContent, CardMedia, Grid, Skeleton } from "@mui/material";
import useHttp from "../hooks/use-http";
import Header from "../components/Header/Header";
import LandingPageFooter from "../components/Footer/LandingPageFooter/LandingPageFooter";

const DistributorDetails = () => {
  const { id, name } = useParams();

  const [distributorsData, setDistributorsData] = useState(null);
  const [distributorsDataLoading, setDistributorsDataLoading] = useState(false);
  const makeRequest = useHttp();
  const navigate = useNavigate();
  useEffect(() => {
    setDistributorsDataLoading(true);
    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/wealth/manager/profile?userId=${id}`,
        method: "get",
        headers: {
          "X-App-Token": process.env.REACT_APP_APP_TOKEN,
          "Content-Type": "application/json",
        },
      },
      (data) => {
        console.log("distributor data", data);
        setDistributorsData(data);
        setDistributorsDataLoading(false);
      },
      (rej) => {
        setDistributorsDataLoading(false);
      }
    );
  }, []);
  return (
    <div>
      <Header />
      <div className="pt-[130px] w-[90%] mx-auto">
        <p className="text-3xl font-bold">Distributor</p>

        {/* card  */}
        {distributorsDataLoading ? (
          <Grid container spacing={2}>
            {[1, 2, 3, 4].map((card, index) => (
              <div className="max-w-2xl px-4 py-2 w-full  sm:max-w-sm md:max-w-sm lg:max-w-sm xl:max-w-sm lg:w-1/4 md:w-1/3 sm:w-full mt-4">
                <div
                  key={index}
                  className=" bg-white shadow-xl rounded-lg text-gray-900"
                >
                  <Card>
                    <Skeleton variant="rectangular" height={200} />
                    <CardContent>
                      <Skeleton variant="text" />
                      <Skeleton variant="text" width="80%" />
                    </CardContent>
                  </Card>
                </div>
              </div>
            ))}
          </Grid>
        ) : (
          <div className="flex flex-wrap justify-center my-5 ">
            {distributorsData && (
              <div className="bg-gray-100 p-20 w-full">
                <div className=" px-4">
                  <div className="bg-white rounded-lg shadow-lg">
                    <div className="p-4">
                      <div className="flex items-center">
                        <div className="h-12 w-12 rounded-full bg-gray-300"></div>
                        <div className="ml-2">
                          <h1 className="text-2xl font-bold">
                            {distributorsData.name}
                          </h1>
                          <p className="text-gray-500">
                            {distributorsData.bio}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="p-4">
                      <h2 className="text-lg font-bold mb-2">Details</h2>
                      <ul className="list-disc list-inside">
                        <li>
                          <span className="font-bold">Fees:</span>{" "}
                          {distributorsData.fees
                            ? `$${distributorsData.fees}`
                            : "Not specified"}
                        </li>
                        <li>
                          <span className="font-bold">Fees Schedule:</span>{" "}
                          {distributorsData.feesSchedule || "Not specified"}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <LandingPageFooter />
    </div>
  );
};

export default DistributorDetails;
