import React, { useEffect, useState } from "react";
import { useCryptobasket } from "./useCryptobasket";
import { Link } from "react-router-dom";
import { Button, Skeleton } from "@mui/material";
import CreateBasketModal from "./CreateBasketModal";
import { useSelector } from "react-redux";
import MybasketCard from "./MybasketCard";
import HowitWorksModal from "./HowitWorksModal";
import CustomButton from "../../components/CustomButton";
import ManagersBasketCard from "./ManagersBasketCard";

const CryptoBasketPage = () => {
  const [openCreateBasketModal, setOpenCreateBasketModal] = useState(false);
  const [closeClicked, setCloseClicked] = useState(false);
  const [howItWorksModal, setHowItWorksModal] = useState(false);
   const [selectedTab, setSelectedTab] = useState(1);
   const {
     fetchPublicBasket,
     publicBasketLoading,
     publicBasket,

     fundmanagersBasket,
     fundmanagersBasketLoading,
     fetchFundManagersBasket,
   } = useCryptobasket();
   const appCtx = useSelector((state) => state.app);

   useEffect(() => {
     fetchPublicBasket();
     fetchFundManagersBasket();
   }, []);
   return (
     <div
       className=""
       // style={{
       //   background: "linear-gradient(180deg, #3C0B8C 0%, #FFFFFF 119.14%)",
       // }}
     >
       <div className="flex flex-col justify-between min-h-screen h-fit  home">
         <div className="w-[100%] pt-5 flex flex-col justify-center">
           <div className=" w-[100%] rounded-tl-[40px] md:rounded-tl-[80px] rounded-tr-[40px] md:rounded-tr-[80px]">
             <div className="">
               <h1 className="font-bold text-center"> Crypto Basket</h1>
               <h5 className="font-bold text-center text-slate-500">
                 {" "}
                 A theme-based group of different crypto tokens
               </h5>
             </div>

             <CreateBasketModal
               openModal={openCreateBasketModal}
               setOpenModal={setOpenCreateBasketModal}
               closeClicked={closeClicked}
               setCloseClicked={setCloseClicked}
             />

             <div>
               <div
                 className="w-[90%] md:w-[40%] mx-auto  text-lg flex justify-between mt-10"
                 style={{
                   background:
                     "linear-gradient(134.72deg, #DCDCDC 3.17%, rgba(212, 210, 216, 0.92) 97.26%)",
                   borderRadius: "2px",
                 }}
               >
                 <button
                   className={` ${
                     selectedTab === 1 &&
                     "text-white bg-gradient-to-br from-purple-600 to-purple-900 shadow-md rounded-md"
                   } w-[48%] py-1 m-1`}
                   onClick={() => {
                     setSelectedTab(1);
                   }}
                 >
                   Public Basket
                 </button>
                 <button
                   className={` ${
                     selectedTab === 0 &&
                     "text-white bg-gradient-to-br from-purple-600 to-purple-900 shadow-md rounded-md"
                   } w-[48%] py-1 m-1`}
                   onClick={() => {
                     setSelectedTab(0);
                   }}
                 >
                   My Basket
                 </button>
               </div>
             </div>
             {/* my basket card  */}
             {selectedTab === 0 && (
               <>
                 {" "}
                 <div className="w-[95%] mx-auto mt-10  bg-[#FFFFFF] p-10">
                   <div className=" flex flex-col md:flex-row flex-wrap justify-center md:justify-between  gap-5">
                     <div className="">
                       <h3 className=" font-semibold ">My Basket</h3>
                       <p className="font-semibold text-slate-500">
                         Simplest way to diversify your crypto investment across
                         multiple crypto tokens
                       </p>
                     </div>
                     <div className="flex justify-center md:justify-end">
                       <CustomButton
                         onClick={() => {
                           setOpenCreateBasketModal(true);
                         }}
                         primary={true}
                         className={`px-5 w-[150px] font-medium`}
                       >
                         Create
                       </CustomButton>
                     </div>
                   </div>
                   {!fundmanagersBasketLoading &&
                     fundmanagersBasket?.length === 0 && (
                       <h4 className="py-5">You don't have any basket</h4>
                     )}

                   <div className="w-[90%] md:w-[80%] mx-auto my-5 flex flex-col md:flex-row  justify-between flex-wrap gap-y-5 ">
                     {fundmanagersBasketLoading ? (
                       <div className="w-[80%] mx-auto flex flex-col md:flex-row gap-5 justify-center">
                         {Array.from({ length: 2 }, (_, index) => (
                           <div
                             key={index}
                             className="flex flex-col w-[90%]  md:w-[40%] mx-auto md:mx-0"
                           >
                             <Skeleton variant="rectangular" height={150} />
                             <Skeleton
                               variant="text"
                               sx={{ fontSize: "1rem" }}
                             />
                             <Skeleton
                               variant="text"
                               sx={{ fontSize: "1rem" }}
                             />
                             <Skeleton
                               variant="text"
                               sx={{ fontSize: "1rem" }}
                             />
                           </div>
                         ))}
                       </div>
                     ) : (
                       fundmanagersBasket.map((basket) => (
                         <ManagersBasketCard basket={basket} />
                       ))
                     )}
                   </div>
                 </div>
               </>
             )}

             {/* publicBasket  */}
             {selectedTab === 1 && (
               <>
                 <div className="bg-white p-10 w-[95%] mx-auto mt-20">
                   {!publicBasketLoading && publicBasket?.length > 0 && (
                     <div className=" flex flex-col md:flex-row items-center justify-between gap-5 ">
                       <div className="">
                         <h3 className=" font-semibold">
                           Public Basket by Crypto Advisor
                         </h3>
                         <p className="font-semibold text-slate-500">
                           Subscribe to crypto baskets created by Crypto Adviser
                           to keep yourself safe from fraud.
                         </p>
                       </div>
                       {!publicBasketLoading && (
                         <div>
                           <div className="">
                             <Button
                               onClick={() => {
                                 setHowItWorksModal(true);
                               }}
                             >
                               How it Works
                             </Button>
                           </div>
                         </div>
                       )}
                     </div>
                   )}

                   <div>
                     <div className="w-[90%] md:w-[80%] mx-auto my-10 flex flex-col md:flex-row  justify-between flex-wrap gap-y-5 ">
                       {publicBasketLoading ? (
                         <div className="w-[100%] mx-auto flex flex-col md:flex-row gap-5 justify-center">
                           {Array.from({ length: 2 }, (_, index) => (
                             <div
                               key={index}
                               className="flex flex-col w-[85%]  md:w-[45%] mx-auto md:mx-0"
                             >
                               <Skeleton variant="rectangular" height={150} />
                               <Skeleton
                                 variant="text"
                                 sx={{ fontSize: "1rem" }}
                               />
                               <Skeleton
                                 variant="text"
                                 sx={{ fontSize: "1rem" }}
                               />
                               <Skeleton
                                 variant="text"
                                 sx={{ fontSize: "1rem" }}
                               />
                             </div>
                           ))}
                         </div>
                       ) : (
                         publicBasket.map((basket) => (
                           <ManagersBasketCard basket={basket} />
                         ))
                       )}
                     </div>
                   </div>
                 </div>
               </>
             )}
           </div>
         </div>
       </div>
       <HowitWorksModal
         openModal={howItWorksModal}
         setOpenModal={setHowItWorksModal}
       />
     </div>
   );
};

export default CryptoBasketPage;
