import React, { useState } from "react";
import Modal from "react-modal";
import { Link, useNavigate } from "react-router-dom";
import ThreeDRenderer from "../../../pages/ThreeDRenderer";
import MinUserInfo from "../min-user-info/MinUserInfo";
import CustomButton from "../../CustomButton";

function ModalPaymentFailure({ openModalPaymentFailure, setOpenModalPaymentFailure, nftSrc, nftId, title, type, scale, ipfsHash, image, media_url, content, isCart, cart }) {
    const navigate = useNavigate();
    const customStyles = {
        content: {
            top: "55%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: "0",
            borderRadius: "10px",
            transform: "translate(-50%, -50%)",
        },
    };

    function closeModal() {
        setOpenModalPaymentFailure(false);
        let params = window.location.href
        // window.open(`${params?.split('?')[0]}`,'_self')
   
    }
    return (
        <Modal isOpen={openModalPaymentFailure} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal" ariaHideApp={false}>
            <div className="flex flex-col">
                <div className="flex  justify-center gap-32 items-center w-full bg-[#ffcce9] p-6">
                    <div className="text-xl font-bold leading-[10px]">Oops! it's not you, it's us.</div>
                    {/* <Link to={`/nft/${nftId}`}>
                    <button className=" font-bold leading-[10px] text-gray-500 absolute  right-6" onClick={closeModal}>
                        X
                    </button>
                    </Link> */}
                    <div
                    // href={`/nft/${nftId}`}
                    >
                        <button
                            className=" font-bold leading-[10px] text-gray-500 absolute  right-6"
                            onClick={() => {
                                // navigate(`/nft/${nftId}`);
                                closeModal();
                            }}
                        >
                            X
                        </button>
                    </div>
                </div>

                <div className="flex overflow-hidden modalPayment">
                    {!isCart ?
                        <div className="flex flex-col py-4 pl-5 pr-[4rem] gap-4 w-[50%] ">
                            <div className={"w-72 h-72 p-2"} style={{ backgroundColor: type === "threeD" ? "#002f49" : "" }}>
                                {type === "text" ? (
                                    <div className={"h-[100%] p-10 dark:bg-neutral-800 shadow-xl flex items-center justify-center"}><img src="/images/nonft.svg" alt="logo" className="h-[100%]" /></div>
                                ) :
                                    type === "image" ? (
                                        <img
                                            className="object-cover w-full h-[300px] div-item-border"
                                            src={image}
                                            alt="NFT"
                                        />
                                    ) : type === "video" ? (
                                        <video
                                            controls
                                            // autoPlay
                                            className="object-cover w-full h-[200px]"
                                            src={media_url}
                                        />
                                    ) : type === "threeD" ? (
                                        image === media_url ?
                                            <img
                                                className="object-cover w-full h-[300px] div-item-border"
                                                src={image}
                                                alt="NFT"
                                            />
                                            :
                                            <ThreeDRenderer
                                                className="object-fill w-full h-full"
                                                src={media_url}
                                                scale={scale}
                                                type={content?.mime_type?.split("/")[1]}
                                            />
                                    ) : (
                                        <></>
                                    )}
                            </div>
                            <div className="flex justify-center flex-col items-center mt-[20px] gap-4">
                                <div className="text-lg leading-[10px] font-bold">#{nftId}</div>
                                <div className="flex justify-start text-xs">
                                    <MinUserInfo userName={title} />
                                </div>
                            </div>

                        </div>
                        :
                        <div className="flex flex-col py-4 pl-5 pr-[2rem] w-[100%] gap-4 overflow-y-scroll h-[330px]">
                            {cart?.map((item) => {
                                const metaData = JSON.parse(item?.nftDomain?.metaData || null);
                                const type = metaData?.mime_type?.split('/')[0];

                                return (
                                    <div className="flex">
                                        <div className={"w-[100px] h-[100px] p-2"}
                                            style={{ border: "2px solid #80808066", borderRadius: "16px", backgroundColor: type === "threeD" ? "#002f49" : "" }}
                                        >
                                            {type === "text" ? (
                                                <div className={"h-[100%] p-10 shadow-xl flex items-center justify-center"}><img src="/images/collectionimage.svg" alt="logo" className="h-[100%]" /></div>
                                            ) :
                                                type === "image" ? (
                                                    <img
                                                        className="object-cover h-[100%] sm:h-[100%] w-[100%] sm:w-[100%] "
                                                        src={`${process.env.REACT_APP_GATEWAY_IPFS}/${metaData?.image ? metaData?.image?.substring(7, metaData?.image?.length) : ''}` || "/images/cart.svg"}
                                                        alt="NFT"
                                                    />
                                                ) : type === "video" ? (
                                                    <video
                                                        controls
                                                        className="object-cover h-[100%] sm:h-[100%] w-[172px] sm:w-[100%]"
                                                        src={`${process.env.REACT_APP_GATEWAY_IPFS}/${metaData?.media_url ? metaData?.media_url?.substring(7, metaData?.media_url?.length) : ''}` || "/images/cart.svg"}
                                                    />
                                                ) : type === "threeD" ? (
                                                    metaData?.media_url?.split('/')[2] !== metaData?.image?.split('/')[2] ?
                                                    <img
                                                        className="object-fill w-full h-full shadow-xl"
                                                        src={`${process.env.REACT_APP_GATEWAY_IPFS}/${metaData?.image ? metaData?.image?.substring(7, metaData?.image?.length) : ''}`}
                                                        alt='' />
                                                    :
                                                    <ThreeDRenderer
                                                        className="object-fill w-full h-full"
                                                        src={`${process.env.REACT_APP_GATEWAY_IPFS}/${metaData?.media_url ? metaData?.media_url?.substring(7, metaData?.media_url?.length) : ''}`}
                                                        scale={scale}
                                                        type={content?.mime_type?.split("/")[1]}
                                                    />
                                                ) : (
                                                    <></>
                                                )}
                                        </div>
                                        <div className="flex flex-col">
                                            <div className={`dark:text-white font-semibold ml-[10px]`}>&nbsp;{metaData?.name ? metaData?.name : metaData?.title ? metaData?.title : '....'}</div>
                                            <div className={`dark:text-white font-semibold ml-[10px]`}>&nbsp;{metaData?.description ? metaData?.description : '....'}</div>
                                            <div className={`dark:text-white font-semibold ml-[10px]`}>Collection:&nbsp;{metaData?.collection ? metaData?.collection.slice(0, 12) : '....'}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                    <div className="flex flex-col px-10 gap-6 max-w-lg py-10 ">
                        <div className="text-[#FF0092]">Something went wrong and this purchase could not be completed</div>
                        <div>Do you want to try again ?</div>
                        <div>
                            <CustomButton primary={true} className="px-5" onClick={closeModal}>
                                Yes, I want to try again
                            </CustomButton>
                        </div>
                        <div>Any money deducted will be refunded in <b>5-6</b> business days.</div>
                        <div>If this issue persists, write and email to <b>info@onnftverse.com</b></div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ModalPaymentFailure;
