import React, { useEffect, useState } from "react";
import { ArrowUpwardRounded, ArrowDownwardRounded } from "@mui/icons-material";
import InvestorsPieChart from "../components/ui/InvestorsPieChart/InvestorsPieChart";
import Areachart from "../components/ui/Areachart/Areachart";
import InvestorsAccounts from "../components/ui/InvestorsAccounts/InvestorsAccounts";
import { useSelector } from "react-redux";
import axios from "axios";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faBasketShopping,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Clients = () => {
  const [analytics, setAnalytics] = useState("");
  const [analyticsLoading, setAnalyticsLoading] = useState(false);
  const [tokenArray, setTokenArray] = useState([]);
  const [tokenPercentArray, setTokenPercentArray] = useState([]);
  const [calculatingDist, setCalculatingDist] = useState(false);
  const appCtx = useSelector((state) => state.app);

  const isPositiveGain = analytics?.cagr > 0;

  useEffect(() => {
    setAnalyticsLoading(true);

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/fundMagnet/wm/${appCtx.wealthManager.wmId}/analytics`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        setAnalytics(response.data);
        setAnalyticsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setAnalyticsLoading(false);
      });
  }, [appCtx.wealthManager.wmId]);

  useEffect(() => {
    if (analytics?.tokenDistribution) {
      setCalculatingDist(true);
      const tokenDistribution = JSON.parse(analytics?.tokenDistribution);
      const tokenArray = Object.keys(tokenDistribution);
      const totalValue = Object.values(tokenDistribution).reduce(
        (total, value) => total + value,
        0
      );

      let totalPercent = 0;
      const tokenPercentArray = tokenArray.map((token) => {
        const percent = Math.round(
          (tokenDistribution[token] / totalValue) * 100
        );
        totalPercent += percent;
        return percent;
      });

      const lastIndex = tokenPercentArray.length - 1;
      tokenPercentArray[lastIndex] += 100 - totalPercent;

      setTokenArray(tokenArray);
      setTokenPercentArray(tokenPercentArray);
      setCalculatingDist(false);
    }
  }, [analytics]);

  const handleRefresh = () => {
    setAnalyticsLoading(true);
    let data = JSON.stringify({});

    let config = {
      method: "put",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/fundMagnet/wm/${appCtx?.wealthManager?.wmId}/analytics`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "X-App-Token": process.env.REACT_APP_APP_TOKEN,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setAnalytics(response.data);
        setAnalyticsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="w-[95%] mx-auto">
      <InvestorsAccounts />
    </div>
  );
};

export default Clients;
