import React, { useState } from "react";
import Modal from "react-modal";
import { Close } from "@mui/icons-material";
import useAuthorizedHtttp from "../../../hooks/use-authorized-http";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function KycInfoModal({ openModal, setOpenModal, kycInfo }) {
  const appCtx = useSelector((state) => state.app);
  const makeRequest = useAuthorizedHtttp();
  const isMobileDevice = window.innerWidth <= 768;
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      borderRadius: "15px",
      zIndex: 9999,
    },
  };

  function closeModal() {
    setOpenModal(false);
  }

  const handleSendKycLink = () => {
    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/fundMagnet/client/send/kyc/link`,
        data: {
          clientName: `${kycInfo?.firstName} ${kycInfo?.lastName}`,
          email: kycInfo?.email,
          mobile: kycInfo?.mobile,
          wmName: appCtx?.wealthManager?.name,
        },
        method: "post",
      },
      (data) => {
        // setLoader(false);
        toast.success("Send Kyc link Successfull");
        closeModal();
      },
      (error) => {
        toast.error("Send Kyc link failed !");
        // setLoader(false);
      },
      () => {}
    );
  };

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="flex justify-end">
        <button className="" onClick={closeModal}>
          <Close />
        </button>
      </div>
      <div className="bg-opacity-50 flex justify-center items-center">
        <div className="">
          <div className="px-10 pb-8  flex flex-col gap-2">
            <h1 className="font-semibold text-2xl pb-3 md:pt-5">
              Kyc details of {kycInfo?.firstName} {kycInfo?.lastName}
            </h1>
            <p>
              {" "}
              <span className="text-lg font-semibold">email:</span>{" "}
              {kycInfo?.email}
            </p>
            <p>
              {" "}
              <span className="text-lg font-semibold">
                wallet address:
              </span>{" "}
              {kycInfo?.address}
            </p>
            <p>
              {" "}
              <span className="text-lg font-semibold">aadhar:</span>{" "}
              {kycInfo?.aadhar}
            </p>
            <p>
              {" "}
              <span className="text-lg font-semibold"> pan:</span>{" "}
              {kycInfo?.pan}
            </p>

            <button
              onClick={() => {
                handleSendKycLink();
              }}
              className="linear flex flex-row items-center rounded-xl bg-gray-100 px-4 py-3 text-base font-medium text-navy-700 transition duration-200 hover:bg-gray-200 active:bg-gray-300"
              data-ripple-light
            >
              Send Kyc Link
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default KycInfoModal;
