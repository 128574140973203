import React, { useEffect, useState, useLayoutEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { curveCardinal } from "d3-shape";
import { useSelector } from "react-redux";
import { Skeleton, Stack } from "@mui/material";
import moment from "moment";
import axios from "axios";

const cardinal = curveCardinal.tension(0.2);

const Areachart = ({ selecteddate, tokenid }) => {
  const [prices, setPrices] = useState([]);
  const [minMax, setMinMax] = useState([]);
  const [chartLoading, setChartLoading] = useState(false);
  const appCtx = useSelector((state) => state.app);

  const [aspectRatio, setAspectRatio] = useState(1);

  useEffect(() => {
    setChartLoading(true);

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/price/history?tokenId=${tokenid}&range=${selecteddate}`,
      headers: {
        ...(appCtx.authToken
          ? { "X-Auth-Token": appCtx.authToken }
          : { "X-App-Token": process.env.REACT_APP_X_APP_TOKEN }),
      },
    };

    axios
      .request(config)
      .then((response) => {
        setPrices(response.data.prices);
        setChartLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let min = prices.length > 0 ? prices[0][1] : 0;
    let max = prices.length > 0 ? prices[0][1] : 0;

    for (let i = 1; i < prices.length; i++) {
      const price = prices[i][1];
      if (price < min) {
        min = price;
      }
      if (price > max) {
        max = price;
      }
    }

    const padding = (max - min) * 0.1;
    // Adjust the domain of the Y-axis to include the empty space
    const yMin = min - padding;
    const yMax = max + padding;

    setMinMax([yMin, yMax]);
  }, [prices]);

  const data = prices?.map((price) => ({
    date: moment.unix(price[0]).toISOString(),
    price: price[1],
  }));

  const tickFormatter = (date) =>
    selecteddate === "h" || selecteddate === "d"
      ? moment(date).format("h:mm A")
      : moment(date).format("D MMM");

  useLayoutEffect(() => {
    const width = window.innerWidth;
    const newAspectRatio = width < 600 ? 2 : 3;
    setAspectRatio(newAspectRatio);
  }, []);

  return (
    <>
      {!chartLoading ? (
        <ResponsiveContainer width="100%" aspect={aspectRatio}>
          <AreaChart data={data}>
            <CartesianGrid horizontal="true" vertical="" />
            <XAxis
              dataKey={
                selecteddate === "h" || selecteddate === "d" ? "date" : "date"
              }
              interval={Math.floor(data.length / 10)}
              tickCount={10}
              tickFormatter={tickFormatter}
              textAnchor="middle"
              label={{
                position: "middle",
                offset: 0,
                style: { paddingRight: "50px" },
              }}
            />
            <YAxis
              domain={minMax}
              tickCount={7}
              tickFormatter={(value, index) => {
                if (index === 0) {
                  return "";
                } else {
                  if (value >= 1000) {
                    return `$${value?.toFixed(0)}`;
                  } else if (value >= 1) {
                    return `$${value?.toFixed(2)}`;
                  } else if (value >= 0.1) {
                    return `$${value?.toFixed(3)}`;
                  } else if (value >= 0.01) {
                    return `$${value?.toFixed(4)}`;
                  } else if (value >= 0.001) {
                    return `$${value?.toFixed(5)}`;
                  } else if (value >= 0.0001) {
                    return `$${value?.toFixed(6)}`;
                  } else if (value >= 0.00001) {
                    return `$${value?.toFixed(7)}`;
                  } else {
                    return value?.toFixed(8); // return the original value with 8 decimal places
                  }
                }
              }}
            />
            {/* <Tooltip
              formatter={(value) => `$${value}`}
              labelFormatter={(label) => moment(label).format("D MMM YYYY")}
            /> */}
            <Tooltip
              contentStyle={{ background: "white", border: "none" }}
              itemStyle={{ color: "black" }}
              labelFormatter={(label) => moment(label).format("D MMM YYYY")}
              formatter={(value) => `$${value?.toFixed(2)}`}
              content={({ label, payload }) => {
                if (payload && payload.length) {
                  const time = moment(label).format("h:mm A");
                  const date = moment(label).format("D MMM YYYY");

                  let value = payload[0].value;
                  let formattedValue = "";

                  if (value >= 1000) {
                    formattedValue = value?.toFixed(0);
                  } else if (value >= 1) {
                    formattedValue = value?.toFixed(2);
                  } else if (value >= 0.1) {
                    formattedValue = value?.toFixed(3);
                  } else if (value >= 0.01) {
                    formattedValue = value?.toFixed(4);
                  } else if (value >= 0.001) {
                    formattedValue = value?.toFixed(5);
                  } else if (value >= 0.0001) {
                    formattedValue = value?.toFixed(6);
                  } else if (value >= 0.00001) {
                    formattedValue = value?.toFixed(7);
                  } else {
                    formattedValue = value?.toFixed(8);
                  }

                  return (
                    <div className="p-4 bg-white border rounded-lg shadow-lg flex flex-col min-w-[250px]">
                      <div className="flex justify-between">
                        <p className="text-gray-800 text-sm font-semibold">
                          {date}
                        </p>
                        <p className="text-gray-600 text-sm">{time}</p>
                      </div>

                      <div className="flex flex-row items-center mt-3">
                        <div className="rounded-full bg-green-500 w-4 h-4 mr-2"></div>
                        <p className="text-gray-600 text-sm font-semibold">
                          Price: ${formattedValue}
                        </p>
                      </div>
                    </div>
                  );
                }
                return null;
              }}
            />

            <Area
              type={cardinal}
              dataKey="price"
              stroke="#82ca9d"
              fill="#82ca9d"
              fillOpacity={0.2}
            />
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        <Stack className="w-[90%] mx-auto">
          {/* For variant="text", adjust the height via font-size */}
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
        </Stack>
      )}
    </>
  );
};

export default Areachart;
