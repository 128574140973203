import React from "react";

import { Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ThreeDRenderer from "../../../pages/ThreeDRenderer";
import CustomButton from "../../CustomButton";

function ModalPaymentSuccess({ openModalPaymentSuccess, setOpenModalPaymentSuccess, nftSrc, nftId, title, type, scale, ipfsHash, image, media_url, content,isCart,cart }) {
    const navigate = useNavigate();
    const customStyles = {
        content: {
            top: "55%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: "0",
            borderRadius: "10px",
            transform: "translate(-50%, -50%)",
            width:"500px",
            minHeight:"500px"
        },
    };

    function closeModal() {
        setOpenModalPaymentSuccess(false);
        navigate('/myassets')
    }

    return (
        <Modal isOpen={openModalPaymentSuccess} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal" ariaHideApp={false}>
            <div className="flex flex-col items-center">
                <div className="flex  justify-center gap-32 items-center w-full bg-[#bbff00] h-[40px]">
                    <div className="text-md font-bold  px-8">Yay! Congratulations on your purchase.</div>
                    {/* <Link to={`/nft/${nftId}`}>
                    <button className=" font-bold leading-[10px] text-gray-500 absolute  right-6" onClick={closeModal}>
                        X
                    </button>
                    </Link> */}
                    <div
                    // href={`/nft/${nftId}`}
                    >
                        <button
                            className=" font-bold leading-[10px] text-gray-500 absolute  right-6"
                            onClick={() => {
                                // navigate(`/explore`);
                                closeModal();
                            }}
                        >
                            X
                        </button>
                    </div>
                </div>

                <div className="flex flex-col py-2 items-center w-[100%] overflow-hidden modalPayment">
                    <div className="font-bold flex gap-2">
                        This item has been added to the wallet
                    </div>
                    {!isCart?
                    <div className="flex flex-col">
                   
                        <div className={"p-2 rounded-xl h-[250px] w-[100%]"} style={{ backgroundColor: type === "threeD" ? "#002f49" : "" }}>
                            {type === "text" ? (
                                <div className={"h-[100%] p-10 dark:bg-neutral-800 shadow-xl flex items-center justify-center"}><img src="/images/nonft.svg" alt="logo" className="h-[100%]" /></div>
                            ) :
                                type === "image" ? (
                                    <img
                                        className="object-contain w-full div-item-border h-full"
                                        src={image}
                                        alt="NFT"
                                    />
                                ) : type === "video" ? (
                                    <video
                                        controls
                                        // autoPlay
                                        className="object-cover w-full h-full"
                                        src={media_url}
                                    />
                                ) : type === "threeD" ? (
                                    <ThreeDRenderer
                                        className="object-fill w-full h-full "
                                        src={media_url}
                                        scale={scale}
                                        type={content?.mime_type?.split("/")[1]}
                                    />
                                ) : (
                                    <></>
                                )}
                        </div>
                        
                        <div className="flex justify-center flex-col items-center gap-3">
                            <div className="text-lg leading-[10px] font-bold">#{nftId}</div>
                            <div className="flex justify-start text-lg font-bold">
                               Name : {title}
                            </div>
                        </div>
                    </div>
                    :
                    <div className="grid grid-cols-2 py-4 pl-5 pr-[2rem] w-[100%] gap-4 overflow-y-scroll h-[330px]">
                            {cart?.map((item) => {
                                const metaData = JSON.parse(item?.nftDomain?.metaData || null);
                                const type = metaData?.mime_type?.split('/')[0];

                                return (
                                    <div className="flex">
                                        <div className={"w-[100px] h-[100px] p-2"}
                                            style={{ border: "2px solid #80808066", borderRadius: "16px", backgroundColor: type === "threeD" ? "#002f49" : "" }}
                                        >
                                            {type === "text" ? (
                                                <div className={"h-[100%] p-10 shadow-xl flex items-center justify-center"}><img src="/images/collectionimage.svg" alt="logo" className="h-[100%]" /></div>
                                            ) :
                                                type === "image" ? (
                                                    <img
                                                        className="object-cover h-[100%] sm:h-[100%] w-[100%] sm:w-[100%] "
                                                        src={`${process.env.REACT_APP_GATEWAY_IPFS}/${metaData?.image ? metaData?.image?.substring(7, metaData?.image?.length) : ''}` || "/images/cart.svg"}
                                                        alt="NFT"
                                                    />
                                                ) : type === "video" ? (
                                                    <video
                                                        controls
                                                        className="object-cover h-[100%] sm:h-[100%] w-[172px] sm:w-[100%]"
                                                        src={`${process.env.REACT_APP_GATEWAY_IPFS}/${metaData?.media_url ? metaData?.media_url?.substring(7, metaData?.media_url?.length) : ''}` || "/images/cart.svg"}
                                                    />
                                                ) : type === "threeD" ? (
                                                    metaData?.media_url?.split('/')[2] !== metaData?.image?.split('/')[2] ?
                                                    <img
                                                        className="object-fill w-full h-full shadow-xl"
                                                        src={`${process.env.REACT_APP_GATEWAY_IPFS}/${metaData?.image ? metaData?.image?.substring(7, metaData?.image?.length) : ''}`}
                                                        alt='' />
                                                        :
                                                        <ThreeDRenderer
                                                            className="object-fill w-full h-full"
                                                            src={`${process.env.REACT_APP_GATEWAY_IPFS}/${metaData?.media_url ? metaData?.media_url?.substring(7, metaData?.media_url?.length) : ''}`}
                                                            scale={scale}
                                                            type={content?.mime_type?.split("/")[1]}
                                                        />


                                                ) : (
                                                    <></>
                                                )}
                                        </div>
                                        <div className="flex flex-col">
                                            <div className={`dark:text-white font-semibold ml-[10px]`}>&nbsp;{metaData?.name ? metaData?.name : metaData?.title ? metaData?.title : '....'}</div>
                                            <div className={`dark:text-white font-semibold ml-[10px]`}>&nbsp;{metaData?.description ? metaData?.description : '....'}</div>
                                            <div className={`dark:text-white font-semibold ml-[10px]`}>Collection:&nbsp;{metaData?.collection ? metaData?.collection.slice(0, 12) : '....'}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                }
                    <Link to="/marketplace" className="no-underline">
                        <CustomButton primary={true} className="mt-2">
                            Continue shopping <ArrowRightAltIcon />{" "}
                        </CustomButton>
                    </Link>
                </div>
            </div>
        </Modal>
    );
}

export default ModalPaymentSuccess;
