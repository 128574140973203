import React, { useState } from "react";
import Modal from "react-modal";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function GenerateKycModal({ openModal, setOpenModal, clientDetails }) {
  const makeRequest = useAuthorizedHttp();
  const appCtx = useSelector((state) => state.app);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      borderRadius: "15px",
      zIndex: 9999,
      width: "700px",
    },
  };

  function closeModal() {
    setOpenModal(false);
  }
  const handleSendKycLink = () => {
    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/fundMagnet/client/send/kyc/link`,
        data: {
          clientName:
            (clientDetails?.firstName || clientDetails?.lastName) === null
              ? "user"
              : `${clientDetails?.firstName} ${clientDetails?.lastName}`,
          email: clientDetails?.email,
          mobile: clientDetails?.mobile,
          wmName: appCtx?.wealthManager?.name,
        },
        method: "post",
      },
      (data) => {
        // setLoader(false);
        toast.success("Send Kyc link Successfull");
        closeModal();
      },
      (error) => {
        toast.error("Send Kyc link failed !");
        // setLoader(false);
      },
      () => {}
    );
  };

  console.log("clientDetails from generate kyc modal", clientDetails);

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="p-5">
        <p className="text-lg font-bold">
          We don't have the confirmation of this users completing the KYC with
          OnRamp solutions
        </p>
        <p className="text-lg font-bold mt-3">
          Click on Initiate KYC to send an email to user with the link to
          complete KYC. You as the fund manager will also be marked in the
          email.
        </p>

        <div className="flex justify-center my-5">
          <button
            onClick={() => {
              handleSendKycLink();
            }}
            className="inline-block px-20 py-2 mx-auto text-white font-semibold bg-[#5c22b5] rounded-full hover:bg-[#5e05e2] md:mx-0 "
          >
            Initiate KYC
          </button>
        </div>

        <p className="text-md font-semibold">
          Once the user has completed KYC, they will be able to transfer funds.
          Ignore if the user has already completed KYC with OnRamp solution.
        </p>
      </div>
    </Modal>
  );
}

export default GenerateKycModal;
