import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import LandingPageFooter from "../components/Footer/LandingPageFooter/LandingPageFooter";
import useHttp from "../hooks/use-http";
import { useNavigate } from "react-router";
import { Card, CardContent, CardMedia, Grid, Skeleton } from "@mui/material";

const Distributor = () => {
  const [distributorsData, setDistributorsData] = useState(null);
  const [distributorsDataLoading, setDistributorsDataLoading] = useState(false);
  const makeRequest = useHttp();
  const navigate = useNavigate();
  useEffect(() => {
    setDistributorsDataLoading(true);
    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/wealth/manager/list
`,
        method: "get",
        headers: {
          "X-App-Token": process.env.REACT_APP_APP_TOKEN,
          "Content-Type": "application/json",
        },
      },
      (data) => {
        setDistributorsData(data.content);
        setDistributorsDataLoading(false);
      },
      (rej) => {
        setDistributorsDataLoading(false);
      }
    );
  }, []);
  return (
    <div>
      <Header />
      <div className="pt-[130px] w-[90%] mx-auto">
        <p className="text-3xl font-bold">Distributors</p>

        {/* card  */}
        {distributorsDataLoading ? (
          <Grid container spacing={2}>
            {[1, 2, 3, 4].map((card, index) => (
              <div className="max-w-2xl px-4 py-2 w-full  sm:max-w-sm md:max-w-sm lg:max-w-sm xl:max-w-sm lg:w-1/4 md:w-1/3 sm:w-full mt-4">
                <div
                  key={index}
                  className=" bg-white shadow-xl rounded-lg text-gray-900"
                >
                  <Card>
                    <Skeleton variant="rectangular" height={200} />
                    <CardContent>
                      <Skeleton variant="text" />
                      <Skeleton variant="text" width="80%" />
                    </CardContent>
                  </Card>
                </div>
              </div>
            ))}
          </Grid>
        ) : (
          <div className="flex flex-wrap justify-center my-5 ">
            {distributorsData &&
              distributorsData.map((user, index) => (
                <div className="max-w-2xl px-4 py-2 w-full  sm:max-w-sm md:max-w-sm lg:max-w-sm xl:max-w-sm lg:w-1/4 md:w-1/3 sm:w-full mt-4">
                  <div
                    key={index}
                    className=" w-[98%] bg-white shadow-xl rounded-lg text-gray-900"
                  >
                    <div className="rounded-t-lg h-32 overflow-hidden">
                      <img
                        className="object-cover object-top w-full"
                        src={
                          user?.coverImage ||
                          "https://images.unsplash.com/photo-1549880338-65ddcdfd017b?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ"
                        }
                        alt={user?.name}
                      />
                    </div>
                    <div className="mx-auto w-32 h-32 relative -mt-16 border-4 border-white rounded-full overflow-hidden">
                      <img
                        className="object-cover object-center h-32"
                        src={
                          user?.profilePic ||
                          "https://images.unsplash.com/photo-1549880338-65ddcdfd017b?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ"
                        }
                        alt={user.name}
                      />
                    </div>
                    <div className="text-center mt-2">
                      <h2 className="font-semibold">{user?.name}</h2>
                      <p className="text-gray-500">{user?.bio}</p>
                    </div>

                    <div className="p-4 border-t mx-8 mt-2">
                      <button
                        onClick={() => {
                          navigate(
                            `/distributor/${user?.userId}/${user?.name}`
                          );
                        }}
                        className=" block mx-auto rounded-full bg-gray-900 hover:shadow-lg font-semibold text-white px-12 py-3"
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
      <LandingPageFooter />
    </div>
  );
};

export default Distributor;
