import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./MybasketCard.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ManagersBasketCard = ({ basket }) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();

  return (
    <Link
      to={`/crypto-basket-details/${basket?.basketId}`}
      key={basket?.basketId}
      className="bg-cover bg-center w-[100%] md:w-[48%] border rounded-3xl pt-5 pb-10  px-5 md:px-10 no-underline text-black "
      style={{
        backgroundImage: `url(/images/basketbg.svg)`,
      }}
    >
      <div>
        <div className="flex flex-row overflow-hidden items-center gap-5 pb-3 h-[80px] ">
          {basket?.icon ? (
            <img src={basket?.icon} alt="" className="w-[70px]" />
          ) : (
            <img src="/images/basketimage.svg" alt="" />
          )}
          <div className="flex flex-col justify-start items-start">
            <h4 className="font-bold ">
              {basket?.name && basket?.name?.length > 20
                ? `${basket?.name.substring(0, 20)}...`
                : basket?.name}
            </h4>
            <h6>
              {basket?.wealthManagerName &&
              basket?.wealthManagerName?.length > 20
                ? `${basket?.wealthManagerName.substring(0, 20)}...`
                : basket?.wealthManagerName}
            </h6>
          </div>
        </div>
        <div className="h-[50px]">
          <p className="text-left">
            {basket?.description && basket.description?.length > 70
              ? `${basket.description.substring(0, 70)}...`
              : basket.description}
          </p>
        </div>

        <div className="flex flex-row justify-between items-center mt-5">
          <div className="text-left">
            <p>Min Amount</p>
            <p className="font-semibold ">
              {basket?.minAmountCurrency} {basket?.minAmount}
            </p>
          </div>
          {/* <div>
                              <h5>Active Subs</h5>
                              <h3>3k+</h3>
                            </div> */}
          {/* <div>
                              <img
                                src="/images/bookmark.svg"
                                alt=""
                                className=" w-[40px]"
                              />
                            </div> */}
          <div>
            <button
              className="bg-gradient-to-r from-purple-700 to-purple-900 rounded-full  text-white py-2 px-5 "
              type="submit"
            >
              {appCtx?.userDetails?.userId === basket?.userId
                ? "Invest"
                : " Subscribe"}
            </button>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ManagersBasketCard;
