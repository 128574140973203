import React, { useEffect, useState } from "react";
import { useCryptobasket } from "./useCryptobasket";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Skeleton } from "@mui/material";
import CreateBasketModal from "./CreateBasketModal";
import { useSelector } from "react-redux";
import MybasketCard from "./MybasketCard";
import HowitWorksModal from "./HowitWorksModal";
import CustomButton from "../../components/CustomButton";

const CryptoBasket = () => {
  const [openCreateBasketModal, setOpenCreateBasketModal] = useState(false);
  const [closeClicked, setCloseClicked] = useState(false);
  const [howItWorksModal, setHowItWorksModal] = useState(false);
  const {
    fetchPublicBasket,
    publicBasket,
    privateBasket,
    fetchPrivateBasket,
    privateBasketLoading,
    publicBasketLoading,
    openBasketModal,
    setopenBasketModal,
  } = useCryptobasket();
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchPublicBasket();
    fetchPrivateBasket(id);
  }, []);
  return (
    <div
      className=""
      // style={{
      //   background: "linear-gradient(180deg, #3C0B8C 0%, #FFFFFF 119.14%)",
      // }}
    >
      <div className="flex flex-col justify-between">
        <div className="w-[80%] mx-auto pt-5 flex flex-col justify-center">
          <div className=" w-[100%] rounded-tl-[40px] md:rounded-tl-[80px] rounded-tr-[40px] md:rounded-tr-[80px]">
            {/* my tokens card  */}
            <div className="mx-auto mt-10  ">
              <div className=" flex flex-col md:flex-row flex-wrap justify-center md:justify-between  gap-5">
                <div className="">
                  <h3 className=" font-semibold ">My Baskets</h3>
                </div>
              </div>
              {!privateBasketLoading &&
                (!privateBasket || privateBasket.length === 0) && (
                  <h4 className="py-5">
                    <div
                      class="p-4 mb-4   rounded-lg bg-[#F8D7DA]  text-lg font-medium"
                      role="alert"
                    >
                      You don't have any items in the basket
                    </div>
                  </h4>
                )}

              <div className=" my-5 flex flex-col md:flex-row  justify-between flex-wrap gap-y-5 ">
                {privateBasketLoading ? (
                  <>
                    {[...Array(3)].map((_, index) => (
                      <Skeleton
                        key={index}
                        variant="rectangular"
                        height={70}
                        className=" mt-[20px] rounded-full w-[100%] mx-auto"
                      />
                    ))}
                  </>
                ) : (
                  <MybasketCard basketList={privateBasket} />
                )}
              </div>
            </div>

            {/* publicBasket  */}

            <div className=" ">
              {!publicBasketLoading && publicBasket?.length > 0 && (
                <div className=" flex flex-col md:flex-row items-center justify-between gap-5 ">
                  <div className="">
                    <h3 className=" font-semibold">All Baskets</h3>
                  </div>
                </div>
              )}

              <div>
                <div className=" my-10 flex flex-col md:flex-row  justify-between flex-wrap gap-y-5 ">
                  {publicBasketLoading ? (
                    <>
                      {[...Array(3)].map((_, index) => (
                        <Skeleton
                          key={index}
                          variant="rectangular"
                          height={70}
                          className=" mt-[20px] rounded-full w-[100%] mx-auto"
                        />
                      ))}
                    </>
                  ) : (
                    <MybasketCard basketList={publicBasket} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HowitWorksModal
        openModal={howItWorksModal}
        setOpenModal={setHowItWorksModal}
      />
    </div>
  );
};

export default CryptoBasket;
