import React from "react";
import Banner from "./Banner/Banner";
import AboutCrypto from "./AboutCrypto/AboutCrypto";
import WhyChoose from "./WhyChoose/WhyChoose";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Pricing from "./Pricing/Pricing";
import LandingPageFooter from "../Footer/LandingPageFooter/LandingPageFooter";

const LandingPage = () => {
  return (
    <div>
      <Header />
      <Banner />
      <AboutCrypto />
      <WhyChoose />
      <Pricing />
      {/* <Footer /> */}
      <LandingPageFooter />
    </div>
  );
};

export default LandingPage;
