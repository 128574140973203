import React, { useEffect, useState } from "react";
import { ArrowUpwardRounded, ArrowDownwardRounded } from "@mui/icons-material";
import InvestorsPieChart from "../components/ui/InvestorsPieChart/InvestorsPieChart";
import NewsDisplay from "../components/ui/News/NewsDisplay";
import Update from "../components/ui/Update";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { appActions } from "../context/app-slice";
import AccessModal from "../components/AccessModal/AccessModal";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faBasketShopping,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";
import Areachart from "../components/ui/Areachart/Areachart";

const Home = () => {
  const [wealthManagerLoaded, setWealthManagerLoaded] = useState(false);
  const [openAccessModal, setOpenAccessModal] = useState(false);
  const [analytics, setAnalytics] = useState("");
  const [analyticsLoading, setAnalyticsLoading] = useState(false);
  const [calculatingDist, setCalculatingDist] = useState(false);
  const [tokenArray, setTokenArray] = useState([]);
  const [tokenPercentArray, setTokenPercentArray] = useState([]);
  const appCtx = useSelector((state) => state.app);

  const isPositiveGain = analytics?.cagr > 0;

  console.log("appCtx", appCtx);

  const dispatch = useDispatch();

  useEffect(() => {
    if (wealthManagerLoaded && !appCtx?.wealthManager?.wmId) {
      setOpenAccessModal(true);
    }
  }, [wealthManagerLoaded]);
  useEffect(() => {
    if (appCtx?.wealthManager?.wmId) {
      setAnalyticsLoading(true);

      let config = {
        method: "get",
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/fundMagnet/wm/${appCtx?.wealthManager?.wmId}/analytics`,
        headers: {
          "X-Auth-Token": appCtx.authToken,
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          setAnalytics(response.data);
          setAnalyticsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          toast.error("failed to get analytics");
        });
    }
  }, [appCtx?.wealthManager?.wmId]);

  useEffect(() => {
    if (analytics?.tokenDistribution) {
      setCalculatingDist(true);
      const tokenDistribution = JSON.parse(analytics?.tokenDistribution);
      const tokenArray = Object.keys(tokenDistribution);
      const totalValue = Object.values(tokenDistribution).reduce(
        (total, value) => total + value,
        0
      );

      let totalPercent = 0;
      const tokenPercentArray = tokenArray.map((token) => {
        const percent = Math.round(
          (tokenDistribution[token] / totalValue) * 100
        );
        totalPercent += percent;
        return percent;
      });

      const lastIndex = tokenPercentArray.length - 1;
      tokenPercentArray[lastIndex] += 100 - totalPercent;

      setTokenArray(tokenArray);
      setTokenPercentArray(tokenPercentArray);
      setCalculatingDist(false);
    }
  }, [analytics]);

  useEffect(() => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/wealth/manager/profile?userId=${appCtx?.userDetails?.userId}`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "X-App-Token": process.env.REACT_APP_APP_TOKEN,
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        dispatch(appActions.setWealthManager(response.data));
        setWealthManagerLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleRefresh = () => {
    setAnalyticsLoading(true);
    let data = JSON.stringify({});

    let config = {
      method: "put",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/fundMagnet/wm/${appCtx?.wealthManager?.wmId}/analytics`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "X-App-Token": process.env.REACT_APP_APP_TOKEN,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setAnalytics(response.data);
        setAnalyticsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="w-[95%] mx-auto">
      <div className=" pt-5">
        <div className="w-[100%] bg-[#FFFFFF] p-5">
          <div className="flex flex-row justify-between">
            <h4>Accounts Summary</h4>
            <div
              className="cursor-pointer"
              onClick={() => {
                handleRefresh();
              }}
              title="Refresh"
            >
              <FontAwesomeIcon
                icon={faArrowsRotate}
                // style={{ color: "#1e58be" }}
              />
            </div>
          </div>

          <div>
            <hr className="bg-black  w-[100%] my-5" />
          </div>
          {analyticsLoading ? (
            <>
              <div className="flex flex-row justify-between items-center flex-wrap mt-5">
                {Array(3)
                  .fill()
                  .map((_, index) => (
                    <div className="w-[15%]" key={index}>
                      <SkeletonTheme baseColor="#f2f2f2" highlightColor="#444">
                        <p>
                          <Skeleton count={2} />
                        </p>
                      </SkeletonTheme>
                    </div>
                  ))}
              </div>
              <div className="flex flex-row justify-between items-center flex-wrap mt-10">
                {Array(3)
                  .fill()
                  .map((_, index) => (
                    <div className="w-[15%]" key={index}>
                      <SkeletonTheme baseColor="#f2f2f2" highlightColor="#444">
                        <p>
                          <Skeleton count={2} />
                        </p>
                      </SkeletonTheme>
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <div className="flex flex-row justify-between items-center  ">
              <div className="w-1/3 flex flex-col justify-start gap-y-5 border-r border-gray-300">
                <div className="flex items-center">
                  <AccountCircleIcon
                    sx={{ fontSize: "60px" }}
                    className=" text-[#F7B924]"
                  />
                  <div className="ml-2">
                    <p className="text-md text-[#495057]">
                      Total Active Clients
                    </p>
                    <p className="text-4xl font-bold">
                      {analytics?.clients || 0}
                    </p>
                  </div>
                </div>
                <div className="flex items-center ">
                  <AccountBalanceWalletIcon
                    sx={{ fontSize: 60 }}
                    className=" text-[#DD3A61]"
                  />
                  <div className="ml-2">
                    <p className="text-md text-[#495057]">Total AUM</p>
                    <p className="text-4xl font-bold">
                      {analytics?.tvlCurrency}{" "}
                      {(analytics?.tvl || 0)?.toFixed(3)}
                    </p>
                  </div>
                </div>
              </div>

              <div className=" w-1/3 border-r border-gray-300 flex flex-col   gap-y-5 ">
                <div className="flex justify-center items-center">
                  <img src="/images/crypto.png" alt="" className="w-[60px]" />
                  <div className="ml-2">
                    <p className="text-md text-[#495057]">Cryptos Subscribed</p>
                    <p className="text-4xl font-bold">
                      {analytics?.tokenCount || 0}
                    </p>
                  </div>
                </div>
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={faBasketShopping}
                    className="text-[#1e40af] text-5xl"
                  />
                  <div className="ml-2">
                    <p className="text-md text-[#495057]">Basket Subscribed</p>
                    <p className="text-4xl font-bold">
                      {analytics?.cryptoBaskets || 0}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-1/3  flex flex-col justify-start gap-y-5  ">
                <div className="flex  justify-center items-center">
                  <img src="/images/cagr.png" alt="" className="w-[60px]" />
                  <div className="ml-2">
                    <p className="text-md text-[#495057]">Investment</p>
                    <p className="text-4xl font-bold">
                      {analytics?.investmentCurrency}{" "}
                      {(analytics?.investment || 0)?.toFixed(3)}
                    </p>
                  </div>
                </div>
                <div className="flex justify-center items-center ">
                  <img
                    src="/images/netprofit.png"
                    alt=""
                    className="w-[60px]"
                  />
                  <div className="ml-2">
                    <p className="text-md text-[#495057]">Net Profit</p>
                    <p className="text-4xl font-bold">
                      {(analytics?.tvl - analytics?.investment)?.toFixed(3) ||
                        0}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div>
            <hr className="bg-black  w-[100%] my-5" />
          </div>
        </div>
      </div>
      {/* token section  */}

      <div className="w-[100%] bg-[#FFFFFF] p-5 my-10">
        <h4>Token Distribution</h4>
        <div>
          <hr className="bg-black  w-[100%] my-5" />
        </div>
        <div>
          {analyticsLoading || calculatingDist ? (
            <div
              style={{ width: "300px", height: "300px" }}
              className="mx-auto"
            >
              <SkeletonTheme baseColor="#f2f2f2" highlightColor="#d9d9d9">
                <Skeleton count={1} circle={true} height={300} width={300} />
              </SkeletonTheme>
            </div>
          ) : !analyticsLoading && tokenArray.length === 0 ? (
            "Token Distribution data are not available"
          ) : (
            tokenArray &&
            tokenPercentArray && (
              <InvestorsPieChart
                tokenPercentArray={tokenPercentArray}
                tokenArray={tokenArray}
              />
            )
          )}
        </div>
      </div>

      {/* overall performance section  */}

      <div className="w-[100%] bg-[#FFFFFF] p-10 my-10">
        <h4>Overall performance</h4>

        <div className="pt-10">
          <Areachart />
        </div>
      </div>

      {/* crypto lens section  */}
      {/* <div className="bg-[#FFFFFF] p-10 ">
        <h1 className="mt-5">Crypto Lens</h1>
        <NewsDisplay />
      </div> */}

      {/* knowledge base  */}
      <div className="bg-[#FFFFFF] p-10 my-10">
        <Update />
      </div>

      <AccessModal
        openModal={openAccessModal}
        setOpenModal={setOpenAccessModal}
      />
    </div>
  );
};

export default Home;
