import {
  ContactPhone,
  KeyboardArrowRightRounded,
  LightMode,
  LogoutRounded,
  SettingsRounded,
} from "@mui/icons-material";
import { useCallback, useState } from "react";
import { appActions } from "../../context/app-slice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import React from "react";
 import PortraitIcon from "@mui/icons-material/Portrait";
 import { useNavigate } from "react-router";

 const NavbarDropdown = (props) => {
   const appCtx = useSelector((state) => state.app);
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const changeMode = useCallback(() => {
     dispatch(appActions.toggleDarkMode());
   }, []);
   const openInNewTab = (url) => {
     window.open(url);
   };
   return (
     <div
       className={`${
         !props.visible && "hidden"
       } w-[90%] sm:w-[400px] h-fit rounded absolute right-5 top-[150px] sm:top-[75px]  bg-slate-50 shadow-lg z-50 flex flex-col`}
     >
       <button
         className={
           "text-left border-b p-4  border-gray-200 flex justify-between hover:bg-gray-200  transition-all ease-out duration-300"
         }
         onClick={() => navigate("/profile")}
       >
         <div className="flex gap-5 items-center">
           <PortraitIcon />
           <div>Profile</div>
         </div>
       </button>
       <button
         className={
           "text-left border-b p-4  border-gray-200 flex justify-between hover:bg-gray-200  transition-all ease-out duration-300"
         }
         onClick={() => props.setContactUsVisible(true)}
       >
         <div className="flex gap-5 items-center">
           <ContactPhone />
           <div>Contact US</div>
         </div>
       </button>
       <button
         onClick={props.onLogoutClick}
         className={
           "text-left border-b p-4  border-gray-200 flex justify-between hover:bg-gray-200  transition-all ease-out duration-300"
         }
       >
         <div className={"flex gap-5 items-center"}>
           <LogoutRounded />
           <div>Log Out</div>
         </div>
         <div>
           <KeyboardArrowRightRounded />
         </div>
       </button>
     </div>
   );
 };

export default NavbarDropdown;
