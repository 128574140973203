import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

import styles from "./SelectClientModal.module.css";
import { toast } from "react-toastify";
import axios from "axios";
import { nativeToken } from "../../../config";
import TaleWalletOnRampModal from "../../TaleWalletOnRampModal/TaleWalletOnRampModal";

function SelectClientModal({
  openModal,
  setOpenModal,
  closeClicked,
  setCloseClicked,
  singleBasket,
  tokenPercentArray,
  tokenArray,
  successcloseClicked,
  setSuccessCloseClicked,
  openSuccessModal,
  setopenSuccessModal,
  transactionSuccess,
  setTransactionSuccess,
  fees,
  setOpenContinueInWebModal,
  symbol,
}) {
  const [investmentAmount, setInvestmentAmount] = useState(null);
  const [totalInvestment, setTotalInvestment] = useState(null);
  const [accounts, setAccounts] = useState("");
  const [accountsAddresses, setAccountsAddresses] = useState("");
  const [clientListLoading, setClientListLoading] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [secondnextClicked, setSecondNextClicked] = useState(false);
  const [clientSelected, setClientSelected] = useState(false);
  const [selectedClientData, setSelectedClientData] = useState("");
  const [combinedData, setCombinedData] = useState([]);
  const [selectedclient, setSelectedClient] = useState("");
  const [openTalewalletOnrampModal, setOpenTalewalletOnrampModal] =
    useState(false);
  const [useraddress, setUseraddress] = useState("");
  const appCtx = useSelector((state) => state.app);

  const [editable, setEditable] = useState(false);

  useEffect(() => {
    setInvestmentAmount(singleBasket?.minAmount);
  }, [singleBasket?.minAmount, openModal]);

  useEffect(() => {
    setTotalInvestment(investmentAmount + fees?.total);
  }, [investmentAmount, openModal]);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      top: "53%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "inherit",
      // width: width > 1200 ? "600px" : "370px",
    },
  };
  function closeModal() {
    setOpenModal(false);
  }

  const handleOrderPlaced = () => {
    CallSubscribeApi();
    let fiatAmount = parseInt(totalInvestment);
    if (fiatAmount < singleBasket?.minAmount) {
      toast.error(
        "Investment Amount should be greater or equal to minimum amount"
      );
      return;
    }

    setOpenTalewalletOnrampModal(true);
  };

  console.log("appCtx", appCtx);

  const handlePlaceOrder = () => {
    handleOrderPlaced();
    closeModal();
  };

  const CallSubscribeApi = () => {
    let data = JSON.stringify({
      basketId: singleBasket?.basketId,
      totalAmount: singleBasket?.minAmount,
      currency: singleBasket?.minAmountCurrency,
    });
    console.log(data);
    let config = {
      method: "post",
      url: `${
        process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE
      }/crypto/basket/subscribe?nativeToken=${
        nativeToken[singleBasket?.blockchain]
      }&nativeTokenQuantity=${
        singleBasket?.minAmount
      }&distributeToken=${false}`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [searchValue, setSearchValue] = useState("");

  // Filter the rows based on the search value
  const filteredData = combinedData.filter((data) => {
    const fullName = `${data.firstName} ${data.lastName}`.toLowerCase();
    return fullName.includes(searchValue.toLowerCase());
  });

  useEffect(() => {
    setClientListLoading(true);
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/fundMagnet/wm/${appCtx.wealthManager.wmId}/client/list`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        setAccounts(response.data?.content);
        const userIdArray = response.data?.content?.map((item) => item?.userId);

        let data = JSON.stringify(userIdArray);

        let config2 = {
          method: "post",
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/address/list`,
          headers: {
            "X-Auth-Token": appCtx.authToken,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config2)
          .then((response) => {
            setAccountsAddresses(response.data);
            setClientListLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (accounts && accountsAddresses) {
      const data = accounts.map((account) => {
        const matchingAddress = accountsAddresses.find(
          (address) => address.userId === account.userId
        );
        const address = matchingAddress ? matchingAddress.address : "";
        const truncatedAddress =
          address?.length > 11
            ? address.substring(0, 5) + "..." + address.slice(-5)
            : address;
        return {
          userId: account.userId,
          clientId: account.clientId,
          firstName: account.firstName,
          lastName: account.lastName,
          email: account.email,
          mobile: account.mobile,
          aadhar: account.aadhar,
          pan: account.pan,
          address: matchingAddress ? matchingAddress.address : "",
          truncatedAddress,
        };
      });

      setCombinedData(data);
    }
  }, [accounts, accountsAddresses]);

  const handleSelectclient = (event) => {
    const selectedValue = event.target.value;
    setSelectedClient(selectedValue);

    // Find the client object from the combinedData array based on the selected value
    const selectedClient = combinedData.find(
      (client) => client.email === selectedValue
    );

    if (selectedClient) {
      setUseraddress(selectedClient.address);
    } else {
      setUseraddress("");
    }
  };

  useEffect(() => {
    const matchedClient = combinedData.find(
      (client) => client?.email === selectedclient
    );

    setSelectedClientData(matchedClient);
  }, [selectedclient]);

  let amount = Math.ceil(totalInvestment + 0.004 * investmentAmount);

  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        className={`${styles.popup}  `}
      >
        <div className="w-[100%] flex justify-end pr-[20px] pt-[10px] ">
          <button
            className=" font-medium  text-2xl flex items-center leading-[10px] mr-[10px] mt-[10px]"
            onClick={closeModal}
          >
            X
          </button>
        </div>
        <div className=" px-14 py-5">
          {!clientSelected && !nextClicked && !secondnextClicked && (
            <div className="px-10">
              <p className="text-2xl font-semibold">Select a client:</p>

              <div className="w-full my-10">
                <select
                  id="client"
                  name="client"
                  value={selectedclient}
                  onChange={handleSelectclient}
                  className="block bg-white rounded-lg py-2 pl-5 leading-tight focus:outline-none border border-violet-600 w-full"
                >
                  <option value="">Select Client</option>

                  {combinedData.length !== 0 ? (
                    combinedData.map((client) => (
                      <option
                        key={client?.userId}
                        value={client?.email}
                        className="border rounded-md"
                      >
                        {(client?.firstName !== null ||
                          client?.lastName !== null) &&
                          `Name: ${client?.firstName} ${client?.lastName} `}
                        {`Email: ${client?.email}`}
                      </option>
                    ))
                  ) : (
                    <option>Loading...</option>
                  )}
                </select>
              </div>
              <div className="flex flex-row justify-between items-center gap-5 w-full">
                <button
                  onClick={() => {
                    closeModal();
                  }}
                  className="inline-block py-2 text-sm font-medium text-violet-600 border border-violet-600 rounded hover:bg-violet-600 hover:text-white active:bg-indigo-500 focus:outline-none focus:ring w-1/2"
                >
                  Back
                </button>

                <button
                  className="inline-block py-2 text-sm font-medium text-white bg-violet-600 border border-violet-600 rounded active:text-violet-500 hover:bg-transparent hover:text-violet-600 focus:outline-none focus:ring w-1/2"
                  onClick={() => {
                    setNextClicked(true);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {nextClicked && !secondnextClicked && (
            <div className="">
              <p className="text-2xl text-center font-semibold mb-3">
                <span className="font-semibold">
                  Please Confirm Client Details
                </span>
              </p>
              <p className="mb-2">
                <span className="font-semibold">Name:</span>{" "}
                {selectedClientData?.firstName} {selectedClientData?.lastName}
              </p>
              <p className="mb-2">
                <span className="font-semibold">Email:</span>{" "}
                {selectedClientData?.email}
              </p>
              <p className="mb-2">
                <span className="font-semibold">Mobile:</span>{" "}
                {selectedClientData?.mobile}
              </p>
              <p className="mb-4 w-[450px] overflow-hidden">
                <span className="font-semibold">Wallet Address:</span>{" "}
                {selectedClientData?.address}
              </p>
              <div className="flex justify-between items-center gap-4 w-full">
                <button
                  onClick={() => {
                    setNextClicked(false);
                  }}
                  className="inline-block py-2 text-sm font-semibold text-violet-600 border border-violet-600 rounded hover:bg-violet-600 hover:text-white active:bg-indigo-500 focus:outline-none focus:ring w-1/2"
                >
                  <span className="font-semibold">Back</span>
                </button>

                <button
                  className="inline-block py-2 text-sm font-semibold text-white bg-violet-600 border border-violet-600 rounded active:text-violet-500 hover:bg-transparent hover:text-violet-600 focus:outline-none focus:ring w-1/2"
                  onClick={() => {
                    setSecondNextClicked(true);
                    setOpenTalewalletOnrampModal(true);
                  }}
                >
                  <span className="font-semibold">Confirm</span>
                </button>
              </div>
            </div>
          )}

          {secondnextClicked && openTalewalletOnrampModal && (
            <TaleWalletOnRampModal
              openModal={openTalewalletOnrampModal}
              setOpenModal={setOpenTalewalletOnrampModal}
              // closeBuyModal={closeModal}
              // allCoin={allCoin}
              // allCoinPrice={allCoinPrice}
              // amount={amount}
              // basketId={basketId}
              // tokenPercentArray={tokenPercentArray}
              // tokenArray={tokenArray}
              // basketBlockchain={basketBlockchain}
              isTokenDetailsPage={true}
              symbol={symbol}
              blockchain={appCtx?.managersBlockchain}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}

export default SelectClientModal;
