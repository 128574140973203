export const nativeToken = {
  ALGORAND: "ALGO",
  ETHEREUM: "ETH",
  POLYGON: "MATIC",
  BSC: "BNB",
  SOLANA: "SOL",
};

export const getImageUrl = (key) => {
  switch (key) {
    case "ALGORAND":
      return "/images/algorand.png";
    case "ETHEREUM":
      return "https://talewallet-testnet.s3.ap-southeast-1.amazonaws.com/coin-icon/eth.png";
    case "POLYGON":
      return "https://talewallet-testnet.s3.ap-southeast-1.amazonaws.com/coin-icon/matic.png";
    case "BSC":
      return "https://talewallet-testnet.s3.ap-southeast-1.amazonaws.com/coin-icon/bnb.png";
    case "SOLANA":
      return "https://talewallet-assets.s3.ap-southeast-1.amazonaws.com/token-icon/Solana.png";
    default:
      return "";
  }
};
