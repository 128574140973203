import "./App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "./pages/Login";
import Home from "./pages/Home";
import AuthorizedPageCard from "./components/AuthorizedPageCard";
import Clients from "./pages/Clients";
import LandingPage from "./components/Home/LandingPage";
import CryptoBasket from "./pages/cryptobasket/CryptoBasket";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CryptoBasketDetails from "./pages/cryptobasket/CryptoBasketDetails";
import MyAccount from "./pages/Clients";
import CryptoLensArticleDetails from "./pages/CryptoLensArticleDetails";
import Wallet from "./pages/Wallet";
import Movies from "./pages/Movies/Movies";
import MovieDetails from "./pages/Movies/MovieDetails";
import UserDetails from "./pages/UserDetails";
import CryptoBasketPage from "./pages/cryptobasket/CryptoBasketPage";
import Profile from "./pages/Profile/Profile";
import Distributor from "./pages/Distributor";
import DistributorDetails from "./pages/DistributorDetails";
import { TokenDetailsPage } from "./pages/TokenDetailsPage";
import RiskProfiler from "./pages/RiskProfiler";
import RiskProfilingTool from "./pages/RiskProfilingTool";
import AddQuestion from "./pages/AddQuestion";
import Tokens from "./pages/Tokens";

function App() {
  const appCtx = useSelector((state) => state.app);
  return (
    <>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme={"light"}
        // toastStyle={
        //   appCtx.isDarkMode
        //     ? { backgroundColor: "#27272A", color: "#E2E8F0" }
        //     : { backgroundColor: "#F8FAFC", color: "#1F2937" }
        // }
      />
      <BrowserRouter>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="distributors" element={<Distributor />} />
          <Route path="risk-profiling-tool" element={<RiskProfilingTool />} />
          <Route
            path="/distributor/:id/:name"
            element={<DistributorDetails />}
          />
          <Route
            path="/"
            element={
              appCtx.isLoggedIn ? <AuthorizedPageCard /> : <LandingPage />
            }
          >
            <Route path="home" element={<Home />} />
            <Route path="clients" element={<Clients />} />
            <Route
              path="/crypto-basket"
              element={
                !appCtx.isLoggedIn ? <LandingPage /> : <CryptoBasketPage />
              }
            />
            <Route
              path="/crypto-basket-details/:id"
              element={<CryptoBasketDetails />}
            />
            <Route path="/my-account" element={<MyAccount />} />
            <Route
              path="/cryptolens_updates/:id"
              element={<CryptoLensArticleDetails />}
            />
            <Route path="/wallet/:id" element={<UserDetails />} />
            <Route path="/tokens" element={<Tokens />} />
            <Route path="/movie/:id" element={<MovieDetails />} />
            <Route path="/movies" element={<Movies />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/add-question/:id" element={<AddQuestion />} />
            <Route path="/risk-profiler" element={<RiskProfiler />} />
            <Route
              path="/token/:tokenid/:userId/:tokoenname"
              element={<TokenDetailsPage />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
