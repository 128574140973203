import React, { useEffect, useState } from "react";
import Footer from "../components/Footer/Footer";
import { AccountAddressBar } from "../components/ui/AccountAddressBar";
import { useNavigate, useParams } from "react-router-dom";
import { Popover } from "react-tiny-popover";
import ChainDropdownModal from "../components/ui/ChaindrodownModal/ChainDropdownModal";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { nativeToken } from "../config";
import { Button, Stack, Tooltip } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Skeleton } from "@mui/material";
import TokenLIstCard from "../components/ui/TokenLIstCard/TokenLIstCard";
import { useWalletView } from "../hooks/useWalletView";
import { usehandleBuyTaleCoin } from "../hooks/handleBuyTaleCoin";
import { appActions } from "../context/app-slice";
import SendAlgosModal from "../components/ui/BuyAndSellAlgos/sendAlgos/SendAgosModal";
import SwapModal from "../components/ui/swap/SwapModal";
import AddFundModal from "../components/ui/InvestorsAccounts/AddFundModal";
import AssetPulse from "../components/ui/News/AssetPulse";
import OtherAssestsView from "./OtherAssestsView";
import CustomButton from "../components/CustomButton";
import { Add } from "@mui/icons-material";
import AssestsView from "./AssestsView";
import CryptoBasket from "./cryptobasket/CryptoBasket";
import useAuthorizedHttp from "../hooks/use-authorized-http";
import { toast } from "react-toastify";
import TaleWalletOnRampModal from "../components/TaleWalletOnRampModal/TaleWalletOnRampModal";
import TaleWalletOffRampModal from "../components/TaleWalletOffRamp/TaleWalletOffRampModal";
import SearchToken from "../components/ui/TokenLIstCard/SearchToken";
import TokenChainDropdownModal from "../components/ui/ChaindrodownModal/TokenChainDropdownModal";
import ManagersTokenCard from "../components/ui/TokenLIstCard/ManagersTokenCard";

const Tokens = ({ clientId, clientDetails }) => {
  const [openchainModalVisible, setOpenchainModalVisible] = useState(false);
  const [blockchainobject, setBlockchainobject] = useState(nativeToken);
  const [openSendAlgosModal, setOpenSendAlgosModal] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isManagersTokenPage, setIsManagersTokenPage] = useState(false);

  const [algoValue, setAlgoValue] = useState(0);
  const [algoValueLoading, setAlgoValueLoading] = useState(false);

  // const [blockchain, setBlockchain] = useState("");
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedWalletTab, setSelectedWalletTab] = useState(0);
  const [sync, setSync] = useState(false);
  const [managersBlockchain, setManagersBlockchain] = useState("BSC");
  const [searchQuery, setSearchQuery] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();
  const makeRequest = useAuthorizedHttp();

  const appCtx = useSelector((state) => state.app);

  const {
    token,
    accountAsset,
    setAccountAsset,
    amount,
    setAmount,
    assetUrl,
    setAssetUrl,
    handleOptIn,
    showAssets,
    optedIn,
    taleAmount,
    showTaleData,
    optInSuccessfull,
    setOptInSuccessfull,
    handleCustodialOptIn,
    minBalance,
    getAssetsById,
    assetLoader,
    allCoin,
    allCoinPrice,
    usersToken,
    isAssetLoading,
    setIsAssetLOading,
    isCoinLoading,
    icon,
    otheraccountAsset,
    otherassetLoader,
    openSwapModal,
    setopenSwapModal,
    openSellModal,
    setopenSellModal,
    getAllCoin,
    getAllCoinPrice,
    getUsersToken,
    setAllcoinPrice,
    isCoinPriceLoading,
  } = useWalletView();

  const { handleBuyAlgos } = usehandleBuyTaleCoin(appCtx);
  const dispatch = useDispatch();

  const info = (index, id) => {
    navigate(`/asset/${accountAsset[index].assetId}`);
    // let ipfsHash = url?.split('/')
    dispatch(appActions.setMetaData(accountAsset[index].assetId));
  };

  const handleTabClick = (tabIndex) => {
    setSelectedTab(tabIndex);
  };

  useEffect(() => {
    setManagersBlockchain("BSC");
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFooter(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const filteredOptions = Object.keys(blockchainobject).filter(
      (key) => key !== managersBlockchain
    );

    setFilteredOptions(filteredOptions);
  }, [managersBlockchain, blockchainobject]);

  var address = appCtx?.walletAddress?.find(
    (addr) => addr.blockchain === managersBlockchain
  )?.address;

  useEffect(() => {
    getAllCoin(managersBlockchain);
    getAllCoinPrice(managersBlockchain);
  }, [managersBlockchain, address, sync]);
  console.log("filteredOptions", filteredOptions);

  let currentToken = managersBlockchain;
  let value = 0;
  let price = 0;
  if (allCoinPrice && nativeToken[currentToken] && amount && !isNaN(amount)) {
    price = allCoinPrice[nativeToken[currentToken]]?.price;
    if (price && !isNaN(price)) {
      value = price * amount;
    }
  }

  // useEffect(() => {
  //   console.log("amount", amount);
  //   if (amount > 0 && value <= 0) {
  //     setOtherValueLoading(true);
  //   } else {
  //     setOtherValueLoading(false);
  //   }
  // }, [appCtx.usersblockchain, amount]);

  useEffect(() => {
    if (managersBlockchain === "ALGORAND") {
      if (amount > 0) {
        setAlgoValueLoading(true);
        let config = {
          method: "get",
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/convert/algorand/${amount}/to/usd`,
          headers: {
            "X-Auth-Token": appCtx.authToken,
            "Content-Type": "application/json",
          },
        };

        axios.request(config).then((response) => {
          setAlgoValue(response.data.usd);
          setAlgoValueLoading(false);
        });
      }
    }
  }, [managersBlockchain, amount]);

  useEffect(() => {
    const url = window.location.href;
    const searchStrings = ["tokens"];
    let isPageFound = false;

    for (const searchString of searchStrings) {
      if (url.includes(searchString)) {
        isPageFound = true;
        break;
      }
    }

    setIsManagersTokenPage(isPageFound);
  }, []);

  // calling additional price api
  const [pausePriceApiCall, setPausePriceApiCall] = useState(false);

  useEffect(() => {
    if (
      allCoin.length !== 0 &&
      allCoin.length !== Object.keys(allCoinPrice).length &&
      !isCoinLoading &&
      !isCoinPriceLoading &&
      !pausePriceApiCall
    ) {
      const tokenSymbolsWithNoPrice = allCoin
        ?.filter((coin) => !allCoinPrice[coin?.symbol?.toUpperCase()]?.price)
        .map((coin) => coin.symbol);

      makeRequest(
        {
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/price`,
          data: tokenSymbolsWithNoPrice,
          method: "post",
          headers: {
            "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
            "Content-Type": "application/json",
          },
        },
        (data) => {
           if (Object.keys(data).length === 0) {
             setPausePriceApiCall(true);
           } else {
             setAllcoinPrice((prev) => ({ ...prev, ...data }));
           }
        },
        (error) => {}
      );
    }
  }, [allCoin, allCoinPrice]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredTokens = allCoin.filter((token) =>
    token.tokenName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="text-black">
      {/* popover  */}

      <div className="pb-20">
        <div className="mb-10 ">
          <div className="w-[90%] mx-auto">
            {/* top part  */}
            <div className="flex flex-row justify-between items-center">
              <div className="flex ">
                <Popover
                  isOpen={() => setOpenchainModalVisible(true)}
                  positions={["right"]} // if you'd like, you can limit the positions
                  padding={0} // adjust padding here!
                  reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
                  onClickOutside={() => {
                    setOpenchainModalVisible(false);
                  }} // handle click events outside of the popover/target here!
                  content={(
                    { position, nudgedLeft, nudgedTop } // you can also provide a render function that injects some useful stuff!
                  ) => (
                    <>
                      <TokenChainDropdownModal
                        userId={id}
                        currencyvisible={openchainModalVisible}
                        setCurrencyVisible={setOpenchainModalVisible}
                        blockchainobject={blockchainobject}
                        filteredOptions={filteredOptions}
                        setManagersBlockchain={setManagersBlockchain}
                      />
                    </>
                  )}
                >
                  <div
                    className="flex-column"
                    onClick={(e) => {
                      setOpenchainModalVisible(true);
                    }}
                  >
                    <Tooltip title={`${""}`}>
                      <Button
                        style={{
                          background: "white",
                          border: "1px solid #B009FF",
                          borderRadius: "34px",
                          color: "black",
                        }}
                        className="w-[200px] flex flex-row justify-between items-center "
                      >
                        <img
                          src="/images/currencyactive.svg"
                          alt=""
                          srcset=""
                          className="mr-2"
                        />

                        <h5 className="text-md	font-medium		">
                          {managersBlockchain}
                        </h5>

                        <ArrowDropDownIcon />
                      </Button>
                    </Tooltip>
                  </div>
                </Popover>
              </div>
              <div className="">
                <div className="relative flex items-center w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden">
                  <div className="grid place-items-center h-full w-12 text-gray-300">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </div>

                  <input
                    className="peer h-full w-full outline-none text-sm text-gray-700 pr-2"
                    type="text"
                    value={searchQuery}
                    onChange={handleSearch}
                    placeholder="Search Token.."
                  />
                </div>
              </div>
            </div>

            {/* bottom part  */}
            {isCoinLoading ? (
              <>
                {[...Array(4)].map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    height={70}
                    className=" mt-[20px] rounded-full w-[100%] mx-auto"
                  />
                ))}
              </>
            ) : (
              filteredTokens?.map((coin, index) => {
                return (
                  <div>
                    <ManagersTokenCard
                      key={index}
                      coin={coin}
                      allCoinPrice={allCoinPrice}
                      usersToken={usersToken}
                      userId={appCtx?.userDetails?.userId}
                      isManagersTokenPage={isManagersTokenPage}
                    />
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tokens;
