import { ArrowForward, CopyAll, Search } from "@mui/icons-material";
import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ethers } from "ethers";
import useAuthorizedHttp from "../../../../hooks/use-authorized-http";
import algosdk from "algosdk";
import { toast } from "react-toastify";
import AlgorandClient from "../../../services/algorandsdk";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import CustomButton from "../../../CustomButton";
import { nativeToken } from "../../../../config";
import { CircularProgress } from "@mui/material";
import SignupPageModal from "../CustodialWalletSetup/SignupPageModal";
var CryptoJS = require("crypto-js");

function ConfirmSendPaymentModal({
  openModal,
  setOpenModal,
  setPreviousOpen,
  address,
  amount,
  algos,
  setAlgos,
  showAssets,
  setInfo,
  option,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const makeAuthorizedRequest = useAuthorizedHttp();
  const [loader, setLoader] = useState(false);
  const [openSignupPageModal, setOpenSignupPageModal] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [proceedToPay, setProceedToPay] = useState(false);
  const appCtx = useSelector((state) => state.app);
  const width = window.screen.width;
  const customStyles = {
    content: {
      top: "53%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "inherit",
      width: width > 1200 ? "400px" : "370px",
    },
  };

  function closeModal() {
    setOpenModal(false);
  }
  const oldMnemonic =
    appCtx.blockchain === "ALGORAND"
      ? appCtx.algomnemonic?.split(" ")
      : appCtx.evmmnemonic?.split(" ");
  let decryptedData = "";
  if (appCtx.blockchain === "ALGORAND") {
    if (oldMnemonic?.length > 1) {
      var ciphertext = CryptoJS?.AES?.encrypt(
        JSON.stringify(appCtx.algomnemonic),
        appCtx?.algoencrypt || ""
      ).toString();
      let bytes = CryptoJS?.AES?.decrypt(ciphertext, appCtx?.algoencrypt || "");
      decryptedData =
        bytes.toString(CryptoJS?.enc?.Utf8) &&
        JSON.parse(bytes.toString(CryptoJS?.enc?.Utf8));
    } else {
      var bytes = CryptoJS?.AES?.decrypt(
        appCtx?.algomnemonic || '""',
        appCtx?.algoencrypt || ""
      );
      decryptedData =
        bytes.toString(CryptoJS?.enc?.Utf8) &&
        JSON.parse(bytes.toString(CryptoJS?.enc?.Utf8));
    }
  }
  if (appCtx.blockchain !== "ALGORAND") {
    if (oldMnemonic?.length > 1) {
      var ciphertext = CryptoJS?.AES?.encrypt(
        JSON.stringify(appCtx.evmmnemonic),
        appCtx?.evmncrypt || ""
      ).toString();
      let bytes = CryptoJS?.AES?.decrypt(ciphertext, appCtx?.evmncrypt || "");
      decryptedData =
        bytes.toString(CryptoJS?.enc?.Utf8) &&
        JSON.parse(bytes.toString(CryptoJS?.enc?.Utf8));
    } else {
      var bytes = CryptoJS?.AES?.decrypt(
        appCtx?.evmmnemonic || '""',
        appCtx?.evmncrypt || ""
      );
      decryptedData =
        bytes.toString(CryptoJS?.enc?.Utf8) &&
        JSON.parse(bytes.toString(CryptoJS?.enc?.Utf8));
    }
  }

  const createAccount = function () {
    // console.log(decryptedData);
    try {
      let myaccount = algosdk.mnemonicToSecretKey(decryptedData);
      return myaccount;
    } catch (err) {
      toast.error("Transaction Failed ");
    }
  };

  const keypress = async () => {
    process.stdin.setRawMode(true);
    return new Promise((resolve) =>
      process.stdin.once("data", () => {
        process.stdin.setRawMode(false);
        resolve();
      })
    );
  };
  const assetTransfer = async () => {
    setLoader(true);
    try {
      const waitForConfirmation = async function (algodclient, txId) {
        let response = await algodclient.status().do();
        let lastround = response["last-round"];
        while (true) {
          const pendingInfo = await algodclient
            .pendingTransactionInformation(txId)
            .do();
          if (
            pendingInfo["confirmed-round"] !== null &&
            pendingInfo["confirmed-round"] > 0
          ) {
            //Got the completed Transaction
            // console.log("Transaction " + txId + " confirmed in round " + pendingInfo["confirmed-round"]);
            break;
          }
          lastround++;
          await algodclient.statusAfterBlock(lastround).do();
        }
      };
      let assetID = parseInt(process.env.REACT_APP_TAIL_COIN_TOKEN);
      // Transfer New Asset:
      // Now that account3 can recieve the new tokens
      // we can tranfer tokens in from the creator
      // to account3
      // First update changing transaction parameters
      // We will account for changing transaction parameters
      // before every transaction in this example

      let params = await AlgorandClient.getTransactionParams().do();
      //comment out the next two lines to use suggested fee
      params.fee = 1000;
      params.flatFee = true;

      let sender = appCtx.walletAddress[0]?.address;
      let recipient = address;
      let revocationTarget = undefined;
      let closeRemainderTo = undefined;
      const enc = new TextEncoder();
      const note = undefined;
      //Amount of the asset to transfer
      amount = parseFloat(algos) * 100;
      let recoveredAccount1 = await algosdk.mnemonicToSecretKey(decryptedData);

      // signing and sending "txn" will send "amount" assets from "sender" to "recipient"
      let xtxn = algosdk.makeAssetTransferTxnWithSuggestedParams(
        sender,
        recipient,
        closeRemainderTo,
        revocationTarget,
        amount,
        note,
        assetID,
        params
      );

      let rawSignedTxn = xtxn.signTxn(recoveredAccount1.sk);
      try {
        let xtx = await AlgorandClient.sendRawTransaction(rawSignedTxn).do();
        // console.log("Transaction : " + xtx.txId);

        await waitForConfirmation(AlgorandClient, xtx.txId);
        toast.success("Asset Transfer Success");
        showAssets();
        setLoader(false);
        setOpenModal(false);
        setPreviousOpen(false);
        setInfo(false);
        setAlgos(0);
      } catch {
        toast.error(
          "You cannot send Tale Coin to an account which has not opted in Tale Coin asset"
        );
        setLoader(false);
      }
    } catch {
      toast.error("Asset Transfer failed");
      setLoader(false);
    }
  };

  function getProvider(blockchain) {
    if (blockchain === "POLYGON") {
      return new ethers.providers.JsonRpcProvider(
        process.env.REACT_APP_POLYGON_RPC_ENDPOINT
      );
    } else if (blockchain === "BSC") {
      return new ethers.providers.JsonRpcProvider(
        process.env.REACT_APP_BSC_RPC_ENDPOINT
      );
    } else if (blockchain === "ETHEREUM") {
      return new ethers.providers.JsonRpcProvider(
        process.env.REACT_APP_ETHEREUM_RPC_ENDPOINT
      );
    }
  }
  const handleTransaction = async () => {
    setLoader(true);

    if (appCtx.blockchain === "ALGORAND") {
      try {
        let myAccount = createAccount();
        // console.log("Press any key when the account is funded");
        // await keypress();
        // Connect your client

        //Check your balance
        let accountInfo = await AlgorandClient.accountInformation(
          myAccount.addr
        ).do();

        // Construct the transaction
        let params = await AlgorandClient.getTransactionParams().do();
        // comment out the next two lines to use suggested fee
        params.fee = algosdk.ALGORAND_MIN_TX_FEE;
        params.flatFee = true;

        // receiver defined as TestNet faucet address
        const receiver = address;
        const enc = new TextEncoder();
        const note = enc.encode("Transfer Algos");
        let amount = parseFloat(algos) * 1000000;
        let sender = myAccount.addr;
        let txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
          from: sender,
          to: receiver,
          amount: amount,
          note: note,
          suggestedParams: params,
        });

        // Sign the transaction
        let signedTxn = txn.signTxn(myAccount.sk);
        let txId = txn.txID().toString();

        // Submit the transaction
        await AlgorandClient.sendRawTransaction(signedTxn).do();

        // Wait for confirmation
        let confirmedTxn = await algosdk.waitForConfirmation(
          AlgorandClient,
          txId,
          4
        );
        //Get the completed Transaction
        // console.log("Transaction " + txId + " confirmed in round " + confirmedTxn["confirmed-round"]);
        let string = new TextDecoder().decode(confirmedTxn.txn.txn.note);
        // console.log("Note field: ", string);
        accountInfo = await AlgorandClient.accountInformation(
          myAccount.addr
        ).do();
        // console.log("Transaction Amount: %d microAlgos", confirmedTxn.txn.txn.amt);
        // console.log("Transaction Fee: %d microAlgos", confirmedTxn.txn.txn.fee);

        // console.log("Account balance: %d microAlgos", accountInfo.amount);
        toast.success("Algo Transfer Successfull");
        showAssets();
        setLoader(false);
        setOpenModal(false);
        setPreviousOpen(false);
        setInfo(false);
        setAlgos(0);
      } catch (err) {
        toast.error("Algo Transfer failed");
        setLoader(false);
      }
    }
    if (appCtx.blockchain !== "ALGORAND") {
      try {
        const bytes = CryptoJS.AES.decrypt(
          appCtx.evmmnemonic,
          appCtx.evmncrypt
        );
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        const passphrase = JSON.parse(decryptedData);
        const wallet = await ethers.Wallet.fromMnemonic(passphrase);

        const provider = getProvider(appCtx?.blockchain);
        const receiver = address;
        const amount = ethers.utils.parseEther(algos.toString());
        const note = "Transfer EVM Token";

        const transaction = {
          to: receiver,
          value: amount,
          data: ethers.utils.toUtf8Bytes(note),
        };

        // Estimate the gas limit
        const gasLimit = await wallet.provider.estimateGas(transaction);

        // Build the transaction object
        const tx = {
          ...transaction,
          gasLimit: gasLimit,
        };

        // Sign the transaction
        const signedTx = await wallet.signTransaction(tx);

        // Send the signed transaction
        const txResponse = await provider.sendTransaction(signedTx);

        // Wait for confirmation
        await txResponse.wait();

        toast.success("EVM Transfer Successful");
        showAssets();
        setLoader(false);
        setOpenModal(false);
        setPreviousOpen(false);
        setInfo(false);
        setAlgos(0);
      } catch (err) {
        toast.error("EVM Transfer Failed");
        setLoader(false);
      }
    }
  };

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="w-[100%] flex justify-start mr-[20px] pt-[10px] mb-[20px]">
        <button
          className=" font-medium flex items-center leading-[10px] mr-[10px] mt-[10px] pl-[20px]"
          onClick={closeModal}
        >
          <ArrowBackIosOutlinedIcon className="bg-gray-200 shadow-md rounded-md p-1 mr-[5px]" />{" "}
          Edit
        </button>
      </div>
      <div className="flex flex-col justify-center items-center  pb-[30px] gap-5 overflow-hidden">
        <div className="flex justify-between items-center px-[20px] w-[100%] border-b border-t border-gray-300 py-[5px] text-gray-600">
          <div className="text-[13px]">
            {appCtx.walletAddress
              .find((addr) => addr.blockchain === appCtx.blockchain)
              ?.address?.substring(0, 10)}
            ...{" "}
            <CopyAll
              onClick={() => {
                navigator.clipboard.writeText(
                  appCtx.walletAddress.find(
                    (addr) => addr.blockchain === appCtx.blockchain
                  )?.address
                );
              }}
            />
          </div>
          <div>
            <ArrowForward className="border border-gray-300 bg-gray-100 text-gray-300 rounded-full" />
          </div>
          <div className="text-[13px]">
            {address?.substring(0, 10)}...{" "}
            <CopyAll
              onClick={() => {
                navigator.clipboard.writeText(address);
              }}
            />
          </div>
        </div>
        {/* <div className="bg-purple-100 border mx-[20px] border-purple-300 p-3 rounded-lg text-gray-800">
                    New address detected! Click here to add to your address book
                </div> */}
        <div className="w-[100%] ">
          <div className="w-[100%] px-[20px] bg-gray-50 py-[10px] border-b border-t border-gray-300 flex flex-col gap-1">
            <div className="w-[150px] flex justify-center p-1 rounded-lg border border-gray-300 text-gray-800">
              {appCtx.blockchain === "ALGORAND"
                ? option === "tale"
                  ? "SENDING TALE"
                  : "SENDING ALGOS"
                : `SENDING ${option}`}
            </div>
            <div className="text-[20px] font-medium">
              {appCtx.blockchain === "ALGORAND"
                ? `${algos} ${option === "tale" ? "Tale" : "Algo"}`
                : `${algos} ${option}`}
            </div>
            {appCtx.blockchain === "ALGORAND" && option !== "tale" && (
              <div className="text-[15px]">${amount}</div>
            )}
          </div>
          <div className="w-[100%] px-[20px] bg-gray-50 py-[10px] border-b border-t border-gray-300 flex flex-col gap-1">
            <div className="flex justify-between text-[14px]">
              <b>Estimated gas fee</b>
              <div className="font-medium">
                0.001 {nativeToken[appCtx.blockchain]}
              </div>
            </div>
            <div className="flex justify-between text-[14px]">
              <div className="font-bold">Total Transfer</div>
              {appCtx.blockchain === "ALGORAND" ? (
                option === "tale" ? (
                  <div>
                    <b className="font-medium">{algos} Tale</b>
                  </div>
                ) : (
                  <div>
                    {amount}&nbsp;&nbsp;
                    <b className="font-medium">{algos} Algo</b>
                  </div>
                )
              ) : (
                <div>
                  <b className="font-medium">
                    {algos} {option}
                  </b>
                </div>
              )}
            </div>
            <div className="flex justify-between text-[14px]">
              {option !== "tale" && (
                <>
                  <div className="text-gray-700 text-[12px]">
                    Amount + gas fee
                  </div>
                  <div className="flex justify-between gap-2">
                    <b>Max amount:</b>
                    <div>
                      {parseFloat(algos) + 0.001} {option}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="w-[100%] flex justify-center mt-[10px] px-[10px] gap-5">
            <CustomButton
              primary={false}
              className="w-[200px] h-[40px] "
              onClick={() => {
                closeModal();
                setPreviousOpen(false);
                setAlgos(0);
              }}
            >
              Reject
            </CustomButton>
            <CustomButton
              primary={true}
              className="w-[200px] h-[40px] flex justify-center items-center gap-2"
              onClick={() => {
                if (decryptedData !== "") {
                  if (option === "tale") {
                    assetTransfer();
                  } else {
                    proceedToPay
                      ? handleTransaction()
                      : setOpenPasswordModal(true);
                  }
                } else {
                  setOpenSignupPageModal(true);
                }
              }}
            >
              Confirm
              {loader ? (
                <CircularProgress style={{ width: "30px", height: "30px" }} />
              ) : (
                ""
              )}
            </CustomButton>
          </div>
        </div>
        <SignupPageModal
          openModal={openSignupPageModal}
          setOpenModal={setOpenSignupPageModal}
          transfer={true}
          recieverAddress={address}
          option={option}
          amount={algos}
        />
      </div>
    </Modal>
  );
}

export default ConfirmSendPaymentModal;
