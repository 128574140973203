import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import LandingPageFooter from "../components/Footer/LandingPageFooter/LandingPageFooter";
import { useNavigate, useParams } from "react-router";
import {
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Skeleton,
} from "@mui/material";
import useHttp from "../hooks/use-http";
import { toast } from "react-toastify";

const RiskProfilingTool = () => {
  const makeRequest = useHttp();
  const navigate = useNavigate();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(-1);
  const [questionList, setQuestionList] = useState([]);
  const [userAnswers, setUserAnswers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [isAnswerSelected, setIsAnswerSelected] = useState(false);
  const [openResultScreen, setOpenResultScreen] = useState(false);
  const [result, setResult] = useState(null);
  const [resultLoading, setResultLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  let userId = queryParams.get("userId");
  let leadId = queryParams.get("leadId");

  const fetchQuestionList = (id) => {
    setLoader(true);

    makeRequest(
      {
        url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/fund/magnet/risk/profile/question/list?wmUserId=${id}`,
        method: "get",
        headers: {
          "X-App-Token": process.env.REACT_APP_APP_TOKEN,
          "Content-Type": "application/json",
        },
      },
      (data) => {
        setQuestionList(data);
        setLoader(false);
      },
      (rej) => {
        setLoader(false);
      }
    );
  };

  useEffect(() => {
    fetchQuestionList(userId);
  }, []);

  const handlePrevious = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    setSelectedAnswerIndex(-1);
  };

  const handleNext = () => {
    if (!isAnswerSelected) {
      setShowError(true); // Show error if no answer is selected when 'Next' is clicked.
    } else {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setSelectedAnswerIndex(-1); // Reset for next question.
      setIsAnswerSelected(false); // Reset for the next question.
    }
  };

  const handleAnswerChange = (event, index) => {
    setSelectedAnswerIndex(index);
    setIsAnswerSelected(true);
    setShowError(false);

    // Update userAnswers state with the newly selected answer
    const option = JSON.parse(currentQuestion.options)[index];
    const newAnswer = {
      answer: option.option,
      answerScore: option.score,
      leadId: leadId,
      questionId: currentQuestion.questionId,
    };

    setUserAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[currentQuestionIndex] = newAnswer;
      return updatedAnswers;
    });
  };

  const handleSubmit = () => {
    if (isAnswerSelected) {
      setSubmitClicked(true);
      setIsSubmitting(true);
      setResultLoading(true);
      makeRequest(
        {
          url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/fund/magnet/risk/profile/answer/save`,
          data: userAnswers,
          method: "post",
          headers: {
            "X-App-Token": process.env.REACT_APP_APP_TOKEN,
            "Content-Type": "application/json",
          },
        },
        (data) => {
          setIsSubmitting(false);
          setOpenResultScreen(true);
          setResult(data);
          setResultLoading(false);
        },
        (error) => {
          setIsSubmitting(false);
          setResultLoading(false);
          //  toast.error("Failed to save questionnaire");
        },
        () => {}
      );
    } else {
      setShowError(true);
    }
  };

  const currentQuestion = questionList[currentQuestionIndex];

  return (
    <div>
      <Header />
      <div className="bg-slate-100  pt-[120px]  ">
        <p className="text-3xl font-medium text-center pt-5">
          Risk profiling tool
        </p>
        <div className="pb-20">
          <div className="bg-white rounded-lg w-[70%] mx-auto mt-5 ">
            {loader ? (
              <div className="flex flex-row justify-center items-center py-10">
                <CircularProgress size={20} className=" text-black" />
              </div>
            ) : (
              <>
                {currentQuestion && !submitClicked && (
                  <div className="p-14">
                    <p className="text-[#174082] text-2xl font-medium text-center ">
                      How much risk can you take?
                    </p>
                    <p className="text-[#174082] text-md text-center mt-2">
                      6 questions that can help you know your risk appetite and
                      demographic score
                    </p>

                    <p className="mb-4 bg-[#F5F5F5] p-3 text-center text-xl font-medium text-[#174082] mt-10">
                      Q{currentQuestionIndex + 1}: {currentQuestion.question}
                    </p>

                    <div className="space-y-2">
                      {JSON.parse(currentQuestion.options).map(
                        (option, index) => (
                          <div
                            key={index}
                            className="ml-[20%] text-sm font-medium text-gray-600"
                          >
                            <label className="inline-flex items-center">
                              <input
                                type="radio"
                                checked={selectedAnswerIndex === index}
                                onChange={(event) =>
                                  handleAnswerChange(event, index)
                                }
                                className="form-checkbox rounded-full h-5 w-5 text-[#174082] transition duration-150 ease-in-out"
                              />
                              <span className="ml-2">{option?.option}</span>
                            </label>
                          </div>
                        )
                      )}
                    </div>

                    <div className="mt-4 flex flex-row justify-between items-center">
                      <div className="">
                        {showError && (
                          <p className="text-red-500 ">
                            Please select an answer to move to the next
                            question.
                          </p>
                        )}
                      </div>
                      <div className="flex flex-row gap-5">
                        {currentQuestionIndex > 0 && (
                          <button
                            onClick={handlePrevious}
                            className="flex flex-row gap-2  items-center justify-center py-2 w-[150px] text-lg border border-purple-800 rounded-full"
                          >
                            <svg
                              className="w-4 h-4 ml-1 transform rotate-180"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <p> Previous</p>
                          </button>
                        )}

                        {currentQuestionIndex < questionList.length - 1 ? (
                          <button
                            onClick={handleNext}
                            className="flex flex-row gap-2  items-center justify-center py-2  w-[150px] text-lg text-white bg-gradient-to-r from-purple-600 to-purple-800 rounded-full "
                          >
                            <p>Next</p>
                            <svg
                              class="w-4 h-4 ml-1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                          </button>
                        ) : (
                          <button
                            onClick={() => handleSubmit()}
                            className="flex flex-row gap-2  items-center justify-center py-2  w-[150px] text-lg text-white bg-gradient-to-r from-purple-600 to-purple-800 rounded-full "
                          >
                            <p>Submit</p>
                            <svg
                              class="w-4 h-4 ml-1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {submitClicked && (
              <div className="w-[90%] mx-auto py-10 ">
                {resultLoading ? (
                  <div className="p-10">
                    {Array.from({ length: 5 }, (_, index) => (
                      <div key={index} className="flex flex-col ">
                        <Skeleton
                          key={index}
                          variant="rectangular"
                          height={50}
                          className=" mt-[5px] w-[100%] mx-auto"
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    <p className="text-[#174082] text-2xl font-medium text-center ">
                      Your Risk Quotient
                    </p>
                    <div className="flex flex-col justify-center items-center my-5">
                      <img
                        src={`/images/${result?.riskProfile}.png`}
                        alt=""
                        className="w-[180px]"
                      />
                    </div>

                    <p className="text-md font-medium text-center pt-5">
                      You score is {result?.riskScore}
                    </p>
                    <p className="text-[#5e6977] text-lg font-medium text-center pt-2">
                      You are a {result?.riskProfile} Risk Investor
                    </p>
                    <p className="text-[#5e6977] text-md text-center pt-2">
                      {result?.riskProfileDescription}
                    </p>
                    <p className="text-[#5e6977] text-md  text-center pt-2">
                      Not convinced?{" "}
                      <span
                        className="text-blue-600 cursor-pointer "
                        onClick={() => window.location.reload()}
                      >
                        Evaluate again
                      </span>
                    </p>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <LandingPageFooter />
    </div>
  );
};

export default RiskProfilingTool;
