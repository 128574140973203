import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./MybasketCard.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const MybasketCard = ({ basketList }) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <img
      src="/images/arrowleft.svg"
      className="w-[50px] bg-red-500"
      alt="prevArrow"
      {...props}
    />
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <img src="/images/arrowright.svg" alt="nextArrow" {...props} />
  );
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: basketList?.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full">
      {basketList?.map((basket) => {
        return (
          <div
            key={basket?.basketId}
            className=" flex justify-start items-center w-full border border-gray-200 bg-gray-50 px-[30px] py-[10px] rounded-full rounded-l-full rounded-r-full  cursor-pointer mt-[20px]"
            style={{
              background: "linear-gradient(180deg, #F1ECF5 0%, #F2EEF5 100%)",
            }}
          >
            <div className="w-[100%] flex justify-between ">
              <div
                className="w-[100%] flex justify-start items-center"
                onClick={() => {
                  navigate(`/crypto-basket-details/${basket?.basketId}`);
                }}
              >
                <div className="pr-[10px] md:pr-[20px]">
                  <img
                    src={basket?.icon}
                    className="w-[30px] md:w-[40px]"
                    alt="tail_coin"
                  />
                </div>
                <div
                  className="flex flex-col justify-start 
                    "
                >
                  <div className="font-medium text-[18px] flex justify-start">
                    {basket?.name && basket?.name?.length > 20
                      ? `${basket?.name.substring(0, 20)}...`
                      : basket?.name}
                  </div>
                  <div className="flex justify-start font-medium	text-bold">
                    {"$"}
                    {"0.0000"}

                    {"(0.0000)"}
                  </div>
                </div>
              </div>
              {/* right side  */}
              <div className="flex w-[600px] justify-between items-center  gap-5">
                <div className="flex flex-col justify-start w-[70%]">
                  <h5 className="font-medium text-left text-green-500">
                    1.82$24h | 33.20%30d | 84.82%1y
                  </h5>
                  <p>$14(12.44%)</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MybasketCard;
