import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import styles from "./SwapModal.module.css";
import axios from "axios";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import Decimal from "decimal.js";

function SwapModal({ openModal, setOpenModal, allCoinPrice, usersToken }) {
  const [tokenInChain, setTokenInChain] = useState([]);
  const [sendToken, setSendToken] = useState("");
  const [sendTokenId, setSendTokenId] = useState("");
  const [sendTokenPrice, setSendTokenPrice] = useState("");
  const [sendTokenUserBalance, setSendTokenUserBalance] = useState(0);
  const [receiveTokenUserBalance, setReceiveTokenUserBalance] = useState(0);
  const [receiveTokenPrice, setReceiveTokenPrice] = useState("");
  const [sendAmount, setSendAmount] = useState("");
  const [receiveToken, setReceiveToken] = useState("");
  const [receiveTokenId, setReceiveTokenId] = useState("");
  const [receiveAmount, setReceiveAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const appCtx = useSelector((state) => state.app);

  const customStyles = {
    content: {
      top: "53%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "inherit",
      // width: width > 1200 ? "600px" : "370px",
    },
  };
  function closeModal() {
    setOpenModal(false);
    // setTokenInChain([]);
    setSendToken(null);
    setSendTokenId(null);
    setSendTokenPrice(null);
    setSendTokenUserBalance(0);
    setReceiveTokenUserBalance(null);
    setReceiveTokenPrice(null);
    setSendAmount(null);
    setReceiveToken(null);
    setReceiveTokenId(null);
    setReceiveAmount(null);
    setSuccess(false);
  }

  useEffect(() => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/search?blockchain=${appCtx.usersblockchain}`,
      headers: {
        "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        setTokenInChain(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [appCtx.usersblockchain]);

  const handleSendTokenChange = (event) => {
    setSendToken(event.target.value);
    // setSendAmount("");
    // setReceiveToken("");
    // setReceiveAmount("");
  };

  const handleReceiveTokenChange = (event) => {
    setReceiveToken(event.target.value);
  };

  useEffect(() => {
    const sendTokenPrice = allCoinPrice[sendToken]?.price;
    const receiveTokenPrice = allCoinPrice[receiveToken]?.price;
    if (sendTokenPrice && receiveTokenPrice) {
      const receiveAmount = new Decimal(sendAmount)
        .times(sendTokenPrice)
        .div(receiveTokenPrice)
        ?.toFixed();

      console.log("receiveAmount", receiveAmount);
      setReceiveAmount(receiveAmount);
    } else {
      setReceiveAmount("");
    }
  }, [receiveToken, sendAmount, allCoinPrice, sendToken]);

  useEffect(() => {
    const send = usersToken.find((token) => token?.token === sendToken);
    const sendprice = allCoinPrice[sendToken]?.price;
    setSendTokenPrice(sendprice);
    console.log("logging send", send);
    setSendTokenId(send?.tokenId);
    setSendTokenUserBalance(send?.balance);
    const receive = usersToken.find((token) => token?.token === receiveToken);
    const receiveprice = allCoinPrice[receiveToken]?.price;
    setReceiveTokenPrice(receiveprice);
    setReceiveTokenId(receive?.tokenId);
    setReceiveTokenUserBalance(receive?.balance);
  }, [sendToken, receiveToken]);

  const handleSendAmountChange = (event) => {
    setSendAmount(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (sendAmount > sendTokenUserBalance) {
      toast.error("Insufficient Balance");
      return;
    }

    setLoading(true);

    let data = JSON.stringify({
      fromTokenId: parseInt(sendTokenId),
      toTokenId: parseInt(receiveTokenId),
      amount: parseFloat(sendAmount),
      blockchain: appCtx.usersblockchain,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/evm/erc20/token/swap`,
      headers: {
        "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setSuccess(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Token swap failed!");
      });
  };

  const availableTokens = tokenInChain?.map((token) => token.tokenShortName);
  const filteredTokens = availableTokens.filter((token) => token !== sendToken);

  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        className={`${styles.popup} w-[600px] p-10 `}
      >
        {!success && (
          <div className="w-[100%] flex justify-end mr-[20px] pt-[10px] ">
            <button
              className=" font-medium  text-2xl flex items-center leading-[10px] mr-[10px] mt-[10px]"
              onClick={closeModal}
            >
              X
            </button>
          </div>
        )}

        <div className="pb-10 px-10">
          {!success && (
            <form
              onSubmit={handleSubmit}
              className="flex flex-col items-center"
            >
              <div className="w-full max-w-md space-y-2">
                {sendToken && (
                  <p>
                    I have {sendTokenUserBalance?.toFixed(5)} {sendToken}
                  </p>
                )}

                <div className="flex flex-row w-full bg-gray-200 p-5 rounded-lg">
                  <div className=" flex-grow mr-5">
                    <select
                      id="sendToken"
                      name="sendToken"
                      value={sendToken}
                      onChange={handleSendTokenChange}
                      className="block  bg-white   rounded-lg py-2 pl-3  leading-tight focus:outline-none  border-2 border-purple-200  w-full"
                    >
                      <option value="">Select Token</option>
                      {availableTokens?.map((token) => (
                        <option key={token} value={token}>
                          <img
                            src={
                              tokenInChain.find(
                                (t) => t.tokenShortName === token
                              )?.tokenIcon
                            }
                            alt=""
                          />
                          {token}
                        </option>
                      ))}
                    </select>
                    {sendTokenPrice && (
                      <label htmlFor="">${sendTokenPrice?.toFixed(5)}</label>
                    )}
                  </div>
                  <div className=" flex-grow ">
                    <input
                      id="sendAmount"
                      placeholder="Enter amount"
                      name="sendAmount"
                      type="number"
                      min="0"
                      step="any"
                      value={sendAmount}
                      onChange={handleSendAmountChange}
                      className="block rounded-xl  border-2 border-purple-200 bg-white shadow-md w-full py-2 px-3  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                </div>
                <div className="flex justify-center my-3">
                  <img src="/images/doublearrow.svg" alt="" />
                </div>
                <div>
                  {receiveToken && <p>I want {receiveToken}</p>}
                  <div className="flex flex-row w-full bg-gray-200  p-5 rounded-lg">
                    <div className="flex-grow mr-5">
                      <select
                        id="receiveToken"
                        name="receiveToken"
                        value={receiveToken}
                        onChange={handleReceiveTokenChange}
                        className="block  bg-white   rounded-lg py-2 pl-3  leading-tight focus:outline-none  border-2 border-purple-200  w-full"
                      >
                        <option value="">Select Token</option>
                        {filteredTokens?.map((token) => (
                          <option key={token} value={token}>
                            {token}
                          </option>
                        ))}
                      </select>
                      {receiveTokenPrice && (
                        <label htmlFor="">
                          ${receiveTokenPrice?.toFixed(5)}
                        </label>
                      )}
                    </div>
                    <div className=" flex-grow ">
                      {" "}
                      <input
                        id="receiveAmount"
                        placeholder="Amount you will get"
                        name="receiveAmount"
                        type="number"
                        min="0"
                        step="any"
                        value={receiveAmount}
                        readOnly
                        className="block rounded-xl  border-2 border-purple-200 bg-white shadow-md w-full py-2 px-3  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-center mt-5">
                  <button
                    type="submit"
                    className="bg-gradient-to-r from-purple-700 to-purple-900 rounded-full  text-white px-20  py-2 md:py-2  "
                  >
                    Swap
                    <span>
                      {" "}
                      {loading && (
                        <CircularProgress size={20} className="ml-[5px]" />
                      )}
                    </span>
                  </button>
                </div>
              </div>
            </form>
          )}
          {success && (
            <div className="mt-10">
              <h4 className="font-semibold">
                Swap request submitted successfully
              </h4>
              <h5 className="font-semibold mt-5">Swap Details:</h5>
              <p>
                {sendAmount}
                <span className="font-semibold">{sendToken}</span>
                <span className="mr-3">to</span> {receiveAmount}
                <span className="font-semibold">{receiveToken}</span>
              </p>
              <div className="flex justify-center mt-5">
                <button
                  onClick={closeModal}
                  className="bg-gradient-to-r from-purple-700 to-purple-900 rounded-full  text-white px-20  py-2 md:py-2  "
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default SwapModal;
