import React, { useState } from "react";
import { UserIcon, FingerPrintIcon } from "@heroicons/react/24/solid";
import DemoModal from "../../DemoModal/DemoModal";

const WhyChoose = () => {
  return (
    <div className="w-[80%] mx-auto  ">
      <h1 className="text-center text-3xl md:text-5xl font-semibold w-[90%] md:w-[60%] mx-auto pb-20">
        Why Choose Fund Magnet?
      </h1>
      <div className="w-[100%]  flex flex-row justify-between gap-y-5 flex-wrap">
        <div className="flex flex-row gap-5 justify-start w-[100%] md:w-[45%]">
          <div>
            <img
              src="/images/get-users.png"
              alt=""
              className="w-[100px] why-chose"
            />
          </div>
          <div>
            <p className="text-2xl font-semibold">Manage Multiple Clients</p>
            <p className="text">
              Create and Manage Multiple DeFI wallets for all of your HNIs
              through a single dashboard provided by Fund Magnet.
            </p>
          </div>
        </div>
        <div className="flex flex-row gap-5 justify-start w-[100%] md:w-[45%]">
          <div>
            <img
              src="/images/onRamp.png"
              alt=""
              className="w-[100px] why-chose"
            />
          </div>
          <div>
            <p className="text-2xl font-semibold">
              Self-Custody Wallet & OnChain Transparency
            </p>
            <p className="text">
              Each account has its own self-custody wallet. Users can easily
              check the performance of their individual portfolios on Tale
              Wallet app.
            </p>
          </div>
        </div>
        <div className="flex flex-row gap-5 justify-start w-[100%] md:w-[45%]">
          <div>
            <img
              src="/images/onRamp.png"
              alt=""
              className="w-[100px] why-chose"
            />
          </div>
          <div>
            <p className="text-2xl font-semibold">
              Easy ONRAMP & OFFRAMP support
            </p>
            <p className="text">
              We provide an easy way for you to convert your user’s Fiat
              currencies to Crypto and Vice Versa.
            </p>
          </div>
        </div>
        <div className="flex flex-row gap-5 justify-start w-[100%] md:w-[45%]">
          <div>
            <img
              src="/images/details.png"
              alt=""
              className="w-[100px] why-chose"
            />
          </div>
          <div>
            <p className="text-2xl font-semibold">Discover New Clients</p>
            <p className="tex ">
              Wealth Managers can reach out to new low-ticket-size users on Tale
              Wallet by making crypto basket public on Fund Magnet.
            </p>
          </div>
        </div>
        <div className="flex flex-row gap-5 justify-start w-[100%] md:w-[45%]">
          <div>
            <img
              src="/images/multiple accounts.png"
              alt=""
              className="w-[100px] why-chose"
            />
          </div>
          <div>
            <p className="text-2xl font-semibold">Full Token Details</p>
            <p className="text ">
              Get continuous updates on the LIVE price of tokens along with all
              their previous prices and more details at Fund Magnet.
            </p>
          </div>
        </div>
        <div className="flex flex-row gap-5 justify-start w-[100%] md:w-[45%]">
          <div>
            <img
              src="/images/learn.png"
              alt=""
              className="w-[100px] why-chose"
            />
          </div>
          <div>
            <p className="text-2xl font-semibold">Learn with Crypto Lens</p>
            <p className="text ">
              Teach yourself about Blockchain Tech & the Crypto Industry and
              keep yourself updated with the events shaping this industry with
              Crypto Lens.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChoose;
