import React, { useEffect, useState } from "react";
import Areachart from "../components/ui/Areachart/Areachart";
import { useParams } from "react-router";
import useAuthorizedHttp from "../hooks/use-authorized-http";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import PortfolioInfoModal from "../components/PortfolioInfo/PortfolioInfoModal";
import { Skeleton, Stack } from "@mui/material";

const Performance = () => {
  const [portfolio, setPortfolio] = useState([]);
  const [portfolioLoading, setPortfolioLoading] = useState(false);
  const [openPortfolioInfoModal, setOpenPortfolioInfoModal] = useState(false);

  const { id } = useParams();
  const makeAuthorizedRequest = useAuthorizedHttp();

  useEffect(() => {
    setPortfolioLoading(true);
    makeAuthorizedRequest(
      {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/fundMagnet/user/${id}/portfolio`,
        method: "get",
      },
      (data) => {
        setPortfolio(data);
        setPortfolioLoading(false);
      },
      (rej) => {
        setPortfolioLoading(false);
      }
    );
  }, []);

  const totalBalance = portfolio
    .map((item) => item.balance)
    .reduce((acc, val) => acc + val, 0);
  const totalInvested = portfolio
    .map((item) => item.investment)
    .reduce((acc, val) => acc + val, 0);

  const totalReturn = totalBalance - totalInvested || 0;

  let returnPercentage;
  if (totalInvested === 0) {
    returnPercentage = 0;
  } else {
    returnPercentage = (totalReturn / totalInvested) * 100 || 0;
  }

  return (
    <div className="w-[100%]  ">
      {portfolioLoading ? (
        <Stack
          className="flex justify-center items-center  w-[80%] md:w-[30%] min-h-[150px]  mx-auto mt-5 mb-10"
          style={{ color: "white" }}
        >
          {/* For variant="text", adjust the height via font-size */}
          <Skeleton
            variant="rounded"
            width={450}
            height={200}
            sx={{ bgcolor: "grey.500" }}
          />
        </Stack>
      ) : (
        <div className="flex justify-center items-center gap-5 w-[80%] md:w-[40%] min-h-[70px]  mx-auto mt-5 mb-10  text-white">
          <div
            className="p-5 w-[100%] rounded-xl bg-[#5c22b5]"
            // style={{
            //   background:
            //     "linear-gradient(to bottom, #850E77, #600637, #320768)",
            // }}
          >
            <div className="flex flex-row justify-between">
              {" "}
              <h4 className="text-bold text-start pb-5">Portfolio</h4>
              <FontAwesomeIcon
                icon={faCircleInfo}
                onClick={() => {
                  setOpenPortfolioInfoModal(true);
                }}
                className="cursor-pointer"
              />
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-row justify-between ">
                <div className="flex flex-col items-start">
                  <p className="text-base font-medium">Current</p>
                  <h3 className="text-lg font-normal">
                    Rs {totalBalance?.toFixed(2)}
                  </h3>
                </div>
                <div className="flex flex-col items-end">
                  <h4 className="text-base font-medium">Invested</h4>
                  <h3 className="text-lg font-normal">
                    Rs {totalInvested?.toFixed(2)}
                  </h3>
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="flex flex-col items-start">
                  <h4 className="text-base font-medium">Return </h4>
                  Rs {totalReturn?.toFixed(2)}
                </div>
                <div className="flex flex-col items-end">
                  <h4 className="text-base font-medium">Return in %</h4>
                  Rs {returnPercentage?.toFixed(2)} %
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <p className="text-3xl font-semibold">Total AUM</p>

      <div className="pt-10 bg-[#FFFFFF] p-10 my-10">
        <Areachart />
      </div>

      <PortfolioInfoModal
        openModal={openPortfolioInfoModal}
        setOpenModal={setOpenPortfolioInfoModal}
      />
    </div>
  );
};

export default Performance;
