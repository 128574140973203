import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import { toast } from "react-toastify";

import axios from "axios";
import useAuthorizedHttp from "../../../../hooks/use-authorized-http";
import AlgorandClient from "../../../services/algorandsdk";
import { appActions } from "../../../../context/app-slice";
import Input from "../../../Input";
import { EmailOutlined } from "@mui/icons-material";
import CustomButton from "../../../CustomButton";
import { CircularProgress } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
var CryptoJS = require("crypto-js");

function SignupPageModal({
  openModal,
  setOpenModal,
  setOpenPreviousModal,
  login,
  rewards,
  otpLogin,
  transfer,
  recieverAddress,
  option,
  amount,
  recoverPopup,
  payAlgos,
  nftId,
  blockchainNftId,
  setOpenModalPaymentSuccess,
  setOpenModalPaymentFailure,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const makeRequest = useAuthorizedHttp();
  const appCtx = useSelector((state) => state.app);
  const width = window.screen.width;
  const dark = false;
  const [userData, setUserData] = React.useState({
    email: transfer || payAlgos ? appCtx.userDetails?.email : "",
    password: "",
  });
  const [otpLoginUserEmail, setOtpLoginUserEmail] = React.useState(
    transfer || payAlgos ? appCtx.userDetails?.email : ""
  );
  const [handleEnterOtp, setHandleEnterOtp] = React.useState(false);
  const [clickledOnLoginOtp, setClickledOnLoginOtp] = React.useState(false);
  const [otp1, setOtp1] = React.useState("");
  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);
  const [reSendOtp, setReSendOtp] = React.useState(false);
  const [isVerifiedOtp, setIsVerifiedOtp] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [openRefferalTailCoinModal, setOpenRefferalTailCoinModal] =
    useState(false);
  const [continueWith, setContinueWith] = useState(false);
  let distance1 = 300,
    resendDistance = 300;
  if (reSendOtp === 0) {
    resendDistance = 300;
    distance1 = 0;
  }
  const [data, setData] = useState({});
  const oldMnemonic = appCtx.mnemonic?.split(" ");
  let decryptedData = "";
  let loginType = "";
  // var keyEncryt = CryptoJS.SHA256(JSON.stringify(appCtx?.encrypt)).toString();

  if (oldMnemonic?.length && oldMnemonic?.length > 1) {
    var ciphertext = CryptoJS?.AES?.encrypt(
      JSON.stringify(appCtx?.mnemonic),
      appCtx?.encrypt || ""
    ).toString();
    let bytes = CryptoJS?.AES?.decrypt(ciphertext, appCtx?.encrypt || "");
    decryptedData =
      bytes.toString(CryptoJS?.enc?.Utf8) &&
      JSON.parse(bytes.toString(CryptoJS?.enc?.Utf8));
  } else {
    try {
      var bytes = CryptoJS?.AES?.decrypt(
        appCtx?.mnemonic || '""',
        appCtx?.encrypt || ""
      );
      decryptedData =
        bytes.toString(CryptoJS?.enc?.Utf8) &&
        JSON?.parse(bytes.toString(CryptoJS?.enc?.Utf8));
    } catch {}
  }

  if (otpLogin) {
    loginType = "type=login";
  }
  // else if (transfer) {
  //     loginType = 'type=token_transfer'
  // }
  else if (decryptedData !== "") {
    loginType = "type=email_update";
  } else {
    loginType = "type=wallet_login";
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevValue) => ({ ...prevValue, [name]: value }));
    setOtpLoginUserEmail(e.target.value);
  };

  const handleSendOtp = () => {
    if (userData.email?.trim().length !== 0) {
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userData.email)
      ) {
        setLoader(true);
        makeRequest(
          {
            url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/otp/send?${loginType}`,
            data: { email: otpLoginUserEmail },
            method: "post",
          },
          (data) => {
            toast("OTP Sent SuccesFully!");
            setClickledOnLoginOtp(true);
            if (data.message === "Success" && data.status === true) {
              setHandleEnterOtp(true);
              setLoader(false);
              var x = setInterval(function () {
                setMinutes(Math.floor(distance1 / 60));
                setSeconds(distance1 % 60);
                distance1--;
                if (isVerifiedOtp === true) {
                  distance1 = 0;
                  resendDistance = 0;
                  clearInterval(x);
                }
                // if (distance1 < 0) {
                //     clearInterval(x);
                //     navigate("/signup");
                // }
                if (distance1 === -1) {
                  clearInterval(x);
                  // navigate("/");
                }
              }, 1000);
            }
          },
          (error) => {
            toast.error("Please try after sometime");
            setLoader(false);
          }
        );
      } else toast.error("Email ID is incorrect !");
    } else {
      toast.error("Email Id cannot be empty !");
    }
  };
  const handleCheckOtp = async () => {
    setLoader(true);
    if (appCtx.blockchain === "ALGORAND" && transfer) {
      let accountInfo = await AlgorandClient.accountInformation(
        recieverAddress
      ).do();
      let array = [];

      if (accountInfo["assets"] === undefined) {
      } else {
        var assetobj = accountInfo["assets"];
        let assetUrl = [];
        assetobj?.map((asset) => {
          array = [...array, { key: asset["asset-id"], amount: asset.amount }];
        });
      }
      const isassetIdPresent = array?.filter((assets) => {
        return assets.key == process.env.REACT_APP_TAIL_COIN_TOKEN;
      });
      if (
        (isassetIdPresent?.length > 0 && option === "tale") ||
        option === "algo"
      ) {
        makeRequest(
          {
            url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/transfer`,
            data: {
              email: otpLoginUserEmail,
              otp: otp1,
              blockchainFtId:
                option === "tale" ? process.env.REACT_APP_TAIL_COIN_TOKEN : 0,
              buyerUserAddress: recieverAddress,
              amount:
                option === "tale"
                  ? parseFloat(amount) * 100
                  : parseFloat(amount) * 1000000,
            },
            method: "post",
          },
          (data) => {
            setLoader(false);
            toast.success("Transfer Successfull");
            navigate("/");
          },
          (error) => {
            toast.error("OTP entered is incorrect !");
            setLoader(false);
          },
          () => {}
        );
      } else {
        setLoader(false);
        if (option === "tale") {
          toast.error(
            "You cannot send Tale Coin to an account which has not opted in Tale Coin asset"
          );
        } else {
          toast.error("Algo transfer unsuccessfull");
        }
      }
    }
    if (appCtx.blockchain === "ALGORAND" && payAlgos) {
      let config = {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/wallet/pay`,
        data: {
          details: {
            email: otpLoginUserEmail,
            otp: otp1,
            blockchainFtId: 0,
            buyerUserAddress: recieverAddress,
            amount: parseFloat(amount) * 1000000,
          },
          nftIds: [nftId],
        },
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": appCtx.authToken,
          // "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
        },
      };
      axios(config)
        .then((res) => {
          setLoader(false);
          // navigate('/');
          // console.log(data.status);
          if (res?.data?.status) {
            setOpenModalPaymentSuccess(true);
            toast.success("NFT Transfer Successfull");
          } else {
            setOpenModalPaymentFailure(true);
          }
          closeModal();
        })
        .catch((error) => {
          // toast.error("Something went wrong!");
          setOpenModalPaymentFailure(true);

          setLoader(false);
          closeModal();
        });
    } else {
      if (appCtx.blockchain !== "ALGORAND" && appCtx.isLoggedIn) {
        setLoader(true);
        makeOtherTokenTransfer();
      }
      if (!appCtx.isLoggedIn) {
        makeRequest(
          {
            url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/otp/verify?${loginType}`,
            data: {
              email: otpLoginUserEmail,
              otp: otp1,
              referredBy: localStorage.getItem("referral"),
            },
            method: "post",
          },
          (data) => {
            setIsVerifiedOtp(true);

            if (rewards) {
              if (
                appCtx.authToken &&
                data?.authToken &&
                appCtx.authToken !== data?.authToken &&
                appCtx.authToken !== null
              ) {
                setData(data);
                setContinueWith(true);
              } else {
                setLoader(false);

                makeRequest(
                  {
                    url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/blockchain/wallet/connect`,
                    data: {
                      address: appCtx.walletAddress[0]?.address,
                      wallet: "TALEWALLET",
                      blockchain: "ALGORAND",
                      marketplaceAddress: "0",
                      custodial: false,
                    },
                    method: "post",
                  },
                  (data1) => {
                    dispatch(
                      appActions.updateUserDetails({
                        ...appCtx.userDetails,
                        email: otpLoginUserEmail,
                      })
                    );
                    setOpenModal(false);
                  },
                  () => {
                    toast.error(
                      "wallet connect unsuccessfull!!! if this issue persists.. logout and try again"
                    );
                  }
                );
              }
            } else {
              setLoader(false);
              dispatch(
                appActions.updateUserDetails({
                  name: data.firstName + " " + data.lastName,
                  email: data.email,
                  userId: data.userId,
                })
              );
              dispatch(appActions.updateAuthToken(data?.authToken));
              window.localStorage.setItem("userId", data.userId);
              window.localStorage.setItem("authToken", data.authToken);
              setOpenModal(false);
            }

            dispatch(appActions.login(true));
          },
          (error) => {
            toast.error("OTP entered is incorrect !");
            setLoader(false);
          },
          () => {}
        );
      }
    }
  };
  const handleResendOtp = () => {
    let header = {};

    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/otp/send?${loginType}&resend=true`,
        data: { email: otpLoginUserEmail },
        method: "post",
      },
      (data) => {
        toast("OTP Sent SuccesFully!");
        setReSendOtp(true);
        distance1 = 0;
        setOtp1("");
        if (data.message === "Success" && data.status === true) {
          setHandleEnterOtp(true);
          setLoader(false);
          // var x = setInterval(function () {

          //     setMinutes(Math.floor(resendDistance / 60));
          //     setSeconds(((resendDistance % 60)));
          //     resendDistance--;
          //     if(isVerifiedOtp === true){
          //         distance1=0;
          //         resendDistance=0;
          //     }
          //     if (resendDistance < 0) {
          //         clearInterval(x);
          //         navigate("/signup");
          //     }
          //     else if (resendDistance===0) {
          //         clearInterval(x);
          //     }

          // }, 1000)
        }
      },
      () => {}
    );
  };
  const handleContinueAccount = () => {
    dispatch(
      appActions.updateUserDetails({
        name: data.firstName + " " + data.lastName,
        email: data.email,
        userId: data.userId,
      })
    );
    dispatch(appActions.setWalletAddress([{ address: data.userName }]));
    dispatch(appActions.updateAuthToken(data.authToken));
    window.localStorage.setItem("userId", data.userId);
    window.localStorage.setItem("authToken", data.authToken);
    dispatch(appActions.setMnemonicCode('""'));
    dispatch(appActions.setCustodial(true));
    setOpenModal(false);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "inherit",
      width: width > 1200 ? "500px" : "370px",
    },
  };
  function closeModal() {
    setOpenModal(false);
    // setOpenPreviousModal(true);
  }

  function makeOtherTokenTransfer() {
    let data = JSON.stringify({
      amount: amount,
      receiver: recieverAddress,
      senderUserId: appCtx.userDetails.userId,
      blockchain: appCtx.blockchain,
      token: option,
      email: appCtx.userDetails.email,
      otp: otp1,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/evm/erc20/token/transfer`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Token Transfer Successfull");
          setLoader(false);
          navigate("/");
        } else {
          toast.error("Token Transfer failed");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // console.log("isVerifiedOtp", isVerifiedOtp);
  // console.log("otp1", otp1);
  // console.log("rewards", rewards);

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={closeModal}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={rewards ? false : recoverPopup ? true : false}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      {!continueWith ? (
        <>
          {!login && !rewards && (
            <div className="w-[100%] flex justify-start ml-[20px] pt-[10px]">
              <button
                className=" font-medium flex items-center leading-[10px] mr-[10px] mt-[10px]"
                onClick={closeModal}
              >
                <ArrowBackIosOutlinedIcon className="bg-gray-200 shadow-md rounded-md p-1 mr-[5px]" />{" "}
                Back
              </button>
            </div>
          )}
          {recoverPopup && (
            <div className="w-[100%] flex justify-end  pt-[10px]">
              <button
                className=" font-medium flex items-center leading-[10px] mr-[10px] mt-[10px]"
                onClick={closeModal}
              >
                x
              </button>
            </div>
          )}
          <div className="flex flex-col justify-center items-center lg:px-[20px]">
            <img
              src="/images/talelogo.png"
              className="w-[100px] h-[100px]"
              alt="tale_logo"
            />
            {recoverPopup ? (
              <>
                <div className="text-center mb-[10px]">
                  To complete your E-KYC, you must verify your email address. If
                  you wish to use your wallet without E-KYC, you can skip the
                  process.
                </div>
              </>
            ) : rewards ? (
              <div className="text-center mb-[10px]">
                Please provide the same email Id which you submitted while
                joining the waitlist
              </div>
            ) : (
              <>
                {transfer ? (
                  <>
                    {/* <div className="text-[24px] lg:text-[30px] text-purple-600 font-semibold">Welcome</div> */}
                    <div className="text-[20px] lg:text-[20px] text-purple-600 font-semibold mt-2 mb-4">
                      {"Verify your Email to complete the transaction."}
                    </div>
                  </>
                ) : payAlgos ? (
                  <>
                    {/* <div className="text-[24px] lg:text-[30px] text-purple-600 font-semibold">Welcome</div> */}
                    <div className="text-[20px] lg:text-[20px] text-purple-600 font-semibold mt-2 mb-4">
                      {"Verify your Email to complete the Transfer."}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-[24px] lg:text-[30px] text-purple-600 font-semibold">
                      Welcome
                    </div>
                    <div>{login ? "Login with OTP" : "Signup with OTP"}</div>
                  </>
                )}
              </>
            )}
            <div className="w-[100%] px-[20px]">
              <div className={`${!clickledOnLoginOtp ? "h-[100px]" : ""}`}>
                {/* style={{ marginTop: !clickledOnLoginOtp ? "" : "59px" }}> */}
                <div className="mb-[8px]">
                  {!clickledOnLoginOtp ? (
                    <>
                      <div className="font-medium mb-[8px]">Your Email Id</div>
                      <div>
                        <Input
                          className="w-[100%] h-[45px] shadow-xl border border-gray-300 rounded-xl pl-[50px]"
                          disabled={transfer || payAlgos}
                          value={otpLoginUserEmail}
                          placeholder="Enter your Email ID"
                          onChange={handleChange}
                          name="email"
                          style={{
                            outline: "2px solid rgb(0 0 0 / 17%)",
                            outlineOffset: "3px",
                            width: "100%",
                            padding: "6px",
                          }}
                        />
                        <div className=" bg-black p-2 w-[45px] h-[45px] rounded-xl relative top-[-45px]">
                          <EmailOutlined
                            className="text-lime-500 text-[20px]"
                            style={{ fontSize: "30px" }}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex justify-center mt-[20px]">
                        {/* <OtpInput
                                        numInputs={6}
                                        isInputNum={true}
                                        inputStyle={{
                                            width: "3rem",
                                            height: "3rem",
                                            margin: "5px",
                                            fontSize: "2rem",
                                            borderRadius: 4,
                                            border: "1px solid rgba(0,0,0,0.3)",
                                            color: "black",
                                            opacity: "1"
                                        }}
                                        value={otp1}
                                        onChange={(otp) => setOtp1(otp)}
                                    /> */}
                        <Input
                          value={otp1}
                          onChange={(e) => {
                            setOtp1(e.target.value);
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="flex justify-between w-[97%] pb-[20px]">
                <div
                  className="font-bold cursor-pointer"
                  style={{
                    visibility: !reSendOtp ? "none" : "hidden",
                    display: !clickledOnLoginOtp ? "none" : "block",
                  }}
                  onClick={() => {
                    handleResendOtp();
                  }}
                >
                  Resend OTP
                </div>
                <div className="font-bold">
                  {!(minutes === 0 && seconds === 0) ? (
                    <>
                      {minutes >= 0 && minutes <= 9 ? `0${minutes}` : minutes}:
                      {seconds >= 0 && seconds <= 9 ? `0${seconds}` : seconds}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="flex justify-center pb-[20px]">
                <CustomButton
                  style={{
                    background: "#0C0C0C 0% 0% no-repeat padding-box",
                    borderRadius: "35px",
                    opacity: "1",
                    color: "#BBFF00",
                  }}
                  className="pl-[17px] pr-[17px] w-[100%] lg:w-[80%] flex justify-center items-center h-[50px]"
                  onClick={() =>
                    !clickledOnLoginOtp ? handleSendOtp() : handleCheckOtp()
                  }
                >
                  <div>{!clickledOnLoginOtp ? `Send OTP` : "Verify OTP"}</div>
                  {loader ? (
                    <CircularProgress
                      sryle={{ color: "#BBFF00", fontSize: "10px" }}
                      className="ml-[5px]"
                      fontSize="small"
                      style={{ width: "35px", height: "35px" }}
                    />
                  ) : (
                    <EastIcon className="ml-[5px]" />
                  )}
                </CustomButton>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex flex-col lg:px-[20px] justify-center items-center pb-[30px] mx-[9px] gap-10">
          <img
            src="/images/talelogo.png"
            className="w-[100px] h-[100px]"
            alt="tale_logo"
          />
          <div className="text-center">
            It seems like you already have an account associated with this email
          </div>
          <div className="w-[100%] flex flex-col justify-center items-center">
            <CustomButton
              primary={true}
              className={"w-[100%]"}
              onClick={() => {
                handleContinueAccount();
              }}
            >
              Use already connected account
            </CustomButton>
            OR
            <CustomButton
              className={"w-[100%]"}
              onClick={() => {
                dispatch(
                  appActions.updateUserDetails({
                    ...appCtx.userDetails,
                    email: data?.email || otpLoginUserEmail,
                  })
                );
                // dispatch(appActions.updateAuthToken(data?.authToken))
                setOpenModal(false);
              }}
            >
              continue with this account
            </CustomButton>
          </div>
        </div>
      )}
      {/* {openRefferalTailCoinModal && (
        <ReffaralAndTailCoinModal
          openModal={openRefferalTailCoinModal}
          setOpenModal={setOpenRefferalTailCoinModal}
        />
      )} */}
      {/* <ChooseWalletModal openModal={continueWith} setOpenModal={setContinueWith} data={data} /> */}
    </Modal>
  );
}

export default SignupPageModal;
