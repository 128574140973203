import React, { useState } from "react";
import Modal from "react-modal";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { CircularProgress } from "@mui/material";


function AddBankModal({ openModal, setOpenModal }) {
  const makeRequest = useAuthorizedHttp();
  const appCtx = useSelector((state) => state.app);
  const [accountNumber, setAccountNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [loader, setLoader] = useState(false);

  const { id } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      accountNumber,
      bankName,
      branchName,
      ifscCode,
      userId: id,
    };
    setLoader(true);
    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/fundMagnet/client/bank/account
`,
        data,
        method: "post",
      },
      (data) => {
        // setLoader(false);
        toast.success("Successfully Added the Bank Account");
        setLoader(false);
        closeModal();
      },
      (error) => {
        toast.error("failed to add bank account!");
        setLoader(false);
      },
      () => {}
    );
  };

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      borderRadius: "15px",
      zIndex: 9999,
      width: "700px",
    },
  };

  function closeModal() {
    setOpenModal(false);
  }

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <p className="text-3xl font-semibold text-center my-5">Add a Bank Account</p>
      <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
        <div className="mb-4">
          <label htmlFor="accountNumber" className="block text-lg">
            Account Number
          </label>
          <input
            type="text"
            placeholder="Enter Account Number"
            id="accountNumber"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            className="border border-gray-300 rounded-md px-4 py-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="bankName" className="block text-lg">
            Bank Name
          </label>
          <input
            type="text"
            placeholder="Enter Bank Name"
            id="bankName"
            value={bankName}
            onChange={(e) => setBankName(e.target.value)}
            className="border border-gray-300 rounded-md px-4 py-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="branchName" className="block text-lg">
            Branch Name
          </label>
          <input
            type="text"
            id="branchName"
            placeholder="Enter Branch Name"
            value={branchName}
            onChange={(e) => setBranchName(e.target.value)}
            className="border border-gray-300 rounded-md px-4 py-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="ifscCode" className="block text-lg">
            IFSC Code
          </label>
          <input
            type="text"
            id="ifscCode"
            placeholder="Enter IFSC Code"
            value={ifscCode}
            onChange={(e) => setIfscCode(e.target.value)}
            className="border border-gray-300 rounded-md px-4 py-2 w-full"
          />
        </div>
       
        <div className="flex justify-center my-5">
        <button
            type="submit"
            className="relative mr-10 inline-flex items-center justify-center  px-5 py-3 overflow-hidden font-medium bg-[#5c22b5] text-slate-100 transition duration-300 ease-out rounded-md shadow-xl group hover:ring-1 hover:ring-purple-500"
          >
            <span className="relative text-white">Add Bank Account  <span>
                    {loader && (
                      <CircularProgress
                        size={20}
                        className="ml-[5px] text-black"
                      />
                    )}
                  </span></span>
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default AddBankModal;
