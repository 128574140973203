import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CircularProgress, Skeleton } from "@mui/material";
import {
  faArrowDown,
  faArrowUp,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";
import useAuthorizedHttp from "../../../hooks/use-authorized-http";
import TokenCard from "./TokenCard";

const TokenLIstCard = ({
  decryptedData,
  allCoin,
  usersToken,
  allCoinPrice,
  assetUrl,
  handleOptIn,
  appCtx,
  optedIn,
  taleAmount,
  optInSuccessfull,
  setOptInSuccessfull,
  handleCustodialOptIn,
  isCoinLoading,
  isCoinLoaded,
  sync,
  setSync,
}) => {
  const navigate = useNavigate();
  const makeRequest = useAuthorizedHttp();
  const { id } = useParams();

  const syncToken = () => {
    makeRequest(
      {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/fundMagnet/user/${id}/token/sync`,
        method: "post",

        headers: {
          "X-App-Token": process.env.REACT_APP_APP_TOKEN,
          "Content-Type": "application/json",
        },
      },
      (data) => {
        console.log(data);

        toast.success("Sync Successfull!");
      }
    );
  };

  console.log("allCoin", allCoin);
  console.log("userstoken", usersToken);
  return (
    <div className="mb-10 ">
      <div className="w-[90%] mx-auto">
        <div className="flex flex-row justify-between items-center my-[20px]">
          <p className="text-black text-[20px] font-bold ">My Token</p>
          <div
            className="cursor-pointer flex justify-end my-5 mr-10"
            onClick={() => {
              setSync(!sync);
              syncToken();
            }}
            title="Refresh"
          >
            <FontAwesomeIcon
              icon={faArrowsRotate}
              // style={{ color: "#1e58be" }}
            />
          </div>
        </div>
        {isCoinLoading && appCtx.usersblockchain !== "ALGORAND" ? (
          <>
            {[...Array(4)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                height={70}
                className=" mt-[20px] rounded-full w-[100%] mx-auto"
              />
            ))}
          </>
        ) : (
          appCtx.usersblockchain !== "ALGORAND" &&
          (!isCoinLoading &&
          (usersToken.length === 0 ||
            usersToken
              .filter(
                (item) =>
                  item.blockchain === appCtx.usersblockchain ||
                  item.blockchain === appCtx.blockchain
              )
              .every((item) => item.balance === 0)) ? (
            <div
              className="p-4 mb-4 rounded-lg bg-[#F8D7DA] text-lg font-medium"
              role="alert"
            >
              You don't have any token
            </div>
          ) : (
            allCoin.map((coin, index) => {
              const TokenBalance = usersToken.filter(
                (element) =>
                  coin.tokenId === element.tokenId &&
                  coin.blockchain === element.blockchain
              );

              if (TokenBalance[0]?.balance > 0) {
                return (
                  <div>
                    <TokenCard
                      key={index}
                      coin={coin}
                      allCoinPrice={allCoinPrice}
                      usersToken={usersToken}
                      userId={id}
                    />
                  </div>
                );
              }
            })
          ))
        )}

        {/* explore token  */}

        {isCoinLoading ? (
          <>
            {[...Array(4)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                height={70}
                className=" mt-[20px] rounded-full w-[100%] mx-auto"
              />
            ))}
          </>
        ) : (
          !isCoinLoading &&
          (allCoin.length === 0 ? (
            <p className="text-center my-[15px] opacity-[0.5]">
              No Tokens Found
            </p>
          ) : (
            <>
              <p className="text-black text-[20px] font-bold my-[20px]">
                Explore Tokens
              </p>
              {allCoin.map((coin, index) => {
                const TokenBalance = usersToken.filter(
                  (item) => item.tokenId == coin.tokenId
                );

                console.log("TokenBalance", TokenBalance[0]?.balance);

                if (
                  TokenBalance[0]?.balance <= 0 ||
                  TokenBalance[0]?.balance === null ||
                  TokenBalance[0]?.balance === undefined
                ) {
                  return (
                    <TokenCard
                      key={index}
                      coin={coin}
                      allCoinPrice={allCoinPrice}
                      usersToken={usersToken}
                      userId={id}
                    />
                  );
                }
              })}
            </>
          ))
        )}
      </div>
    </div>
  );
};

export default TokenLIstCard;
