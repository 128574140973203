import React, { useEffect, useState } from "react";
import { Skeleton, Stack } from "@mui/material";
import { useSelector } from "react-redux";

const Priceperformance = ({ selectedPerformanceDate, tokoenname }) => {
  const [prices, setPrices] = useState([]);
  const [minprice, setMin] = useState(0);
  const [maxprice, setMax] = useState(0);
  const [ratio, setRatio] = useState(null);
  const [chartLoading, setChartLoading] = useState(false);

  const appCtx = useSelector((state) => state.app);
  useEffect(() => {
    const axios = require("axios");
    setChartLoading(true);

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/price/history?token=${tokoenname}&range=${selectedPerformanceDate}`,
      headers: {
        ...(appCtx.authToken
          ? { "X-Auth-Token": appCtx.authToken }
          : { "X-App-Token": process.env.REACT_APP_X_APP_TOKEN }),
      },
    };

    axios
      .request(config)
      .then((response) => {
        setPrices(response.data.prices);
        setChartLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Define the colors for the two halves of the bar
  //   const color1 = "blue"; // First color
  //   const color2 = "red"; // Second color

  useEffect(() => {
    let min = prices.length > 0 ? prices[0][1] : 0;
    let max = prices.length > 0 ? prices[0][1] : 0;

    for (let i = 1; i < prices.length; i++) {
      const price = prices[i][1];
      if (price < min) {
        min = price;
      }
      if (price > max) {
        max = price;
      }
    }
    setMin(min);
    setMax(max);
    setRatio(min / (min + max));
  }, [prices]);
  return (
    <div className="">
      <div className="flex justify-between items-center mt-10 mb-5">
        <div className="">
          <p className="text-xl font-normal">Low </p>
          {!chartLoading ? (
            <p className="font-semibold text-xl"> ${minprice?.toFixed(4)}</p>
          ) : (
            <Stack className=" ">
              {/* For variant="text", adjust the height via font-size */}
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            </Stack>
          )}
        </div>

        <div className="">
          <p className="text-xl font-normal">High </p>
          {!chartLoading ? (
            <p className="font-semibold text-xl">${maxprice?.toFixed(4)}</p>
          ) : (
            <Stack className=" ">
              {/* For variant="text", adjust the height via font-size */}
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            </Stack>
          )}
        </div>
      </div>
      {/* vertical bar  */}

      {!chartLoading ? (
        <div
          style={{
            height: "5px",
            background: "#D9D9D9",
            position: "relative",
            borderRadius: "2px",
          }}
          className="rounded-r-full mb-10"
        >
          <div
            style={{
              height: "100%",
              width: `${ratio * 100}%`,
              background: "linear-gradient(180deg, #805EB5 0%, #B391EB 100%)",
              // background: "#D9D9D9",
              position: "absolute",
              bottom: 0,
              left: 0,
            }}
            className=" rounded-l-full rounded-r-full "
          ></div>
          <div
            style={{
              height: "100%",
              width: `${(1 - ratio) * 100}%`,
              background: "#D9D9D9",
              // background: "linear-gradient(180deg, #805EB5 0%, #B391EB 100%)",
            }}
          ></div>
        </div>
      ) : (
        <Stack className="w-[90%] mx-auto">
          {/* For variant="text", adjust the height via font-size */}
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        </Stack>
      )}
    </div>
  );
};

export default Priceperformance;
