import algosdk from "algosdk";
import axios from "axios";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


export const useTailCoin = () => {
  const [userToken, setUserToken] = useState([]);
  const [tokenDetails, setTokenDetails] = useState(null);
  const [allCoinPrice, setAllcoinPrice] = useState(null);
  const [descriptionData, setDescriptionData] = useState(null);

  const appCtx = useSelector((state) => state.app);

  const getUsersToken = useCallback(
    async (tokenid, blockchain) => {
      const axios = require("axios");

      let config = {
        method: "get",
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/token?blockchain=${blockchain}&tokenId=${tokenid}`,
        headers: {
          "X-Auth-Token": appCtx.authToken,
        },
      };

      axios
        .request(config)
        .then((response) => {
          setUserToken(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [appCtx.walletAddress, appCtx.usersblockchain]
  );

  const getTokenDetailsbyId = async (id) => {
    try {
      const config = {
        method: "get",
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/${id}`,
        headers: {
          "X-Auth-Token": appCtx.authToken,
        },
      };
      const response = await axios(config);
      setTokenDetails(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const getAllcoinprice = async () => {
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/price?blockchain=${appCtx.usersblockchain}`,
        headers: {
          "X-Auth-Token": appCtx.authToken,
        },
      };
      const response = await axios(config);
      setAllcoinPrice(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const getDescriptionData = async (tokenid) => {
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/${tokenid}/info`,
        headers: {
          "X-Auth-Token": appCtx.authToken,
        },
      };
      const response = await axios(config);
      setDescriptionData(response.data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return {
    getUsersToken,
    userToken,
    tokenDetails,
    getTokenDetailsbyId,
    getAllcoinprice,
    allCoinPrice,
    getDescriptionData,
    descriptionData,
  };
};
