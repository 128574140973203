import React, { useEffect, useState } from "react";
import useAuthorizedHttp from "../hooks/use-authorized-http";
import { useParams } from "react-router";
import GenerateKycModal from "../components/Kyc/GenerateKycModal";
import AddBankModal from "../components/Bank/AddBankModal";
import axios from "axios";
import { useSelector } from "react-redux";

const ClientsProfile = ({ clientId, clientDetails }) => {
  const [openGenerateKycModal, setOpenGenerateKycModal] = useState(false);
  const [openAddBankModal, setOpenAddBankModal] = useState(false);
  const [userWallet, setUserWallet] = useState(null);
  const appCtx = useSelector((state) => state.app);

  const { id } = useParams();
  const [bankDatas, setBankDatas] = useState(null);

  const makeRequest = useAuthorizedHttp();

  // get clients bank account
  useEffect(() => {
    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/fundMagnet/client/${id}/bank/account/list`,
      },
      (res) => {
        setBankDatas(res);
      }
    );
  }, [openAddBankModal]);

  useEffect(() => {
    if (id) {
      let data = [id];

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/address/list`,
        headers: {
          "X-Auth-Token": appCtx.authToken,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          let data = response?.data;
          setUserWallet(response?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [appCtx.usersblockchain]);

  return (
    <>
      <div>
        <p className="text-2xl font-bold mt-2 mb-5">General Details</p>
        <table className="w-full border-collapse">
          <tbody>
            <tr className="bg-white rounded-xl">
              <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg w-1/5">
                Name
              </td>
              <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg w-1/5">
                {clientDetails?.firstName !== null &&
                  clientDetails?.lastName !== null &&
                  clientDetails?.firstName !== "null" &&
                  clientDetails?.lastName !== "null" &&
                  `${clientDetails?.firstName} ${clientDetails?.lastName}`}
              </td>
              <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg flex-1"></td>
              <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg flex-1"></td>
            </tr>
            <tr className="bg-white rounded-xl">
              <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg w-1/5">
                Emailid
              </td>
              <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg w-1/5">
                {clientDetails?.email}
              </td>
              <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg flex-1"></td>
              <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg flex-1"></td>
            </tr>
            <tr className="bg-white rounded-xl">
              <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg w-1/5">
                Mobile No
              </td>
              <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg w-1/5">
                {clientDetails?.mobile}
              </td>
              <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg flex-1"></td>
              <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg flex-1"></td>
            </tr>
            <tr className="bg-white rounded-xl">
              <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg w-1/5">
                Wallet Address
              </td>

              <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg w-1/5">
                {userWallet && "BSC"}
                {console.log("userwallet", userWallet)}
              </td>
              <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg w-1/5">
                {userWallet && userWallet?.find((wallet) => wallet?.blockchain === "BSC")?.address}
              </td>
              <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg  flex-1"></td>
            </tr>
            <tr className="bg-white rounded-xl">
              <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg  w-1/5">
                KYC Status
              </td>
              <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg  w-1/5">
                <button
                  onClick={() => {
                    setOpenGenerateKycModal(true);
                  }}
                  className="relative inline-flex items-center justify-center p-4 px-5 py-1 overflow-hidden font-medium bg-[#5c22b5] text-slate-100 transition duration-300 ease-out rounded-md shadow-xl group hover:ring-1 hover:ring-purple-500"
                >
                  <span className="relative text-white">Generate Kyc Link</span>
                </button>
              </td>
              <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg  w-1/5"></td>
              <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg flex-1"></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div>
        <div className="flex justify-between items-center">
          <p className="text-2xl font-bold mt-2 py-10">
            Withdrawal Bank Account
          </p>
          <button
            onClick={() => {
              setOpenAddBankModal(true);
            }}
            className="relative mr-10 inline-flex items-center justify-center  px-5 py-1 overflow-hidden font-medium bg-[#5c22b5] text-slate-100 transition duration-300 ease-out rounded-md shadow-xl group hover:ring-1 hover:ring-purple-500"
          >
            <span className="relative text-white">Add Bank Account</span>
          </button>
        </div>

        <div className="flex flex-col gap-10">
          {bankDatas &&
            bankDatas.map((bankData, index) => (
              <>
                {" "}
                <div key={index} className="flex justify-between my-5">
                  <p className="text-lg font-bold ">Bank Account {index + 1}</p>
                </div>
                <table className="w-full border-collapse">
                  <tbody>
                    <tr className="bg-white rounded-xl">
                      <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg w-1/5">
                        Bank Name
                      </td>
                      <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg w-1/5">
                        {bankData?.bankName}
                      </td>
                      <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg flex-1"></td>
                      <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg flex-1"></td>
                    </tr>
                    <tr className="bg-white rounded-xl">
                      <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg w-1/5">
                        Account Name
                      </td>
                      <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg w-1/5">
                        Demo Name
                      </td>
                      <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg flex-1"></td>
                      <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg flex-1"></td>
                    </tr>
                    <tr className="bg-white rounded-xl">
                      <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg w-1/5">
                        Account Number
                      </td>
                      <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg w-1/5">
                        {bankData?.accountNumber}
                      </td>
                      <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg flex-1"></td>
                      <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg flex-1"></td>
                    </tr>
                    <tr className="bg-white rounded-xl">
                      <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg w-1/5">
                        IFSC code
                      </td>
                      <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg w-1/5">
                        {bankData?.ifscCode}
                      </td>
                      <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg flex-1"></td>
                      <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg flex-1"></td>
                    </tr>
                    <tr className="bg-white rounded-xl">
                      <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg w-1/5">
                        Branch Name
                      </td>
                      <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg w-1/5">
                        {bankData?.branchName}
                      </td>
                      <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg w-1/5"></td>
                      <td className="border-b-[10px] border-[#F1F5F9] p-2  font-semibold text-lg  flex-1"></td>
                    </tr>
                  </tbody>
                </table>
              </>
            ))}
        </div>
      </div>

      <GenerateKycModal
        openModal={openGenerateKycModal}
        setOpenModal={setOpenGenerateKycModal}
        clientDetails={clientDetails}
      />
      <AddBankModal
        openModal={openAddBankModal}
        setOpenModal={setOpenAddBankModal}
      />
    </>
  );
};

export default ClientsProfile;
