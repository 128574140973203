import React, { useEffect } from "react";
import { Box, Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import { useCryptolens } from "../../hooks/useCryptolens";

const Update = () => {
  const { content, postloading, fetchPostContent } = useCryptolens();
  useEffect(() => {
    fetchPostContent();
  }, []);
  return (
    <div className="text-[#480190]">
      <h2 className="font-medium  w-[90%]"> </h2>
      <div>
        <p className="text-2xl font-bold  mb-5 "> Knowledge Base</p>
      </div>

      <div className="flex justify-between">
        <div className="w-[50%]   flex flex-row gap-5 flex-wrap  py-10 pr-10">
          {postloading ? (
            <Box className="w-full flex flex-col gap-5">
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Box>
          ) : (
            content &&
            content.map((item) => (
              <div key={item?.postId} className="">
                <Link
                  to={`/cryptolens_updates/${item.postId}`}
                  className=" flex flex-row items-center gap-2   no-underline  "
                >
                  <img
                    className="w-[70px] h-[70px]"
                    src={item.coverImage}
                    alt="Cover Image"
                  />

                  <div className="px-6 py-4">
                    <p className=" text-base font-bold">{item.title}</p>
                    <p className=" text-base">{item?.category}</p>
                  </div>
                </Link>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Update;
