import React, { useEffect, useState } from "react";
import Wallet from "./Wallet";
import ClientsProfile from "./ClientsProfile";
import Areachart from "../components/ui/Areachart/Areachart";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import useAuthorizedHtttp from "../hooks/use-authorized-http";
import { Skeleton, Stack } from "@mui/material";
import Performance from "./Performance";

const UserDetails = () => {
  const [selectedTab, setSelectedTab] = useState(2);
  const [clientsList, setClientsList] = useState("");
  const [singleclientId, setSingleclientId] = useState(null);
  const [clientDetails, setClientDetails] = useState(null);

  const [loading, setLoading] = useState(false);
  const makeRequest = useAuthorizedHtttp();

  const appCtx = useSelector((state) => state.app);
  const { id } = useParams();

  const handleTabClick = (tabIndex) => {
    setSelectedTab(tabIndex);
  };

  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/fundMagnet/wm/${appCtx.wealthManager.wmId}/client/list`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        setClientsList(response.data?.content);
        const foundItem = response.data?.content.find(
          (item) => item.userId == id
        );
        const clientId = foundItem ? foundItem?.clientId : null;
        setSingleclientId(clientId);
        makeRequest(
          {
            url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/fundMagnet/wm/client/${clientId}/detail`,
          },
          (res) => {
            setClientDetails(res);
            setLoading(false);
          }
        );
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [id]);

 

  return (
    <div className="w-[95%] mx-auto bg-white mt-5 p-5">
      <div className="ml-2">
        {!loading ? (
          <p className="text-2xl font-bold my-5">
            Client:{" "}
            <span className="text-xl font-semibold">
              {clientDetails?.firstName !== null &&
                clientDetails?.lastName !== null &&
                clientDetails?.firstName !== "null" &&
                clientDetails?.lastName !== "null" &&
                `${clientDetails?.firstName} ${clientDetails?.lastName}`}
            </span>
          </p>
        ) : (
          <Stack className="w-[20%]">
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          </Stack>
        )}
      </div>
      <div className="flex my-10">
        <button
          className={`flex-1 text-center py-2 text-xl font-semibold ${
            selectedTab === 1
              ? " text-black rounded-t-md border border-gray-300 border-b-0"
              : "border-gray-300 border-b"
          }`}
          onClick={() => handleTabClick(1)}
        >
          Profile
        </button>
        <button
          className={`flex-1 text-center py-2 text-xl font-semibold ${
            selectedTab === 2
              ? " text-black rounded-t-md border border-gray-300 border-b-0"
              : "border-gray-300 border-b"
          }`}
          onClick={() => handleTabClick(2)}
        >
          Wallet
        </button>
        <button
          className={`flex-1 text-center py-2 text-xl font-semibold ${
            selectedTab === 3
              ? " text-black rounded-t-md border border-gray-300 border-b-0"
              : "border-gray-300 border-b"
          }`}
          onClick={() => handleTabClick(3)}
        >
          Performance & Activities
        </button>
      </div>

      <div className="flex-1">
        {selectedTab === 1 && (
          <ClientsProfile
            clientId={singleclientId}
            clientDetails={clientDetails}
          />
        )}
        {selectedTab === 2 && (
          <Wallet clientId={singleclientId} clientDetails={clientDetails} />
        )}
        {selectedTab === 3 && (
          <Performance />
        )}
      </div>
    </div>
  );
};

export default UserDetails;
