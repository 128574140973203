import React, { useState } from "react";
import DemoModal from "../../DemoModal/DemoModal";

const Banner = () => {
  const [openDemoModal, setOpenDemoModal] = useState(false);
  return (
    <div className="pt-[30%] md:pt-[10%] ">
      <h1 className="text-center text-4xl md:text-5xl font-bold w-[90%] md:w-[70%] mx-auto">
        Diversify Your Client’s Portfolio With Crypto and Blockchain Assets
      </h1>
      <br />
      <h1 className="text-center text-2xl font-semibold w-[90%] mx-auto">
        For Wealth Mangers & Financial Advisors
      </h1>
      <div className="flex justify-center my-10 ">
        <button
          onClick={() => setOpenDemoModal(true)}
          class="bg-gradient-to-r from-purple-700 to-purple-900 text-white px-12 py-3  border-white border-2 rounded-full"
        >
          <span class="relative"> Request Access</span>
        </button>
      </div>
      <img src="/images/deckSS.png" alt="" className="w-[60%] mx-auto" />

      <DemoModal
        openDemoModal={openDemoModal}
        setOpenDemoModal={setOpenDemoModal}
      />
    </div>
  );
};

export default Banner;
