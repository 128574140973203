import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

const QuestionTypeModal = ({ openModal, setOpenModal }) => {
  const [loader, setLoader] = useState(false);
  const [savingQuestion, setSavingQuestion] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [selectedCard, setSelectedCard] = useState("");
  const makeAuthorizedRequest = useAuthorizedHttp();
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      // width: isMobileDevice ? "90%" : "50%",
      borderRadius: "15px",
      zIndex: 9999,
    },
  };
  function closeModal() {
    setOpenModal(false);
  }

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="relative">
        <button
          className="font-medium text-2xl absolute top-0 right-2 "
          onClick={closeModal}
        >
          X
        </button>
      </div>
      {!selectedCard && (
        <div className="p-5">
          <p className="text-xl font-medium text-center mb-3">
            Select question type
          </p>
          <div className="flex justify-center items-center ">
            <div className="flex space-x-5">
              <div
                className={` rounded-lg p-10 cursor-pointer bg-[#e2e8f0]`}
                onClick={() => {
                  navigate("/add-question/General");
                }}
              >
                <h2 className="text-lg font-medium">General</h2>
              </div>
              <div
                className={`bg-[#e0f2fe] rounded-lg p-10 cursor-pointer`}
                onClick={() => {
                  navigate("/add-question/Crypto");
                }}
              >
                <h2 className="text-lg font-medium">Crypto</h2>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default QuestionTypeModal;
