import React from "react";
import "./LandingPageFooter.css";
import PlaceIcon from "@mui/icons-material/Place";
import { useNavigate } from "react-router-dom";

export default function LandingPageFooter() {
  const navigate = useNavigate();
  return (
    <div
      className="h-[22rem]] footer flex-col w-[100%]"
      style={{
        color: "white",
      }}
    >
      {/* <img src='/images/Footerno.svg' alt='logo' className="absolute left-0 w-full"/> */}

      <div className="flex flex-col sm:flex-row sm:justify-between items-center text-left h-[100%] lg:h-[65%]">
        <div className="flex flex-col justify-end items-center w-[100%] sm:w-[60%] lg:w-[100%] gap-3">
          <div
            className="flex items-center justify-center sm:justify-start w-[100%] cursor-pointer"
            onClick={() => {
              window.open("https://www.nexttales.io", "_blank");
            }}
          >
            <img
              src="/images/talelogo.png"
              className="w-[50px] h-[50px]"
              alt=""
            />
            <spam className="ml-[10px] text-[20px] text-white">
              NextTales Technology Pvt Ltd
            </spam>
          </div>
          <div className=" text-center sm:text-left w-[100%] text-[15px] md:text-[22px] text-white">
            Built with &#10084;&#65039; by NextTales in India for world.
          </div>
          <div className=" text-center sm:text-left w-[100%] text-[10px] md:text-[18px] text-white">
            Bringing blockchain solution to masses.
          </div>
        </div>
        <div className="lg:w-[100%] gap-3 flex justify-center lg:justify-end items-end mt-[20px] sm:mt-[0px]">
          <div className="lg:w-[320px] gap-3">
            <div
              className="flex justify-center sm:justify-end items-center font-medium mb-[10px] mt-[0px] text-center text-white"
              style={{ color: "", fontSize: "21px" }}
            >
              <img
                src="/images/talelogo.png"
                className="w-[70px] h-[70px]"
                alt=""
              />
              <spam className="ml-[10px] text-[20px] text-white">
                {" "}
                Tale Wallet
              </spam>
            </div>
            <div className="flex justify-center sm:justify-end items-center mb-[10px] text-white">
              Contact: info@onnftverse.com
            </div>
            <div className="flex justify-center sm:justify-end items-center mb-[10px] text-white text-[16px] h-[20px]">
              <div
                onClick={() => {
                  window.open(
                    "https://www.talewallet.com/crypto-charcha",
                    "_blank"
                  );
                }}
                className="hover:mb-[5px] cursor-pointer"
              >
                Crypto&nbsp;Charcha&nbsp;
              </div>
              <div className="hover:mb-[5px] cursor-pointer">&nbsp;|&nbsp;</div>
              <div
                onClick={() => {
                  window.open("https://www.talewallet.com/aboutus", "_blank");
                }}
                className="hover:mb-[5px] cursor-pointer"
              >
                About&nbsp;us&nbsp;
              </div>
              <div className="hover:mb-[5px] cursor-pointer">&nbsp;|&nbsp;</div>
              <div
                onClick={() => {
                  window.open(
                    "https://www.talewallet.com/termsandcondition",
                    "_blank"
                  );
                }}
                className="hover:mb-[5px] cursor-pointer"
              >
                T&C&nbsp;
              </div>
              <div className="hover:mb-[5px] cursor-pointer">&nbsp;|&nbsp;</div>
              <div
                onClick={() => {
                  window.open(`https://www.blog.talewallet.com/`, "_blank");
                }}
                className="hover:mb-[5px] cursor-pointer"
              >
                blog
              </div>
            </div>
            <div className="flex justify-between w-auto">
              <div
                className="max-w-[18%] flex justify-center items-center w-auto cursor-pointer"
                onClick={() => {
                  window.open(
                    "https://www.instagram.com/onnftverse/",
                    "_blank"
                  );
                }}
              >
                <img
                  src="/images/instagramdark.svg"
                  alt="logo"
                  className={`w-[50px]`}
                />
              </div>
              <div
                className="max-w-[18%] flex justify-center items-center w-auto cursor-pointer"
                onClick={() => {
                  window.open(
                    "https://www.linkedin.com/company/nexttales/",
                    "_blank"
                  );
                }}
              >
                <img
                  src="/images/linkedindark.svg"
                  alt="logo"
                  className={`w-[50px]`}
                />
              </div>
              <div
                className="max-w-[18%] flex justify-center items-center w-auto cursor-pointer"
                onClick={() => {
                  window.open("https://twitter.com/TaleWallet", "_blank");
                }}
              >
                <img
                  src="/images/twitterdark.svg"
                  alt="logo"
                  className={`w-[50px]`}
                />
              </div>
              <div
                className="max-w-[18%] flex justify-center items-center w-auto cursor-pointer"
                onClick={() => {
                  window.open("https://www.facebook.com/onnftverse", "_blank");
                }}
              >
                <img
                  src="/images/facebookdark.svg"
                  alt="logo"
                  className={`w-[50px]`}
                />
              </div>
              <div
                className="max-w-[18%] flex justify-center items-center w-auto cursor-pointer"
                onClick={() => {
                  window.open("https://discord.gg/tbs47P7gDW", "_blank");
                }}
              >
                <img
                  src="/images/discorddark.svg"
                  alt="logo"
                  className={`w-[50px]`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center w-[100%] text-[10px] sm:text-[18px] text-white mt-[30px]">
        <PlaceIcon style={{ color: "#ba1b1b", fontSize: "30px" }} />
        #224, Bannerghatta Main Rd, near Arekere Gate, Omkar Nagar, Bilekahalli,
        Bengaluru, Karnataka 560076
      </div>
    </div>
  );
}
