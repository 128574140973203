import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const InvestorsPieChart = ({ tokenPercentArray, tokenArray }) => {
  console.log(tokenArray);
  console.log(tokenPercentArray);
  const [chartData, setChartData] = useState({
    series: tokenPercentArray,
    options: {
      chart: {
        type: "pie",
      },
      labels: tokenArray,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  useEffect(() => {
    setChartData({
      series: tokenPercentArray,
      options: {
        chart: {
          type: "pie",
        },
        labels: tokenArray,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    });
  }, [tokenArray]);

  return (
    <div id="chart" className="flex flex-row justify-center items-center">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="pie"
        width={450}
      />
    </div>
  );
};

export default InvestorsPieChart;
