import React, { useEffect, useState } from "react";
import useHttp from "../hooks/use-http";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useAuthorizedHttp from "../hooks/use-authorized-http";
import { CircularProgress, Skeleton } from "@mui/material";
import QuestionTypeModal from "../components/QuestionTypeModal/QuestionTypeModal";
import SendQuestionModal from "../components/SendQuestionModal/SendQuestionModal";
 import {
   Page,
   Text,
   View,
   Document,
   StyleSheet,
   PDFDownloadLink,
   Font,
 } from "@react-pdf/renderer";
 import RobotoRegular from "./fonts/Roboto-Regular.ttf";
 import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
 import { faDownload } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

Font.register({
  family: "Roboto",
  src: RobotoRegular,
});

const RiskProfiler = () => {
  const [questionList, setQuestionList] = useState([]);
  const [leadList, setLeadList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [openQuestionTypeModal, setOpenQuestionTypeModal] = useState(false);
  const [openSendQuestionModal, setOpenSendQuestionModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [sortParam, setSortParam] = useState("leadId");
  const [direction, setDirection] = useState("desc");
  const [selectedButton, setSelectedButton] = useState("descending");
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredLeads, setFilteredLeads] = useState(leadList);

  const makeAuthorizedRequest = useAuthorizedHttp();
  const appCtx = useSelector((state) => state.app);

  const fetchQuestionList = (id) => {
    setLoader(true);

    makeAuthorizedRequest(
      {
        url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/fund/magnet/risk/profile/question/list?wmUserId=${id}`,
        method: "get",
      },
      (data) => {
        setQuestionList(data);
        setLoader(false);
      },
      (rej) => {
        setLoader(false);
      }
    );
  };

  useEffect(() => {
    fetchQuestionList(appCtx?.userDetails?.userId);
  }, [openQuestionTypeModal === false]);

  const getLeadList = () => {
    setLoader(true);

    makeAuthorizedRequest(
      {
        url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/fund/magnet/risk/profile/lead/list?wmUserId=${appCtx.userDetails.userId}&page=0&size=100&sort=${sortParam},${direction}`,
        method: "get",
      },
      (data) => {
        setLeadList(data?.content);
        setLoader(false);
      },
      (rej) => {
        setLoader(false);
      }
    );
  };
  const handleDelete = (leadId) => {
    setDeleteLoader(true);

    makeAuthorizedRequest(
      {
        url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/fund/magnet/risk/profile/lead/${leadId}/delete`,
        method: "delete",
      },
      (data) => {
        setDeleteLoader(false);
        setDeleteSuccess(true);
        toast.success("Lead deleted successfully");
      },
      (rej) => {
        setDeleteLoader(false);
        toast.success("failed to  delete lead ");
      }
    );
  };

  useEffect(() => {
    fetchQuestionList(appCtx?.userDetails?.userId);
  }, [openQuestionTypeModal === false]);
  useEffect(() => {
    getLeadList();
  }, [selectedButton, deleteSuccess]);
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    filterLeads(value);
  };

  const filterLeads = (searchValue) => {
    const filtered = leadList.filter((lead) =>
      lead.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredLeads(filtered);
  };

  let ViewLeadList = null;
  if (searchTerm !== "") {
    ViewLeadList = filteredLeads;
  } else {
    ViewLeadList = leadList;
  }

  const LeadListPdf = ({ item }) => (
    <Document>
      <Page
        size="A4"
        style={{
          padding: "20px",
          backgroundColor: "white",
        }}
      >
        <View style={{ textAlign: "center", margin: "10px" }}></View>
        <View
          style={{
            padding: "20px",
            backgroundColor: "#EEEEEE",
            border: "1px solid #e5e5e5",
            marginTop: "5px",
          }}
        >
          <Text
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginBottom: "2px",
              fontFamily: "Roboto",
            }}
          >
            {item?.name}
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{}}>
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "normal",
                  marginBottom: "2px",
                  fontFamily: "Roboto",
                }}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    marginBottom: "2px",
                    fontFamily: "Roboto",
                  }}
                >
                  Email:
                </Text>{" "}
                {item?.email}
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "normal",
                  marginBottom: "2px",
                  fontFamily: "Roboto",
                }}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "Roboto",
                  }}
                >
                  Mobile:
                </Text>{" "}
                {item?.mobile}
              </Text>
            </View>

            <View style={{}}>
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "normal",
                  marginBottom: "2px",
                  fontFamily: "Roboto",
                }}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "Roboto",
                  }}
                >
                  Risk Profile:
                </Text>{" "}
                <Text
                  style={{
                    fontSize: "12px",
                    fontWeight: "normal",
                    fontFamily: "Roboto",
                  }}
                >
                  {item?.riskProfile}
                </Text>
              </Text>

              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "normal",
                  marginBottom: "2px",
                  fontFamily: "Roboto",
                }}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    fontFamily: "Roboto",
                  }}
                >
                  Risk Score:
                </Text>{" "}
                <Text
                  style={{
                    fontSize: "12px",
                    fontWeight: "normal",
                    fontFamily: "Roboto",
                  }}
                >
                  {item?.riskScore}
                </Text>
              </Text>
            </View>
          </View>
          <Text
            style={{
              fontSize: "12px",
              fontWeight: "normal",
              marginTop: "2px",
              fontFamily: "Roboto",
            }}
          >
            <Text style={{ fontWeight: "bold", fontFamily: "Roboto" }}>
              Risk Profile Description:{" "}
            </Text>{" "}
            {item?.riskProfileDescription}
          </Text>
          {item?.profileGenDate && (
            <Text
              style={{
                fontSize: "12px",
                fontWeight: "normal",
                marginTop: "2px",
                fontFamily: "Roboto",
              }}
            >
              <Text style={{ fontWeight: "bold", fontFamily: "Roboto" }}>
                Risk profile generated on:
              </Text>{" "}
              {moment(item?.profileGenDate).format("D MMM YYYY")}
            </Text>
          )}
        </View>
      </Page>
    </Document>
  );

  return (
    <div>
      <p className="text-3xl font-medium text-center">Risk Profiler</p>
      <p className="text-lg  text-center mt-2">
        Assess the risk appetite of your client
      </p>
      <div className="bg-white w-[80%] mx-auto my-10 min-h-[200px]">
        {loader ? (
          <div className="p-10">
            {Array.from({ length: 5 }, (_, index) => (
              <div key={index} className="flex flex-col ">
                <Skeleton
                  key={index}
                  variant="rectangular"
                  height={120}
                  className=" mt-[5px] w-[100%] mx-auto"
                />
              </div>
            ))}
          </div>
        ) : (
          <>
            {questionList.length === 0 ? (
              <div className="flex flex-col justify-center items-center h-[200px]">
                <p
                  onClick={() => setOpenQuestionTypeModal(true)}
                  className="cursor-pointer text-blue-600 text-center text-lg"
                >
                  Create questionnaire for Risk Profiler
                </p>
              </div>
            ) : (
              <div>
                <div className="flex my-10">
                  <button
                    className={`flex-1 text-center py-2 text-xl font-semibold ${
                      selectedTab === 1
                        ? " text-black rounded-t-md border border-gray-300 border-b-0"
                        : "border-gray-300 border-b"
                    }`}
                    onClick={() => setSelectedTab(1)}
                  >
                    Profiler
                  </button>
                  <button
                    className={`flex-1 text-center py-2 text-xl font-semibold ${
                      selectedTab === 2
                        ? " text-black rounded-t-md border border-gray-300 border-b-0"
                        : "border-gray-300 border-b"
                    }`}
                    onClick={() => {
                      setSelectedTab(2);
                    }}
                  >
                    Customers
                  </button>
                </div>
              </div>
            )}
          </>
        )}
        {selectedTab === 1 && (
          <div className="container mx-auto px-8 min-w-[400px]">
            {loader ? (
              <div className="flex flex-row justify-center items-center">
                {/* <CircularProgress size={20} className="ml-[5px]" /> */}
              </div>
            ) : (
              <>
                {questionList.length !== 0 && (
                  <div className=" pb-10">
                    <p
                      className="text-md text-blue-600 cursor-pointer text-right "
                      onClick={() => {
                        setOpenSendQuestionModal(true);
                      }}
                    >
                      Send it to a client
                    </p>

                    {questionList.length !== 0 &&
                      questionList.map((item, index) => {
                        let parsedOptions = JSON.parse(item?.options);
                        const isEvenIndex = index % 2 === 0;
                        const backgroundColor = isEvenIndex
                          ? "#f5f5f5"
                          : "#ffffff";
                        return (
                          <div
                            key={index}
                            className={`p-[20px] bg-[${backgroundColor}] border border-[#e5e5e5] mt-[5px]`}
                          >
                            <p className="text-md font-medium mt-3">
                              Q{index + 1}. {item.question}
                            </p>
                            <div className="flex flex-col w-[100%] mt-1">
                              <div className="flex flex-row flex-wrap gap-x-3">
                                {parsedOptions
                                  ?.slice(0, 2)
                                  .map((option, optionIndex) => (
                                    <div
                                      key={optionIndex}
                                      className="w-[300px]"
                                    >
                                      <p className="text-md ">
                                        {String.fromCharCode(97 + optionIndex)}.{" "}
                                        {option.option}
                                      </p>
                                    </div>
                                  ))}
                              </div>
                              <div className="flex flex-row flex-wrap gap-x-3">
                                {parsedOptions
                                  ?.slice(2, 4)
                                  .map((option, optionIndex) => (
                                    <div
                                      key={optionIndex}
                                      className="w-[300px]"
                                    >
                                      <p className="text-md">
                                        {String.fromCharCode(
                                          97 + optionIndex + 2
                                        )}
                                        . {option.option}
                                      </p>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {selectedTab === 2 && (
          <div className="container mx-auto px-8 min-w-[400px] pb-10">
            {loader ? (
              <div className="flex flex-row justify-center items-center">
                {/* <CircularProgress size={20} className="ml-[5px]" /> */}
              </div>
            ) : (
              <>
                <div className="flex flex-row justify-between items-center mb-5">
                  {/* search button  */}
                  <div className="relative  w-[30%]">
                    <div className="max-w-md mx-auto">
                      <div className="relative flex items-center w-full h-12 rounded-lg focus-within:shadow-lg bg-gray-200 overflow-hidden">
                        <div className="grid place-items-center h-full w-12 text-black">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            />
                          </svg>
                        </div>

                        <input
                          className=" bg-gray-200 h-full w-full outline-none text-sm text-black pr-2"
                          type="text"
                          value={searchTerm}
                          onChange={handleSearchChange}
                          placeholder="Search Lead.."
                        />
                      </div>
                    </div>
                  </div>

                  {/* filter button  */}
                  <div className="flex justify-end space-x-4 ">
                    <button
                      className={`px-2 py-1 font-medium   rounded-lg text-sm ${
                        selectedButton === "ascending"
                          ? "bg-blue-500 text-white"
                          : "bg-blue-100"
                      }`}
                      onClick={() => {
                        setSelectedButton("ascending");
                        setSortParam("leadId");
                        setDirection("asc");
                      }}
                    >
                      Ascending
                    </button>
                    <button
                      className={`px-2 py-1  font-medium   rounded-lg text-sm ${
                        selectedButton === "descending"
                          ? "bg-blue-500 text-white"
                          : "bg-blue-100"
                      }`}
                      onClick={() => {
                        setSelectedButton("descending");
                        setSortParam("leadId");
                        setDirection("desc");
                      }}
                    >
                      Descending
                    </button>
                    <button
                      className={`px-2 py-1  font-medium   rounded-lg text-sm ${
                        selectedButton === "name"
                          ? "bg-blue-500 text-white"
                          : "bg-blue-100"
                      }`}
                      onClick={() => {
                        setSelectedButton("name");
                        setSortParam("name");
                        setDirection("asc");
                      }}
                    >
                      Name
                    </button>
                  </div>
                </div>

                {leadList.length !== 0 &&
                  ViewLeadList?.map((item, index) => {
                    const isEvenIndex = index % 2 == 0;
                    const backgroundColor = isEvenIndex ? "#f5f5f5" : "#ffffff";
                    return (
                      <div
                        key={index}
                        className={`p-[20px] bg-[${backgroundColor}] border border-[#e5e5e5] mt-[5px]`}
                      >
                        <div className="flex flex-row justify-between items-center pb-2">
                          <div className="flex gap-x-5">
                            <p className="text-xl font-medium">{item?.name}</p>
                            <button
                              onClick={() => {
                                handleDelete(item?.leadId);
                              }}
                              class="px-2 py-1 relative rounded group overflow-hidden text-sm bg-purple-50 text-purple-600 inline-block"
                            >
                              <p class="relative">Delete</p>
                            </button>
                          </div>
                          <PDFDownloadLink
                            document={<LeadListPdf item={item} />}
                            fileName={`${item.name
                              .replace(/\s+/g, "_")
                              .toLowerCase()}.pdf`}
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              className="text-black mr-10"
                            />
                          </PDFDownloadLink>
                        </div>
                        <div className="flex flex-row justify-between">
                          <div className="">
                            {item?.email && (
                              <p>
                                <span className="font-medium">Email:</span>{" "}
                                {item?.email}
                              </p>
                            )}

                            {item?.mobile && (
                              <p>
                                <span className="font-medium">Mobile:</span>{" "}
                                {item?.mobile}
                              </p>
                            )}
                          </div>

                          <div className="">
                            {item?.riskProfile && (
                              <p>
                                <span className="font-medium">
                                  Risk Profile:
                                </span>{" "}
                                {item?.riskProfile}
                              </p>
                            )}

                            {item?.riskScore && (
                              <p>
                                <span className="font-medium">Risk Score:</span>{" "}
                                {item?.riskScore}
                              </p>
                            )}
                          </div>
                        </div>
                        {item?.riskProfileDescription && (
                          <p className="mt-2">
                            <span className="font-medium">
                              Risk Profile Description:
                            </span>{" "}
                            {item?.riskProfileDescription}
                          </p>
                        )}

                        {item?.profileGenDate && (
                          <p className="mt-2">
                            <span className="font-medium">
                              Risk profile generated on:{" "}
                            </span>{" "}
                            {moment(item?.profileGenDate).format("D MMM YYYY")}
                          </p>
                        )}
                      </div>
                    );
                  })}
              </>
            )}
          </div>
        )}
      </div>

      <QuestionTypeModal
        openModal={openQuestionTypeModal}
        setOpenModal={setOpenQuestionTypeModal}
      />
      <SendQuestionModal
        openModal={openSendQuestionModal}
        setOpenModal={setOpenSendQuestionModal}
      />
    </div>
  );
};

export default RiskProfiler;
