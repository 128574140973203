import React, { useEffect, useState } from "react";
import Footer from "../components/Footer/Footer";
import { AccountAddressBar } from "../components/ui/AccountAddressBar";
import { useNavigate, useParams } from "react-router-dom";
import { Popover } from "react-tiny-popover";
import ChainDropdownModal from "../components/ui/ChaindrodownModal/ChainDropdownModal";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { nativeToken } from "../config";
import { Button, Stack, Tooltip } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Skeleton } from "@mui/material";
import TokenLIstCard from "../components/ui/TokenLIstCard/TokenLIstCard";
import { useWalletView } from "../hooks/useWalletView";
import { usehandleBuyTaleCoin } from "../hooks/handleBuyTaleCoin";
import { appActions } from "../context/app-slice";
import SendAlgosModal from "../components/ui/BuyAndSellAlgos/sendAlgos/SendAgosModal";
import SwapModal from "../components/ui/swap/SwapModal";
import AddFundModal from "../components/ui/InvestorsAccounts/AddFundModal";
import AssetPulse from "../components/ui/News/AssetPulse";
import OtherAssestsView from "./OtherAssestsView";
import CustomButton from "../components/CustomButton";
import { Add } from "@mui/icons-material";
import AssestsView from "./AssestsView";
import CryptoBasket from "./cryptobasket/CryptoBasket";
import useAuthorizedHttp from "../hooks/use-authorized-http";
import { toast } from "react-toastify";
import TaleWalletOnRampModal from "../components/TaleWalletOnRampModal/TaleWalletOnRampModal";
import TaleWalletOffRampModal from "../components/TaleWalletOffRamp/TaleWalletOffRampModal";

const Wallet = ({ clientId, clientDetails }) => {
  const [openchainModalVisible, setOpenchainModalVisible] = useState(false);
  const [blockchainobject, setBlockchainobject] = useState(false);
  const [userWallet, setUserWallet] = useState([]);
  const [openSendAlgosModal, setOpenSendAlgosModal] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [openTalewalletOnrampModal, setOpenTalewalletOnrampModal] =
    useState(false);

  const [algoValue, setAlgoValue] = useState(0);
  const [algoValueLoading, setAlgoValueLoading] = useState(false);

  // const [blockchain, setBlockchain] = useState("");
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedWalletTab, setSelectedWalletTab] = useState(0);
  const [sync, setSync] = useState(false);
  const [openOfframpModal, setOpenOfframpModal] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const makeRequest = useAuthorizedHttp();

  const appCtx = useSelector((state) => state.app);

  const {
    token,
    accountAsset,
    setAccountAsset,
    amount,
    setAmount,
    assetUrl,
    setAssetUrl,
    handleOptIn,
    showAssets,
    optedIn,
    taleAmount,
    showTaleData,
    optInSuccessfull,
    setOptInSuccessfull,
    handleCustodialOptIn,
    minBalance,
    getAssetsById,
    assetLoader,
    allCoin,
    allCoinPrice,
    usersToken,
    isAssetLoading,
    setIsAssetLOading,
    isCoinLoading,
    icon,
    otheraccountAsset,
    otherassetLoader,
    openSwapModal,
    setopenSwapModal,
    openSellModal,
    setopenSellModal,
    getAllCoin,
    getAllCoinPrice,
    getUsersToken,
    setAllcoinPrice,
    isCoinPriceLoading,
  } = useWalletView();

  const { handleBuyAlgos } = usehandleBuyTaleCoin(appCtx);
  const dispatch = useDispatch();

  const info = (index, id) => {
    navigate(`/asset/${accountAsset[index].assetId}`);
    // let ipfsHash = url?.split('/')
    dispatch(appActions.setMetaData(accountAsset[index].assetId));
  };

  const handleTabClick = (tabIndex) => {
    setSelectedTab(tabIndex);
  };

  useEffect(() => {
    dispatch(appActions.setUsersBlockchain("BSC"));
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFooter(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (id) {
      let data = [id];

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/address/list`,
        headers: {
          "X-Auth-Token": appCtx.authToken,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          let data = response.data;
          setUserWallet(response.data);
          const blockchainObjects = data.reduce((obj, item) => {
            obj[item.blockchain] = true;
            return obj;
          }, {});

          setBlockchainobject(blockchainObjects);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [appCtx.usersblockchain]);

  useEffect(() => {
    const filteredOptions = Object.keys(blockchainobject).filter(
      (key) => key !== appCtx.usersblockchain
    );

    setFilteredOptions(filteredOptions);
  }, [appCtx.usersblockchain, blockchainobject]);

  var address = userWallet?.find(
    (addr) => addr.blockchain === appCtx.usersblockchain
  )?.address;

  useEffect(() => {
    getAllCoin(appCtx.usersblockchain);
    getAllCoinPrice(appCtx.usersblockchain);
    getUsersToken(id);
  }, [appCtx.usersblockchain, address, sync]);
  console.log("filteredOptions", filteredOptions);

  useEffect(() => {
    showAssets(address, id);
  }, [appCtx.usersblockchain, address]);

  let currentToken = appCtx.usersblockchain;
  let value = 0;
  let price = 0;
  if (allCoinPrice && nativeToken[currentToken] && amount && !isNaN(amount)) {
    price = allCoinPrice[nativeToken[currentToken]]?.price;
    if (price && !isNaN(price)) {
      value = price * amount;
    }
  }

  // useEffect(() => {
  //   console.log("amount", amount);
  //   if (amount > 0 && value <= 0) {
  //     setOtherValueLoading(true);
  //   } else {
  //     setOtherValueLoading(false);
  //   }
  // }, [appCtx.usersblockchain, amount]);

  useEffect(() => {
    if (appCtx.usersblockchain === "ALGORAND") {
      if (amount > 0) {
        setAlgoValueLoading(true);
        let config = {
          method: "get",
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/convert/algorand/${amount}/to/usd`,
          headers: {
            "X-Auth-Token": appCtx.authToken,
            "Content-Type": "application/json",
          },
        };

        axios.request(config).then((response) => {
          setAlgoValue(response.data.usd);
          setAlgoValueLoading(false);
        });
      }
    }
  }, [appCtx.usersblockchain, amount]);

  const clientData = userWallet?.find(
    (addr) => addr?.blockchain === appCtx?.usersblockchain
  );

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  // calling additional price api
  const [pausePriceApiCall, setPausePriceApiCall] = useState(false);

  useEffect(() => {
    if (
      allCoin.length !== 0 &&
      allCoin.length !== Object.keys(allCoinPrice).length &&
      !isCoinLoading &&
      !isCoinPriceLoading &&
      !pausePriceApiCall
    ) {
      const tokenSymbolsWithNoPrice = allCoin
        ?.filter((coin) => !allCoinPrice[coin?.symbol?.toUpperCase()]?.price)
        .map((coin) => coin.symbol);

      makeRequest(
        {
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/token/price`,
          data: tokenSymbolsWithNoPrice,
          method: "post",
          headers: {
            "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
            "Content-Type": "application/json",
          },
        },
        (data) => {
          if (Object.keys(data).length === 0) {
            setPausePriceApiCall(true);
          } else {
            setAllcoinPrice((prev) => ({ ...prev, ...data }));
          }
        },
        (error) => {}
      );
    }
  }, [allCoin, allCoinPrice, appCtx.usersblockchain]);

  console.log("appCtx", appCtx);

  return (
    <div className="text-black">
      {/* popover  */}

      <div className=" w-[100%] flex justify-center mb-10 ">
        <Popover
          isOpen={() => setOpenchainModalVisible(true)}
          positions={["right"]} // if you'd like, you can limit the positions
          padding={0} // adjust padding here!
          reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
          onClickOutside={() => {
            setOpenchainModalVisible(false);
          }} // handle click events outside of the popover/target here!
          content={(
            { position, nudgedLeft, nudgedTop } // you can also provide a render function that injects some useful stuff!
          ) => (
            <>
              <ChainDropdownModal
                userId={id}
                currencyvisible={openchainModalVisible}
                setCurrencyVisible={setOpenchainModalVisible}
                blockchainobject={blockchainobject}
                filteredOptions={filteredOptions}
              />
            </>
          )}
        >
          <div
            className="flex-column"
            onClick={(e) => {
              setOpenchainModalVisible(true);
            }}
          >
            <Tooltip title={`${""}`}>
              <Button
                style={{
                  background: "white",
                  border: "1px solid #B009FF",
                  borderRadius: "34px",
                  color: "black",
                }}
                className="w-[200px] flex flex-row justify-between items-center "
              >
                <img
                  src="/images/currencyactive.svg"
                  alt=""
                  srcset=""
                  className="mr-2"
                />

                <h5 className="text-md	font-medium		">
                  {appCtx?.usersblockchain}
                </h5>

                <ArrowDropDownIcon />
              </Button>
            </Tooltip>
          </div>
        </Popover>
      </div>

      {/* top div  */}

      <div className="flex flex-col justify-center   w-[100%]">
        <div className="flex justify-center  ">
          <AccountAddressBar address={address} />
        </div>

        {isAssetLoading || algoValueLoading ? (
          <Stack
            className="flex justify-center items-center  w-[80%] md:w-[30%] min-h-[70px]  mx-auto mt-5 mb-10"
            style={{ color: "white" }}
          >
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem", bgcolor: "white" }}
              className="w-[80%] mx-auto"
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem", bgcolor: "white" }}
              className="w-[80%] mx-auto"
            />
          </Stack>
        ) : (
          <div className="flex justify-center items-center gap-5 w-[80%] md:w-[30%] min-h-[70px]  mx-auto mt-5 mb-10 ">
            <div className="min-w-[60px]">
              {appCtx.usersblockchain === "ALGORAND" ? (
                <img
                  src="/images/algo.svg"
                  alt="token logo"
                  className="w-[45px] "
                />
              ) : (
                !isCoinLoading && (
                  <img src={icon} alt="token logo" className="w-[60px] " />
                )
              )}
            </div>
            <div>
              <h4 className="flex justify-start">
                Balance: {amount?.toFixed(4) || "0.0000"}{" "}
                {nativeToken[appCtx.usersblockchain]}
              </h4>
              <h5 className="flex justify-start">
                Value:{" "}
                {appCtx.usersblockchain === "ALGORAND"
                  ? `$${algoValue?.toFixed(4)}`
                  : `$${value?.toFixed(4)}`}
              </h5>
            </div>
          </div>
        )}
      </div>

      <div className=" w-[100%]">
        <div className="flex gap-5 justify-center my-5 md:my-10 pt-10">
          <button
            onClick={() => {
              setOpenTalewalletOnrampModal(true);
              // handleBuyAlgos(address);
            }}
          >
            <img
              src="/images/buyicon.svg"
              alt="Button Image"
              className="w-15"
            />
            <h5>Buy</h5>
          </button>
          <button
            onClick={() => {
              // showTaleData();
              allCoin &&
                allCoinPrice &&
                usersToken &&
                setOpenSendAlgosModal(true);
            }}
          >
            <img
              src="/images/sendicon.svg"
              alt="Button Image"
              className="w-15 "
            />
            <h5>Send</h5>
          </button>
          <button
            onClick={() => {
              setOpenOfframpModal(true);
            }}
          >
            <img
              src="/images/sellicon.svg"
              alt="Button Image"
              className="w-15"
            />
            <h5>Sell</h5>
          </button>

          <button
            onClick={() => {
              setopenSwapModal(true);
            }}
          >
            <img src="/images/swap.svg" alt="Button Image" className="w-15" />
            <h5>Swap</h5>
          </button>
        </div>

        <div className="w-[80%] flex mx-auto">
          <button
            className={`flex-1 w-full border-b-4 p-0 transition-all ease-out duration-500 ${
              selectedWalletTab === 0 && "border-b-[#BBFF00]"
            }`}
            onClick={() => {
              setSelectedWalletTab(0);
            }}
          >
            Tokens
          </button>
          <button
            className={`flex-1 w-full border-b-4 p-5 transition-all ease-out duration-500 ${
              selectedWalletTab === 1 && "border-b-[#BBFF00]"
            }`}
            onClick={() => {
              setSelectedWalletTab(1);
              getAssetsById();
            }}
          >
            NFTs
          </button>

          <button
            className={` flex-1 w-full border-b-4 p-5 transition-all ease-out duration-500 ${
              selectedWalletTab === 2 && "border-b-[#BBFF00]"
            }`}
            onClick={() => {
              setSelectedWalletTab(2);
            }}
          >
            Crypto Basket
          </button>
        </div>
        <div className="flex-1">
          {selectedWalletTab === 0 && (
            <div className="pb-20">
              <TokenLIstCard
                allCoin={allCoin}
                usersToken={usersToken}
                allCoinPrice={allCoinPrice}
                assetUrl={assetUrl}
                appCtx={appCtx}
                optedIn={optedIn}
                taleAmount={taleAmount}
                optInSuccessfull={optInSuccessfull}
                setOptInSuccessfull={setOptInSuccessfull}
                isCoinLoading={isCoinLoading}
                sync={sync}
                setSync={setSync}
              />
            </div>
          )}

          {selectedWalletTab === 1 ? (
            <>
              {appCtx.blockchain === "ALGORAND" && (
                <>
                  {" "}
                  {assetLoader ? (
                    <div className="m-4 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 w-[100%] gap-2">
                      <div className="p-[10px] mt-[5px]">
                        <AssetPulse />
                      </div>
                      <div className="p-[10px] mt-[5px]">
                        <AssetPulse />
                      </div>
                      <div className="p-[10px] mt-[5px]">
                        <AssetPulse />
                      </div>
                      <div className="p-[10px] mt-[5px]">
                        <AssetPulse />
                      </div>
                      <div className="p-[10px] mt-[5px]">
                        <AssetPulse />
                      </div>
                      <div className="p-[10px] mt-[5px]">
                        <AssetPulse />
                      </div>
                      <div className="p-[10px] mt-[5px]">
                        <AssetPulse />
                      </div>
                    </div>
                  ) : accountAsset === undefined ? (
                    <div
                      className={`mx-auto w-[100%] min-h-[100px] flex flex-col justify-center items-center`}
                    >
                      No Assest Found !!
                    </div>
                  ) : accountAsset?.length === 0 ? (
                    <div
                      className={`mx-auto w-[100%] min-h-[100px] flex flex-col justify-center items-center`}
                    >
                      No Assest Found !!
                    </div>
                  ) : (
                    <div
                      className={"m-4 grid-rows-1 w-[100%] gap-2"}
                      id="wallet_asset_div"
                    >
                      {/* <div className="text-[50px] w-[100%] text-left font-bold">Your Assests</div> */}
                      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4">
                        {accountAsset?.map((item, index) => {
                          return (
                            <>
                              <div
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  marginTop: "5px",
                                  padding: "10px",
                                  textAlign: "left",
                                  display: "flex",
                                }}
                                key={index}
                              >
                                <div
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                    cursor: "pointer",
                                  }}
                                  className="w-[100%]"
                                  // href="https://testnet.talewallet.com/asset/' + item + '" target="_blank"
                                  onClick={() => {
                                    info(index, accountAsset[index]?.url);
                                  }}
                                >
                                  <AssestsView
                                    nftId={item?.assetId}
                                    type={"text"}
                                    ipfsHash=""
                                    title={item.name}
                                    url={accountAsset[index]?.value}
                                    token={accountAsset[index]?.token}
                                  />
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </>
              )}

              {/* {JSON.stringify(accountAsset)} */}

              {appCtx.blockchain !== "ALGORAND" && (
                <>
                  {" "}
                  {otherassetLoader ? (
                    <div className="m-4 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 w-[100%] gap-2">
                      <div className="p-[10px] mt-[5px]">
                        <AssetPulse />
                      </div>
                      <div className="p-[10px] mt-[5px]">
                        <AssetPulse />
                      </div>
                      <div className="p-[10px] mt-[5px]">
                        <AssetPulse />
                      </div>
                      <div className="p-[10px] mt-[5px]">
                        <AssetPulse />
                      </div>
                      <div className="p-[10px] mt-[5px]">
                        <AssetPulse />
                      </div>
                      <div className="p-[10px] mt-[5px]">
                        <AssetPulse />
                      </div>
                      <div className="p-[10px] mt-[5px]">
                        <AssetPulse />
                      </div>
                    </div>
                  ) : otheraccountAsset === undefined ? (
                    <div
                      className={`mx-auto w-[100%] min-h-[100px] flex flex-col justify-center items-center`}
                    >
                      No Assest Found !!
                    </div>
                  ) : otheraccountAsset?.length === 0 ? (
                    <div
                      className={`mx-auto w-[100%] min-h-[100px] flex flex-col justify-center items-center`}
                    >
                      No Assest Found !!
                    </div>
                  ) : (
                    <div>
                      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4">
                        {otheraccountAsset &&
                          otheraccountAsset?.map((item) => {
                            let metadata = JSON.parse(item.metadata);
                            return (
                              <>
                                <div
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    marginTop: "5px",
                                    padding: "10px",
                                    textAlign: "left",
                                    display: "flex",
                                  }}
                                  key={item.id}
                                >
                                  <div
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                      cursor: "pointer",
                                    }}
                                    className="w-[100%]"
                                  >
                                    {/* <Card>
                                    <img src={item.metadata.media_url} alt="" srcSet="" />
                                    <h1>this is a new card</h1>
                                  </Card> */}

                                    <OtherAssestsView item={item} />
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            ""
          )}

          {selectedWalletTab === 2 && <CryptoBasket />}
        </div>
      </div>
      {showFooter && (
        <div>
          <Footer />
        </div>
      )}
      {openSendAlgosModal && (
        <SendAlgosModal
          openModal={openSendAlgosModal}
          setOpenModal={setOpenSendAlgosModal}
          allCoin={allCoin}
          allCoinPrice={allCoinPrice}
          usersToken={usersToken}
          useraddress={address}
        />
      )}

      {openSwapModal && (
        <SwapModal
          openModal={openSwapModal}
          setOpenModal={setopenSwapModal}
          allCoinPrice={allCoinPrice}
          usersToken={usersToken}
        />
      )}

      {/* <AddFundModal
        openModal={openTalewalletOnrampModal}
        setOpenModal={setOpenTalewalletOnrampModal}
        clientData={clientData}
        clientId={clientId}
        clientDetails={clientDetails}
        useraddress={address}
      /> */}

      {openTalewalletOnrampModal && (
        <TaleWalletOnRampModal
          openModal={openTalewalletOnrampModal}
          setOpenModal={setOpenTalewalletOnrampModal}
          // closeBuyModal={closeModal}
          // allCoin={allCoin}
          // allCoinPrice={allCoinPrice}
          // amount={amount}
          // basketId={basketId}
          // tokenPercentArray={tokenPercentArray}
          // tokenArray={tokenArray}
          // basketBlockchain={basketBlockchain}
          useraddress={address}
          clientDetails={clientDetails}
        />
      )}

      {openOfframpModal && (
        <TaleWalletOffRampModal
          openModal={openOfframpModal}
          setOpenModal={setOpenOfframpModal}
          // closeBuyModal={closeModal}
          allCoin={allCoin}
          allCoinPrice={allCoinPrice}
          // amount={amount}
          // basketId={basketId}
          // tokenPercentArray={tokenPercentArray}
          // tokenArray={tokenArray}
          // basketBlockchain={basketBlockchain}
          // symbol={symbol}
          // isTokenDetailsPage={isTokenDetailsPage}
          userId={id}
        />
      )}
    </div>
  );
};

export default Wallet;
