import React, { useEffect, useState } from "react";
import "./Movies.css";
import { useSelector } from "react-redux";
import axios from "axios";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, useNavigate } from "react-router-dom";

const Movies = () => {
  const [moviesData, setMoviesData] = useState(null);
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  useEffect(() => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/fundMagnet/movies/list`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        setMoviesData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      {!moviesData && (
        <div className="flex flex-col md:flex-row justify-between items-center flex-wrap mt-5">
          {Array(2)
            .fill()
            .map((_, index) => (
              <div className="w-[40%]" key={index}>
                <SkeletonTheme baseColor="#f2f2f2" highlightColor="#444">
                  <p>
                    <Skeleton count={2} />
                  </p>
                </SkeletonTheme>
              </div>
            ))}
        </div>
      )}
      {moviesData &&
        moviesData.map((movie) => (
          <div key={movie.Id} className="rounded-lg">
            <div className="block rounded-lg bg-white text-black shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] w-[90%] md:w-[45%]">
              <a href="#!">
                <div className="image-container">
                  <img className="rounded-lg" src={movie?.Posters} alt="" />
                </div>
              </a>
              <div className="p-6">
                <div
                  onClick={() => {
                    navigate(`/movie/${movie?.Id}`);
                  }}
                  className="mb-2 text-2xl font-semibold leading-tight no-underline text-black hover:text-[#6B07D6] cursor-pointer"
                >
                  {movie?.Title}
                </div>
                <p className="my-4 text-base text-[#74787c] font-medium">
                  {movie?.Year}
                </p>
                <p className="mb-4 text-base text-[#74787c] font-normal">
                  {movie?.shorttext?.substring(0, 170) + "..."}
                  <span>
                    <div
                      onClick={() => {
                        navigate(`/movie/${movie?.Id}`);
                      }}
                      className="no-underline text-black font-semibold inline-flex cursor-pointer"
                    >
                      More
                    </div>
                  </span>
                </p>

                <div className="bg-[#F4FDFF] flex flex-row justify-between items-center gap-3 flex-wrap p-3">
                  <div className="w-[30%]">
                    <p className="text-lg font-bold ">{movie?.TimeLine} Days</p>
                    <p className="text-[#74787c] text-sm">Timeline</p>
                  </div>
                  <div className="w-[30%] text-center ">
                    <p className="text-lg font-bold ">
                      {movie?.TimeToMaturity} Months
                    </p>
                    <p className="text-[#74787c] text-sm">Time to Maturity</p>
                  </div>
                  <div className="w-[30%] flex flex-col text-right ">
                    <p className="text-lg font-bold ">{movie?.Censor}</p>
                    <p className="text-[#74787c] text-sm">Cencor</p>
                  </div>
                  <div className="w-[30%]">
                    <p className="text-lg font-bold ">
                      {movie?.AssetHoldingPercentage} %
                    </p>
                    <p className="text-[#74787c] text-sm">Asset Holding</p>
                  </div>
                  <div className="w-[30%] text-center ">
                    <p className="text-lg font-bold ">
                      ₹ {movie?.MinInvestment}
                    </p>
                    <p className="text-[#74787c] text-sm">Minimum Investment</p>
                  </div>
                  <div className="w-[30%] flex flex-col text-right ">
                    <p className="text-lg font-bold ">
                      ₹ {movie?.InvestmentFromStudio}
                    </p>
                    <p className="text-[#74787c] text-sm">Studio Investment</p>
                  </div>
                </div>

                {/* time line   */}

                <div className="py-5">
                  <div className="w-full h-2 relative">
                    <div className="w-full h-2 left-0 top-0 absolute bg-gray-200 rounded-2xl shadow-inner" />
                    <div className="w-[20%] h-2 left-0 top-0 absolute bg-[#198754] rounded-2xl" />
                  </div>
                  <div className="w-full flex justify-between mt-1">
                    <div className=" text-md font-medium font-['Eudoxus Sans']">
                      <span className="text-[#198754]">70.99k</span>
                      <span className="text-[#212529]">/₹75.00L</span>
                    </div>
                    <div className=" text-md  font-['Eudoxus Sans']">
                      <span className="text-[#74787c] font-semibold">
                        0.974%
                      </span>
                      <span className="text-[#74787c] font-medium">
                        {" "}
                        Completed
                      </span>
                    </div>
                  </div>
                </div>

                {/* tags  */}
                <button className="px-5 py-1.5  bg-blue-50  text-black rounded-full text-xs font-bold">
                  {movie?.tags}
                </button>

                <div className="flex justify-center mt-2">
                  <button
                    onClick={() => {
                      navigate(`/movie/${movie?.Id}`);
                    }}
                    className="inline-flex items-center px-10 py-2 mb-3 mr-1 text-base font-semibold text-white no-underline align-middle bg-[#1A1A1A] border border-transparent border-solid rounded-md cursor-pointer w-auto"
                  >
                    View Details
                    <svg
                      className="w-4 h-4 ml-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default Movies;
