import { NavLink, useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import useAuthorizedHttp from "../../../hooks/use-authorized-http";
import { appActions } from "../../../context/app-slice";
import "./ChainDropdown.css";
import Networkmodal from "../Networkmodal/Networkmodal";
import { useEffect, useState } from "react";
import CustomButton from "../../CustomButton";
import { nativeToken } from "../../../config";

const TokenChainDropdownModal = ({
  currencyvisible,
  setCurrencyVisible,
  blockchainobject,
  filteredOptions,
  setManagersBlockchain,
}) => {
  const [openAccountModal, setopenAccountModal] = useState(false);
  const [blockChainmenue, setBlockChainmenue] = useState("");
  const [networkdata, setNetworkdata] = useState([]);
  const dispatch = useDispatch();
  const makeRequest = useAuthorizedHttp();
  const appCtx = useSelector((state) => state.app);

  const { id } = useParams();

  useEffect(() => {
    const filteredOptions = Object.keys(nativeToken).filter(
      (key) => !blockchainobject[key]
    );

    setNetworkdata(filteredOptions);
  }, [appCtx.usersblockchain, blockchainobject]);

  return (
    <>
      <div
        className={`${
          !currencyvisible && "hidden"
        }  currencydropdownBox right-[1px]  z-50 flex flex-col gap-3 fixed top-[23px] bg-white text-black
                `}
        // style={{
        //   background: " #4E327B",
        //   border: "0.96001px solid #5B339A",
        //   borderRadius: "15px",
        //   color: "white",
        // }}
      >
        <div className="w-[50%] mx-auto pt-5">
          {" "}
          {filteredOptions &&
            filteredOptions?.map((singleChain) => (
              <button
                key={singleChain}
                className={`my-2 `}
                onClick={() => {
                  setCurrencyVisible(false);

                  setManagersBlockchain(singleChain);
                  dispatch(appActions.setManagersBlockchain(singleChain));
                }}
              >
                <div className={"flex  gap-3 "}>
                  <img src="/images/currencyactive.svg" alt="" srcset="" />
                  <div
                    style={{ color: "black" }}
                    className=" flex font-medium "
                  >
                    <div className="">{singleChain}</div>
                  </div>
                </div>
              </button>
            ))}
          {/* <CustomButton
            primary={true}
            className={`flex  items-center  mt-2 mb-5`}
            onClick={() => {
              setCurrencyVisible(false);
              setopenAccountModal(true);
            }}
          >
            <div>Add&nbsp;Network </div>
          </CustomButton> */}
        </div>
      </div>
      <Networkmodal
        openModal={openAccountModal}
        setOpenModal={setopenAccountModal}
        filteredOptions={networkdata}
        id={id}
      />
    </>
  );
};

export default TokenChainDropdownModal;
