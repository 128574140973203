import React, { useState } from "react";

import Modal from "react-modal";

import styles from "./Portfolioinfomodal.module.css";

function PortfolioInfoModal({ openModal, setOpenModal }) {
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      top: "53%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "inherit",
      // width: width > 1200 ? "600px" : "370px",
    },
  };
  function closeModal() {
    setOpenModal(false);
  }

  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        className={`${styles.popup} bg-[#fff] `}
      >
        <div className="w-[100%] flex justify-end mr-[20px] pt-[10px] ">
          <button
            className=" font-medium  p-2xl flex items-center leading-[10px] mr-[10px] mt-[10px]"
            onClick={closeModal}
          >
            X
          </button>
        </div>
        <div className="px-10 py-5">
          <div className={" w-[70vw]  mx-auto "}>
            <h4 className="font-semibold mb-2">Amount Calculations</h4>

            <div className="mb-[20px]">
              <p className="font-semibold ">Current Amount Calculations</p>

              <p className="">
                1. The INR value of your current amount is calculated as per the
                withdrawal function.
              </p>
              <p className="">
                2. To get you the best returns the system will convert any asset
                you withdraw into USDT and exchange it with INR as per the
                OnRamp USDT to INR value.
              </p>
              <p className="">
                3. This is always slightly higher than the actual USD to INR
                value. Hence your current amount in INR is sum of all your
                crypto tokens value in USDT multiplied by the current rate of
                USDT to INR.
              </p>
            </div>

            <div className="">
              <p className="font-semibold ">Invested Amount Calculation</p>
              <p className="">Your invested amount is calculated as the</p>

              <p className="">1. sum of all the amount you Invested plus</p>
              <p className="">
                2. sum of amount you transferred from other wallet minus
              </p>
              <p className="">3. the sum of amount you have withdrawn minus</p>
              <p className="">
                4. The sum of amount you transferred from to other wallet minus
              </p>
              <p className="">5. the OnRamp charges and Platform fee.</p>
            </div>

            <div className="mb-[20px]">
              <p className="font-semibold mt-5 mb-2">
                Return Amount Calculation
              </p>

              <p>
                Your return amount calculation is just your current amount minus
                the Invested amount.
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default PortfolioInfoModal;
