import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";

import { toast } from "react-toastify";

import "./Networkmodal.css";
import axios from "axios";
import { appActions } from "../../../context/app-slice";

function Networkmodal({ openModal, setOpenModal, filteredOptions, id }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appCtx = useSelector((state) => state.app);
  const width = window.screen.width;
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      height: "70%",
      width: "80%",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "inherit",
      background: "linear-gradient(180deg, #3C0B8C 0%, #FFFFFF 119.14%)",
    },
  };
  function closeModal() {
    setOpenModal(false);
  }

  const dark = false;

  const callBlockchainSetupApi = (blockchain) => {
    let data = JSON.stringify({
      wallet: "TALEWALLET",
      userId: id,
      blockchain: blockchain,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/fundMagnet/client/blockchain/wallet/setup`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.success("New network added successfully");
        dispatch(appActions.setUsersBlockchain(blockchain));
        closeModal();
      })
      .catch((error) => {
        toast.error("failed to add network");
      });
  };

  const coinButtons = Object.entries(filteredOptions).map(([key, value]) => (
    <div
      value={value}
      className="coin-button-container  "
      style={{
        background: "linear-gradient(180deg, #E5E4F0 0%, #F0F0F0 100%)",

        border: "1px solid #DDDCE8",
        borderRadius: "4.7736px",
      }}
    >
      <button
        className="coin-button flex justify-between items-center w-[100%]"
        onClick={() => {
          switch (value) {
            case "POLYGON":
              callBlockchainSetupApi("POLYGON");
              break;
            case "BSC":
              callBlockchainSetupApi("BSC");
              break;
            case "ETHEREUM":
              callBlockchainSetupApi("ETHEREUM");
              break;
            case "SOLANA":
              callBlockchainSetupApi("SOLANA");
              break;

            case "ALGORAND":
              callBlockchainSetupApi("ALGORAND");
              break;
          }
        }}
      >
        <div className="flex justify-between pl-10  w-[100%] ">
          <div className="flex gap-2 items-center">
            <img src="/images/btcicon.png" alt="" srcSet="" />{" "}
            <div>
              <p className="font-semibold">{value}</p>
              <p className="flex justify-start">0.00</p>{" "}
            </div>
          </div>
        </div>
        <div className="cursor-pointer">
          <h6 className="text-blue-700	mr-10 font-semibold">Add</h6>
        </div>
      </button>
    </div>
  ));

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="w-[100%] flex justify-end mr-[20px] pt-[10px]">
        <button
          className=" font-medium text-2xl text-white flex items-center leading-[10px] mr-[10px] mt-[10px]"
          onClick={closeModal}
        >
          X
        </button>
      </div>
      <div
        className="flex flex-col justify-center items-center gap-5 mb-5"
        style={{
          color: "white",
        }}
      >
        <h1>Add a network</h1>
        <p>
          Add from a list of popular networks or add a network manually. Only
          interact with the entities you trust.
        </p>
        <div
          className="px-5 py-1	"
          style={{
            background: " rgba(146, 109, 205, 0.5)",
            border: "0.96001px solid #5B339A",
            borderRadius: "10px",
          }}
        >
          <SearchIcon className="search-icon" />
          <input
            type="text"
            placeholder="Search for network"
            style={{
              background: " rgba(146, 109, 205, 0.3)",
            }}
            className="outline-none	"
          />
        </div>
      </div>
      <div
        className="min-h-screen"
        style={{ background: " #FFFFFF", borderRadius: "10% 10% 0% 0%" }}
      >
        <div className="w-[80%] mx-auto pt-10">
          {" "}
          <div className=" ">
            <h3
              className="font-semibold	text-xl  pl-10 py-2		"
              style={{
                background: "linear-gradient(180deg, #E8E7F0 0%, #E2E1E9 100%)",

                border: "1px solid #BEB7D2",
                borderRadius: "4.7736px",
              }}
            >
              Add a Network
            </h3>
          </div>
          <div className=" w-[100%] bg-gray-200 ">{coinButtons}</div>
        </div>
      </div>
    </Modal>
  );
}
export default Networkmodal;
