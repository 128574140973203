import { Card, CardActions } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import "./NftItem.css";
import ThreeDRenderer from "./ThreeDRenderer";
const AssestsView = ({
  nftId,
  type,
  ipfsHash,
  title,
  url,
  showNft,
  token,
  product,
}) => {
  const [metaData, setMetaData] = useState({});
  const dispatch = useDispatch();
  let hash = url?.split("/");
  const fetchData = () => {
    if (showNft) {
      setMetaData(JSON.parse(product?.metaData));
    } else {
      fetch(`${process.env.REACT_APP_GATEWAY_IPFS}/${hash[hash?.length - 1]}`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setMetaData(data);
        })
        .catch((rej) => {
          // console.log(rej);
        });
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {/* group */}
      {/* {(token?.length == 1 && */}
      <div className="mt-[10px] w-[100%] marketplace-nft-card group">
        {/* <Link to={`/collectedNft/${nftId}`} nftId={nftId} className="cardLink"> */}
        <Card
          className=" h-[184px] sm:h-[270px] lg:h-[256px]"
          style={{ borderRadius: "14px" }}
        >
          <div
            className="w-[100%] h-[140px] sm:h-[200px]"
            style={{
              backgroundColor:
                metaData?.mime_type?.split("/")[0] === "threeD"
                  ? "rgb(0 47 73 / 42%)"
                  : "",
            }}
          >
            {metaData?.mime_type?.split("/")[0] === "text" ? (
              <div
                className={
                  "h-[140px] sm:h-[200px] flex items-center justify-center"
                }
              >
                <div className="h-[100%]">
                  <img
                    src="/images/defaultnft.png"
                    className="h-[100%]"
                    alt="logo"
                  />
                </div>
                {/* <div> No image or video found for this NFT</div> */}
              </div>
            ) : metaData?.mime_type?.split("/")[0] === "image" ||
              metaData?.ipfsHash ? (
              metaData?.image || metaData?.ipfsHash ? (
                <img
                  className="  h-[140px] sm:h-[200px] w-full"
                  src={`${process.env.REACT_APP_GATEWAY_IPFS}/${
                    metaData?.image?.split("/")[2] || metaData?.ipfsHash
                  }`}
                  alt="NFT"
                />
              ) : (
                <div
                  className={
                    "h-[140px] sm:h-[200px] p-10 flex items-center justify-center text-black font-medium"
                  }
                >
                  Image not found
                </div>
              )
            ) : metaData?.mime_type?.split("/")[0] === "doc" ||
              metaData?.ipfsHash ? (
              metaData?.image || metaData?.ipfsHash ? (
                <img
                  className="  h-[140px] sm:h-[200px] w-full"
                  src={`${process.env.REACT_APP_GATEWAY_IPFS}/${
                    metaData.image.split("//")[1]
                  }`}
                  alt="NFT"
                />
              ) : (
                // <>
                //   <img
                //     src={`${process.env.REACT_APP_GATEWAY_IPFS}/${
                //       metaData.image.split("//")[1]
                //     }`}
                //     alt=""
                //   />
                //   {/* {JSON.stringify(metaData.image.split("//")[1])} */}
                // </>
                // <div>
                //   {" "}
                //   <h1>this is an pdf file</h1>
                //   <h1>
                //     {JSON.stringify(metaData.image)}
                //     {console.log(
                //       "logging metaData from inside of card",
                //       metaData
                //     )}
                //   </h1>
                // </div>
                <div
                  className={
                    "h-[140px] sm:h-[200px] p-10 flex items-center justify-center text-black font-medium"
                  }
                >
                  Document not found
                </div>
              )
            ) : metaData?.mime_type?.split("/")[0] === "video" ? (
              metaData?.media_url ? (
                <video
                  controls
                  autoPlay
                  className="  h-[140px] sm:h-[200px] w-full"
                  src={`${process.env.REACT_APP_GATEWAY_IPFS}/${
                    metaData?.media_url?.split("/")[2] || metaData?.ipfsHash
                  }`}
                />
              ) : (
                <div
                  className={
                    "h-[140px] sm:h-[200px] p-10 flex items-center justify-center text-black font-medium"
                  }
                >
                  Video not found
                </div>
              )
            ) : metaData?.mime_type?.split("/")[0] === "threeD" ? (
              metaData?.image === metaData?.media_url ? (
                <ThreeDRenderer
                  className="h-[140px] sm:h-[200px] w-full"
                  src={`${process.env.REACT_APP_GATEWAY_IPFS}/${
                    metaData?.media_url?.split("/")[2] || metaData?.ipfsHash
                  }`}
                  type={metaData?.mime_type?.split("/")[1]}
                />
              ) : (
                <img
                  className="  h-[140px] sm:h-[200px] w-full"
                  src={`${process.env.REACT_APP_GATEWAY_IPFS}/${
                    metaData?.image?.split("/")[2] || metaData?.ipfsHash
                  }`}
                  alt="NFT"
                />
              )
            ) : (
              // <div className={"h-[140px] sm:h-[200px] p-10 flex items-center justify-center text-white font-medium"}>Click to preview 3D NFT</div>
              <div
                className={
                  "h-[140px] sm:h-[200px] p-10 flex items-center justify-center"
                }
              ></div>
            )}
          </div>

          <CardActions className="flex justify-between ml-[20px] mr-[20px] groupPrice">
            {/* <button size="small" className="flex">
                                <div>
                                    <div className="text-left">Price</div>
                                    <div className="font-bold text-[11px] ">{parsedMetaData.price ? parsedMetaData.price : "Not Listed"}</div>
                                </div>
                            </button> */}
            {/* <button size="small" className="flex"><div><div>Current Bid</div><div className='font-bold'>2 ETH</div></div></button> */}
          </CardActions>
          {/* {!domain && ( */}
          <button
            onClick={() => {
              // window.open("http://" + domain + "/" + nft.nftId);
            }}
            className="bg-green-500 text-slate-50 p-3 w-full rounded-b hidden mt-[-8px] lg:mt-[2px] sm:mt-[18px]  card_btn"
          >
            {!showNft && "View NFT"}
            {product?.sold && "View NFT"}
            {product && !product?.sold && "Buy Now"}
          </button>
          {/* )} */}
          {/* <div className='flex justify-center items-center mb-[2px]'>
                        <div className="bg-gray-100 w-[100%] h-[2px]"></div>
                    </div> */}
          <div className="flex justify-start ml-[10px] items-end h-[11%] sm:h-[12%] lg:h-[9%]">
            <div className="flex items-center groupTitle">
              <img
                src="/images/addresslogo.svg"
                alt="logo"
                className="w-[22px] h-[22px] rounded-full"
              />
              <div className="ml-[6px] text-[10px]">{title}</div>
            </div>
            {/* <div className='font-bold'>{`${metaData.title}`}</div> */}
          </div>
        </Card>
        {/* </Link> */}
      </div>
      {/* )} */}
    </>
  );
};

export default AssestsView;
