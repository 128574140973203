import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const ManagersTokenCard = ({
  coin,
  allCoinPrice,
  usersToken,
  userId,
  isManagersTokenPage,
}) => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();

  return (
    <div>
      {/* {JSON.stringify(coin)}
      {JSON.stringify(usersToken)} */}
      <div
        className="flex justify-start items-center  border border-gray-200 bg-gray-50 px-[30px] py-[10px] rounded-full rounded-l-full rounded-r-full  cursor-pointer mt-[20px]"
        style={{
          background: "linear-gradient(180deg, #F1ECF5 0%, #F2EEF5 100%)",
        }}
      >
        <div className="w-[100%] flex justify-between ">
          <div
            className="w-[100%] flex justify-start items-center"
            onClick={() => {
              navigate(`/token/${coin?.tokenId}/${userId}/${coin?.symbol}`);
            }}
          >
            <div className="pr-[10px] md:pr-[20px]">
              <img
                src={coin.tokenIcon}
                className="w-[30px] md:w-[40px]"
                alt="tail_coin"
              />
            </div>
            <div
              className="flex flex-col justify-start 
                    "
            >
              <div className="font-medium text-[18px] flex justify-start">
                {coin.tokenName}
              </div>
            </div>
          </div>
          {/* right side  */}
          <div className="flex w-[170px] justify-between items-center  gap-5">
            <div className="flex flex-col justify-start w-[70%]">
              <h5 className="font-medium text-left">
                ${allCoinPrice && allCoinPrice[coin.symbol]?.price?.toFixed(5)}
              </h5>

              {allCoinPrice && (
                <p
                  className={`${
                    allCoinPrice[coin.symbol]?.priceChange > 0
                      ? "text-green-500"
                      : "text-red-500"
                  }  flex items-center gap-1 `}
                >
                  <FontAwesomeIcon
                    icon={
                      allCoinPrice[coin.symbol]?.priceChange > 0
                        ? faArrowUp
                        : faArrowDown
                    }
                    className=" text-base"
                    aria-hidden="true"
                  />
                  {allCoinPrice[coin.symbol]?.priceChange?.toFixed(5)}
                </p>
              )}
            </div>
            <div className=" w-[20%]">
              <img src="/images/arrowright.png " className="w-[50%]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagersTokenCard;
