import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const AddAccountModal = ({ openDemoModal, setOpenDemoModal }) => {
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [pan, setPan] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [loader, setLoader] = useState(false);
  const [progress, setProgress] = useState(0);
  const [usserId, setUserId] = useState("");
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();

  const isMobileDevice = window.innerWidth <= 768;
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      top: "56%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      width: isMobileDevice ? "90%" : "auto",
      borderRadius: "15px",
      zIndex: 9999,
    },
  };
  function closeModal() {
    setOpenDemoModal(false);
    setEmail("");
    setMobile("");
    setFirstName("");
    setLastName("");
    setPan("");
    setAadhar("");
    setProgress(0);
  }

  const setupUserWallet = (id) => {
    setProgress(3);
    let data = JSON.stringify({
      wallet: "TALEWALLET",
      userId: id,
      blockchain: appCtx.blockchain,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/fundMagnet/client/blockchain/wallet/setup`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setProgress(4);
      })
      .catch((error) => {
        closeModal();
      });
  };

  const handleSubmit = () => {
    setProgress(1);
    setLoader(true);

    let data = JSON.stringify({
      email: email,
      mobile: mobile,
      wmId: appCtx.wealthManager.wmId,
      firstName: firstName,
      lastName: lastName,
      pan: pan,
      aadhar: aadhar,
    });

    let config = {
      method: "post",

      url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/fundMagnet/client/add`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify());
        setProgress(2);
        setLoader(false);
        setupUserWallet(response.data?.userId);
        setUserId(response.data?.userId);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Request failed. Please try again.");
        closeModal();
      });
  };
  useEffect(() => {
    if (progress > 3) {
      const timer = setTimeout(() => {
        closeModal();
        navigate(`/wallet/${usserId}`);
        toast.success("New account added successfully");
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [progress]);
  return (
    <Modal
      isOpen={openDemoModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="w-full flex justify-end  ">
        <button
          className="font-medium text-2xl flex items-center leading-10 mr-5 "
          onClick={closeModal}
        >
          X
        </button>
      </div>

      {progress === 0 && (
        <div className="flex items-center justify-center py-2 overflow-hidden">
          <div className="mx-auto w-full max-w-[550px] bg-white">
            <p className="text-center font-bold text-2xl my-5"> Add Clients</p>
            <form>
              <div className="-mx-3 flex flex-wrap">
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      for="fName"
                      className="mb-3 block text-base font-medium text-[#07074D]"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                      placeholder="Enter  first name"
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      for="lName"
                      className="mb-3 block text-base font-medium text-[#07074D]"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                      placeholder="Enter  last name"
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      for="fName"
                      className="mb-3 block text-base font-medium text-[#07074D]"
                    >
                      Email:
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      placeholder="Enter Email"
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      for="lName"
                      className="mb-3 block text-base font-medium text-[#07074D]"
                    >
                      Phone Number:
                    </label>
                    <input
                      type="tel"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      required
                      placeholder="Enter Phone Number"
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      for="fName"
                      className="mb-3 block text-base font-medium text-[#07074D]"
                    >
                      PAN card number:
                    </label>
                    <input
                      type="text"
                      value={pan}
                      onChange={(e) => setPan(e.target.value)}
                      required
                      placeholder="Enter PAN card number"
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>
                </div>
                <div className="w-full px-3 sm:w-1/2">
                  <div className="mb-5">
                    <label
                      for="lName"
                      className="mb-3 block text-base font-medium text-[#07074D]"
                    >
                      Aadhaar Number:
                    </label>
                    <input
                      type="text"
                      value={aadhar}
                      onChange={(e) => setAadhar(e.target.value)}
                      required
                      placeholder="Enter Aadhaar Number"
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-center">
                <button
                  onClick={() => {
                    handleSubmit();
                  }}
                  className="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
                >
                  Add account
                  <span>
                    {loader && (
                      <CircularProgress
                        size={20}
                        className="ml-[5px] text-black"
                      />
                    )}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {progress > 0 && (
        <div className="h-[280px] py-10">
          {progress > 0 && (
            <div className="w-[70%] mx-auto mb-1 text-center bg-blue-200">
              <p className="text-lg font-semibold p-3">Adding Account</p>
            </div>
          )}
          {progress > 1 && (
            <div className="w-[70%] mx-auto mb-1 text-center bg-green-200">
              <p className="text-lg font-semibold  p-3">Account Added</p>
            </div>
          )}
          {progress > 2 && (
            <div className="w-[70%] mx-auto mb-1 text-center bg-yellow-200">
              <p className="text-lg font-semibold  p-3">Setting up Wallet</p>
            </div>
          )}
          {progress > 3 && (
            <div className="w-[70%] mx-auto mb-1 text-center bg-pink-200">
              <p className="text-lg font-semibold  p-3">Setup Complete</p>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default AddAccountModal;
