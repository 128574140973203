import React, { useCallback, useState, useEffect } from "react";
import {
  ExpandMore,
  LightMode,
  Menu,
  Person,
  RemoveRedEye,
  Wallet,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { persistor } from "../../context/store";
import logoWhite from "../../assets/logo-white.svg";
import logoBlack from "../../assets/logo-black.svg";
import { useDispatch } from "react-redux";
import { Popover } from "react-tiny-popover";

import "react-dropdown/style.css";
import { toast } from "react-toastify";
import NavbarDropdown from "./NavbarDropdown";
import AddAccountModal from "../AddAccountModal/AddAccountModal";
import NavWallet from "./NavWallet";
import { getImageUrl } from "../../config";
import axios from "axios";
import { appActions } from "../../context/app-slice";
import { button } from "@material-tailwind/react";
import { useWalletView } from "../../hooks/useWalletView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faUserLarge,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const [isExpanded, setExpanded] = useState(false);
  const appCtx = useSelector((state) => state.app);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [contactUsVisible, setContactUsVisible] = useState(false);
  const [openAddAccountModal, setOpenAddAccountModal] = useState(false);
  const [updateProfileVisible, setUpdateProfileVisible] = useState(false);

  const [openDemoModal, setOpenDemoModal] = useState(false);
  const [isWalletPopOpen, setIsWalletPopOpen] = useState(false);
  const [wallet, setWallet] = useState(null);
  const [marketplace, setMarketplace] = useState(null);
  const [setupWallet, setSetupWallet] = useState(false);
  const dispatch = useDispatch();

  const { callBlockchainSetupApi } = useWalletView();

  const handleLogoutClick = useCallback(() => {
    localStorage.removeItem("display");
    localStorage.removeItem("contact");
    localStorage.clear();
    persistor.purge().then(() => (window.location.href = "/"));
    // dispatch(appActions.logout())
  }, []);

  useEffect(() => {
    if (appCtx.authToken) {
      let config = {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/blockchain/account?wallet=TALEWALLET`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": appCtx.authToken,
        },
      };
      axios(config).then((response) => {
        let data = response.data;
        const walletData = data?.filter(
          (account) => account.wallet === "TALEWALLET"
        );

        if (walletData?.length > 0) {
          dispatch(appActions.setWalletAddress(walletData));
        } else {
          setSetupWallet(true);
        }
      });
    }
  }, [appCtx.blockchain]);

  return (
    // loading ?<div className='w-screen text-black flex z-[100] justify-center items-center text-3xl font-bold bg-white h-screen fixed top-0'>
    //         Hi ,please wait for a while, as we are configuring your dashboard.
    // </div>
    // :

    <>
      <NavbarDropdown
        contactUsVisible={contactUsVisible}
        setContactUsVisible={setContactUsVisible}
        onLogoutClick={handleLogoutClick}
        visible={isDropdownVisible}
      />

      <div
        className={`${
          isExpanded ? "h-[250px]" : "h-[80px]"
        } fixed z-30 md:h-[70px] w-screen  bg-white  shadow-xl text-black  px-5 py-2 transition-all ease-out duration-300 overflow-y-hidden`}
      >
        <div className="flex flex-col md:flex-row justify-between w-[100%] mx-auto">
          <div className="flex justify-between gap-3 items-center ">
            <Link to="/" className="font-extrabold text-2xl no-underline">
              <img
                src="/images/fund-magnet.png"
                alt={"Fundmagnet"}
                className={"w-[120px] "}
              />
            </Link>

            <button
              className="visible md:invisible"
              onClick={() => setExpanded(!isExpanded)}
            >
              <Menu fontSize="large" className="" />
            </button>
          </div>
          {appCtx.isLoggedIn && (
            <div className="flex gap-16">
              <div
                className={`flex flex-col md:flex-row md:items-center gap-3 mt-5 md:mt-0 md:gap-5 transition-all ease-out duration-300 `}
              >
                {setupWallet && (
                  <Link
                    onClick={() => {
                      callBlockchainSetupApi(appCtx.blockchain);
                    }}
                    className="no-underline text-black  rounded-lg text-left md:text-center my-auto md:p-2 hover:opacity-70 transition-all ease-out duration-300"
                    disabled={appCtx.wealthManager?.wmId}
                  >
                    Setup
                  </Link>
                )}
                <Popover
                  isOpen={isWalletPopOpen}
                  className="z-10"
                  positions={["bottom", "right"]}
                  padding={20}
                  reposition={false}
                  onClickOutside={() => setIsWalletPopOpen(false)}
                  content={({ position }) => (
                    <div
                      // style={{ backgroundColor: "blue", opacity: 0.7 }}
                      className={` z-20 relative right-32  rounded-md overflow-hidden bg-white dark:bg-gray-700 '
                                            } text-gray-600  shadow-md bg-inherit`}
                    >
                      <NavWallet marketplace={marketplace} wallet={wallet} />
                    </div>
                  )}
                >
                  <button
                    className="mt-1"
                    onClick={() => setIsWalletPopOpen(!isWalletPopOpen)}
                  >
                    <div className="flex gap-2  items-center">
                      <FontAwesomeIcon
                        icon={faWallet}
                        className="w-[20px] h-[20px]"
                      />
                      {wallet && (
                        <div className="text-[10px] flex bg-slate-200 dark:bg-gray-600 rounded-3xl gap-1 p-1 items-center">
                          <img
                            src={getImageUrl(wallet?.blockchain)}
                            className="w-4 h-4"
                          />

                          {/* {appCtx.walletDetails.balance.toFixed(5)} */}
                        </div>
                      )}
                    </div>
                  </button>
                </Popover>
                <Link
                  onClick={(e) => {
                    if (!appCtx.wealthManager?.wmId) {
                      e.preventDefault();
                      return;
                    }
                    setOpenAddAccountModal(!openAddAccountModal);
                  }}
                  className="w-[20px] h-[20px]"
                  disabled={appCtx.wealthManager?.wmId}
                >
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    style={{ color: "#252b2d" }}
                    className="w-[20px] h-[20px]"
                  />
                </Link>

                <Link
                  onClick={() => setDropdownVisible(!isDropdownVisible)}
                  to="#"
                  className="w-[20px] h-[20px]"
                >
                  <FontAwesomeIcon
                    icon={faUserLarge}
                    style={{ color: "#1d293f" }}
                    className="w-[20px] h-[20px]"
                  />
                  {/* {appCtx.userDetails.name.split(' ')[0]} */}
                </Link>
              </div>
            </div>
          )}
        </div>
        <AddAccountModal
          openDemoModal={openAddAccountModal}
          setOpenDemoModal={setOpenAddAccountModal}
        />
      </div>
    </>
  );
};

export default Navbar;
