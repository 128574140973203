import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import axios from "axios";
import { usehandleBuyTaleCoin } from "../../../hooks/handleBuyTaleCoin";
import { Link, useNavigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import KycInfoModal from "./KycInfoModal";
import { Button, Tooltip } from "@mui/material";
import AddFundModal from "./AddFundModal";
import AddAccountModal from "../../AddAccountModal/AddAccountModal";

const InvestorsAccounts = () => {
  const [accounts, setAccounts] = useState("");
  const [accountsAddresses, setAccountsAddresses] = useState("");
  const [portfolioData, setPortfolioData] = useState({});
  const [clientListLoading, setClientListLoading] = useState(false);
  const [combinedData, setCombinedData] = useState([]);
  const [kycInfo, setkycInfo] = useState("");
  const [openKycInfoModal, setOpenKycInfoModal] = useState(false);
  const [openAddFundModal, setOpenAddFundModal] = useState(false);
  const [totalClients, setTotalClients] = useState(false);
  const [openAddAccountModal, setOpenAddAccountModal] = useState(false);

  const [clientData, setClientData] = useState("");
  const navigate = useNavigate();

  const appCtx = useSelector((state) => state.app);

  const [searchValue, setSearchValue] = useState("");

  const [openDropdownId, setOpenDropdownId] = useState(null);
  const toggleDropdown = (id) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  // Filter the rows based on the search value
  const filteredData = combinedData.filter((data) => {
    const fullName = `${data.firstName} ${data.lastName}`.toLowerCase();
    return fullName.includes(searchValue.toLowerCase());
  });

  useEffect(() => {
    setClientListLoading(true);
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/fundMagnet/wm/${appCtx.wealthManager.wmId}/client/list`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        setAccounts(response.data?.content);
        const userIdArray = response.data?.content?.map((item) => item?.userId);
        setTotalClients(response?.data?.totalElements);

        let data = JSON.stringify(userIdArray);

        let config2 = {
          method: "post",
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/address/list`,
          headers: {
            "X-Auth-Token": appCtx.authToken,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config2)
          .then((response) => {
            setAccountsAddresses(response.data);
            // setClientListLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });

        let config3 = {
          method: "post",
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/fundMagnet/client/portfolio/list`,
          headers: {
            "X-Auth-Token": appCtx.authToken,
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config3)
          .then((response) => {
            setPortfolioData(response.data);
            setClientListLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (accounts && accountsAddresses) {
      const data = accounts.map((account) => {
        const matchingAddress = accountsAddresses.find(
          (address) =>
            address.userId === account.userId && address.blockchain === "BSC"
        );

        const address = matchingAddress ? matchingAddress.address : "";
        const truncatedAddress =
          address?.length > 11
            ? address.substring(0, 5) + "..." + address.slice(-5)
            : address;

        const portfolio = portfolioData[account?.userId];
        return {
          userId: account.userId,
          clientId: account.clientId,
          firstName: account.firstName,
          lastName: account.lastName,
          email: account.email,
          mobile: account.mobile,
          aadhar: account.aadhar,
          pan: account.pan,
          address: matchingAddress ? matchingAddress.address : "",
          truncatedAddress,
          aum: portfolio?.tvl,
          investment: portfolio?.investment,
          netProfit: portfolio?.gain,
        };
      });

      setCombinedData(data);
    }
  }, [accounts, accountsAddresses]);

  return (
    <div>
      <div>
        <p className="text-3xl font-bold">Clients</p>
      </div>
      <div className="flex flex-row items-center justify-between  w-[100%]">
        <div>
          <p className=" font-semibold ">Total Clients</p>
          <p className="font-semibold text-2xl"> {totalClients}</p>
        </div>
        <div className="relative py-10 w-[30%]">
          <div className="max-w-md mx-auto">
            <div className="relative flex items-center w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden">
              <div className="grid place-items-center h-full w-12 text-gray-300">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>

              <input
                className="peer h-full w-full outline-none text-sm text-gray-700 pr-2"
                type="text"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Search Client.."
              />
            </div>
          </div>
        </div>
        <div>
          <button
            onClick={() => {
              setOpenAddAccountModal(!openAddAccountModal);
            }}
            className="relative inline-flex items-center justify-center p-4 px-5 py-3 overflow-hidden font-medium bg-[#5c22b5] text-slate-100 transition duration-300 ease-out rounded-full shadow-xl group hover:ring-1 hover:ring-purple-500"
          >
            <span className="relative text-white"> Add Client</span>
          </button>
        </div>
      </div>

      <div className="">
        {accounts && (
          <>
            <table className="w-full border-collapse">
              <thead>
                <tr>
                  <th className=" p-2 text-center">Clients Id</th>
                  <th className=" p-2 text-center">Name</th>
                  <th className=" p-2 text-center">email-id</th>
                  <th className=" p-2 text-center">mobile</th>

                  <th className=" p-2 text-center">wallet address</th>
                  <th className=" p-2 text-center">AUM</th>
                  <th className=" p-2 text-center">Investment</th>
                  <th className=" p-2 text-center">Net profit</th>
                  <th className=" p-2 text-center">Action</th>
                  <th className="p-2 text-center"></th>
                  <th className=" p-2 text-center"></th>
                </tr>
              </thead>
              <tbody>
                {clientListLoading ? (
                  Array(5)
                    .fill()
                    .map((_, index) => (
                      <tr key={index} className="bg-white rounded-xl">
                        {Array(11)
                          .fill()
                          .map((_, columnIndex) => (
                            <td
                              key={columnIndex}
                              colSpan={1}
                              className="border-b-[15px] border-[#F1F5F9] p-2 text-center mb-5"
                            >
                              <SkeletonTheme
                                baseColor="#f2f2f2"
                                highlightColor="#d9d9d9"
                              >
                                <Skeleton count={1} />
                              </SkeletonTheme>
                            </td>
                          ))}
                      </tr>
                    ))
                ) : filteredData && filteredData.length === 0 ? (
                  <tr>
                    <td className="text-center p-10" colSpan={7}>
                      No client found
                    </td>
                  </tr>
                ) : (
                  filteredData &&
                  filteredData.map((data, index) => (
                    <tr key={index} className="bg-white rounded-xl">
                      <td className=" border-b-[15px] border-[#F1F5F9] p-2 text-center mb-5">
                        {data.clientId}
                      </td>
                      <td className="border-b-[15px] border-[#F1F5F9] p-2 text-center">
                        {data?.firstName !== null &&
                          data?.lastName !== null &&
                          data?.firstName !== "null" &&
                          data?.lastName !== "null" &&
                          `${data?.firstName} ${data?.lastName}`}
                      </td>

                      <td className="border-b-[15px] border-[#F1F5F9] p-2 text-center">
                        {data.email}
                      </td>
                      <td className="border-b-[15px] border-[#F1F5F9] p-2 text-center">
                        {data.mobile}
                      </td>

                      <td className="border-b-[15px] border-[#F1F5F9] p-2 text-center">
                        <Tooltip title={data?.address}>
                          <button className="">{data?.truncatedAddress}</button>
                        </Tooltip>
                      </td>

                      <td className="border-b-[15px] border-[#F1F5F9] p-2 text-center">
                        {data?.aum}
                      </td>
                      <td className="border-b-[15px] border-[#F1F5F9] p-2 text-center">
                        {data?.investment}
                      </td>
                      <td className="border-b-[15px] border-[#F1F5F9] p-2 text-center">
                        {data?.netProfit}
                      </td>
                      <td className="border-b-[15px] border-[#F1F5F9] p-2 text-center">
                        <button
                          onClick={() => {
                            navigate(`/wallet/${data.userId}`);
                          }}
                          className="w-full py-2 relative rounded group overflow-hidden font-medium bg-[#5c22b5] text-white inline-block"
                        >
                          More
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </>
        )}
      </div>
      <KycInfoModal
        openModal={openKycInfoModal}
        setOpenModal={setOpenKycInfoModal}
        kycInfo={kycInfo}
      />

      {clientData && (
        <AddFundModal
          openModal={openAddFundModal}
          setOpenModal={setOpenAddFundModal}
          clientData={clientData}
        />
      )}
      <AddAccountModal
        openDemoModal={openAddAccountModal}
        setOpenDemoModal={setOpenAddAccountModal}
      />
    </div>
  );
};

export default InvestorsAccounts;
