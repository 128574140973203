import algosdk from "algosdk";
import axios from "axios";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AlgorandClient from "../components/services/algorandsdk";
import { nativeToken } from "../config";
import { appActions } from "../context/app-slice";
import { useNavigate } from "react-router";

var CryptoJS = require("crypto-js");

export const useWalletView = () => {
  const [accountAsset, setAccountAsset] = useState([]);
  const [otheraccountAsset, setOtherAccountAsset] = useState([]);
  const [assetLoader, setAssetLoader] = useState(false);
  const [otherassetLoader, setOtherAssetLoader] = useState(false);
  const [amount, setAmount] = useState(0);
  const [assetUrl, setAssetUrl] = useState([]);
  const [optedIn, setOptIn] = useState(false);
  const [taleAmount, setTaleAmount] = useState(0);
  const [optInSuccessfull, setOptInSuccessfull] = useState(false);
  const [minBalance, setMinBalance] = useState(0);
  const [token, setToken] = useState(null);
  const [allCoin, setAllcoin] = useState([]);
  const [allCoinPrice, setAllcoinPrice] = useState([]);
  const [usersToken, setUsersToken] = useState([]);
  const [isAssetLoading, setIsAssetLOading] = useState(false);
  const [isCoinLoading, setIsCoinLOading] = useState(false);
  const [isCoinLoaded, setIsCoinLoaded] = useState(false);
  const [openSwapModal, setopenSwapModal] = useState(false);
  const [openSellModal, setopenSellModal] = useState(false);
  const [isCoinPriceLoading, setIsCoinPriceLoading] = useState(false);
  const [managersBalance, setManagersBalance] = useState(null);

  const [icon, setIcon] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const appCtx = useSelector((state) => state.app);

  const address = appCtx.walletAddress?.find(
    (addr) => addr.blockchain === appCtx.blockchain
  )?.address;

  const showAssets = async (address, id) => {
    if (appCtx.usersblockchain === "ALGORAND") {
      setIsAssetLOading(true);
      if (address) {
        setIsAssetLOading(true);
        let accountInfo = await AlgorandClient.accountInformation(address).do();
        console.log("accountInfo", accountInfo.amount);

        setAmount(parseFloat(accountInfo.amount / 1000000));
        setMinBalance(parseFloat(accountInfo["min-balance"] / 1000000));
        setToken("Algos");
        setIsAssetLOading(false);
        console.log("amount", amount);
        console.log("accountInfo", accountInfo);

        // setAccountAsset(accountInfo["created-assets"]);
      }
    } else {
      setIsAssetLOading(true);
      const blockchainMap = nativeToken;
      const blockchaintoken = blockchainMap[appCtx.usersblockchain];
      let url;
      if (appCtx.usersblockchain === "SOLANA") {
        url = `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/wallet/balance?blockchain=${appCtx.usersblockchain}&address=${address}`;
      } else {
        url = `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/evm/wallet/${address}/token/${blockchaintoken}/balance?blockchain=${appCtx.usersblockchain}`;
      }

      // blockchaintoken && address;
      if (blockchaintoken && address) {
        // Axios request to get balance of a specific chain
        let config = {
          method: "get",
          url: url,
          headers: {
            "X-Auth-Token": appCtx.authToken,
            "Content-Type": "application/json",
          },
        };

        axios
          .request(config)
          .then((response) => {
            setAmount(response.data.balance);
            setMinBalance(response.data.minBalance);
            setToken(response.data.token);
          })
          .finally(() => setIsAssetLOading(false));
      }
    }
  };
  const getAssetsById = (address) => {
    setAssetLoader(true);
    // for algorand chain
    if (appCtx.usersblockchain === "ALGORAND") {
      let config = {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "X-APP-Token": process.env.REACT_APP_X_APP_TOKEN,
        },
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/${address}/list`,
      };
      axios(config)
        .then((response) => {
          if (response.data?.content === undefined) {
            setAssetLoader(false);
            setAccountAsset([]);
            // document.getElementById('wallet_asset_div').innerHTML = '<div class="container" style="background-color:#f1f1f1; line-height: 2">No asset found</div>';
          } else {
            var c =
              '<div class="container" style="text-align: left;font-size:30px">Your Assets</div> <br />';
            // console.log(accountInfo['created-assets'])
            var assetobj = response.data?.content;
            console.log("assetobj----", assetobj);
            let array = [];
            let assetUrl = [];
            for (const item in assetobj) {
              if (assetobj[item].params?.total == 1) {
                array = [
                  ...array,
                  {
                    key: assetobj[item].index,
                    value: assetobj[item].params?.url,
                    token: parseInt(
                      assetobj[item].params?.total?.length > 2 ? "10" : "1"
                    ),
                    name: assetobj[item].params?.name,
                    assetId: assetobj[item]?.assetId || "",
                    amount: assetobj[item]?.amount || 0,
                    params: assetobj[item]?.params,
                  },
                ];
              }
              assetUrl = [
                ...assetUrl,
                {
                  key: assetobj[item].index,
                  value: assetobj[item].params?.url,
                  token: parseInt(
                    assetobj[item].params?.total?.length > 2 ? "10" : "1"
                  ),
                  name: assetobj[item].params?.name,
                  assetId: assetobj[item]?.assetId || "",
                  amount: assetobj[item]?.amount || 0,
                  params: assetobj[item]?.params,
                },
              ];
            }
            setAccountAsset(array);
            setAssetUrl(assetUrl);
            setAssetLoader(false);
          }
        })
        .catch((error) => {
          setAssetLoader(false);
          if (error?.response?.status === 401) {
            dispatch(appActions.logout(undefined));
            dispatch(appActions.updateAuthToken(undefined));
            dispatch(appActions.setWalletAddress([{ address: "" }]));
            dispatch(
              appActions.updateUserDetails({
                userId: "",
                email: "",
                name: "",
              })
            );
            dispatch(appActions.setCountry(""));
            dispatch(appActions.setDocument(""));
            dispatch(appActions.setFrontSide(""));
            dispatch(appActions.setBackSide(""));
            dispatch(appActions.setPicture(""));
            dispatch(appActions.setVerified(false));
            dispatch(appActions.setMnemonicCode('""'));
            dispatch(appActions.setPassword(""));
            dispatch(appActions.setRewardData({}));
            dispatch(appActions.setMetaData(""));
            dispatch(appActions.setCustodial(true));
            dispatch(appActions.setEncrypt(""));
          }
        });
    }

    // for other chain asset
    if (appCtx.usersblockchain !== "ALGORAND") {
      let config = {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "X-APP-Token": process.env.REACT_APP_X_APP_TOKEN,
        },
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/${appCtx.userDetails.userId}/nft/list?blockchain=${appCtx.usersblockchain}`,
      };
      axios(config).then((response) => {
        if (response.data?.content === undefined) {
          setOtherAssetLoader(false);
          setOtherAccountAsset([]);
        } else {
          setOtherAccountAsset(response.data?.content);
          setOtherAssetLoader(false);
        }
      });
    }
  };
  const optInAsset = (address) => {
    const waitForConfirmation = async function (AlgorandClient, txId) {
      let response = await AlgorandClient.status().do();
      let lastround = response["last-round"];
      while (true) {
        const pendingInfo = await AlgorandClient.pendingTransactionInformation(
          txId
        ).do();
        if (
          pendingInfo["confirmed-round"] !== null &&
          pendingInfo["confirmed-round"] > 0
        ) {
          //Got the completed Transaction
          // console.log("Transaction " + txId + " confirmed in round " + pendingInfo["confirmed-round"]);
          break;
        }
        lastround++;
        await AlgorandClient.statusAfterBlock(lastround).do();
      }
    };

    // Function used to print created asset for account and assetid
    const printCreatedAsset = async function (
      AlgorandClient,
      account,
      assetid
    ) {
      // note: if you have an indexer instance available it is easier to just use this
      //     let accountInfo = await indexerClient.searchAccounts()
      //    .assetID(assetIndex).do();
      // and in the loop below use this to extract the asset for a particular account
      // accountInfo['accounts'][idx][account]);
      let accountInfo = await AlgorandClient.accountInformation(account).do();
      for (let idx = 0; idx < accountInfo["created-assets"].length; idx++) {
        let scrutinizedAsset = accountInfo["created-assets"][idx];
        if (scrutinizedAsset["index"] == assetid) {
          // console.log("AssetID = " + scrutinizedAsset['index']);
          let myparms = JSON.stringify(
            scrutinizedAsset["params"],
            undefined,
            2
          );
          // console.log("parms = " + myparms);
          break;
        }
      }
    };
    // Function used to print asset holding for account and assetid
    const printAssetHolding = async function (
      AlgorandClient,
      account,
      assetid
    ) {
      // note: if you have an indexer instance available it is easier to just use this
      //     let accountInfo = await indexerClient.searchAccounts()
      //    .assetID(assetIndex).do();
      // and in the loop below use this to extract the asset for a particular account
      // accountInfo['accounts'][idx][account]);
      let accountInfo = await AlgorandClient.accountInformation(account).do();
      for (let idx = 0; idx < accountInfo["assets"].length; idx++) {
        let scrutinizedAsset = accountInfo["assets"][idx];
        if (scrutinizedAsset["asset-id"] == assetid) {
          let myassetholding = JSON.stringify(scrutinizedAsset, undefined, 2);
          // console.log("assetholdinginfo = " + myassetholding);
          break;
        }
      }
    };
    const oldMnemonic = appCtx.mnemonic?.split(" ");
    let decryptedData = "";

    if (oldMnemonic?.length > 1) {
      var ciphertext = CryptoJS?.AES?.encrypt(
        JSON.stringify(appCtx.mnemonic),
        appCtx?.encrypt || ""
      ).toString();
      let bytes = CryptoJS?.AES?.decrypt(ciphertext, appCtx?.encrypt || "");
      decryptedData =
        bytes.toString(CryptoJS?.enc?.Utf8) &&
        JSON.parse(bytes.toString(CryptoJS?.enc?.Utf8));
    } else {
      var bytes = CryptoJS?.AES?.decrypt(
        appCtx?.mnemonic || '""',
        appCtx?.encrypt || ""
      );
      decryptedData =
        bytes.toString(CryptoJS?.enc?.Utf8) &&
        JSON.parse(bytes.toString(CryptoJS?.enc?.Utf8));
    }
    // JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    var account3_mnemonic = decryptedData;
    var recoveredAccount3 = algosdk.mnemonicToSecretKey(account3_mnemonic);

    (async () => {
      let note = undefined;
      let assetID = parseInt(process.env.REACT_APP_TAIL_COIN_TOKEN);
      // console.log(process.env.REACT_APP_TAIL_COIN_TOKEN);

      let params = await AlgorandClient.getTransactionParams().do();
      //comment out the next two lines to use suggested fee
      params.fee = 1000;
      params.flatFee = true;

      let sender = recoveredAccount3.addr;
      let recipient = sender;
      // We set revocationTarget to undefined as
      // This is not a clawback operation
      let revocationTarget = undefined;
      // CloseReaminerTo is set to undefined as
      // we are not closing out an asset
      let closeRemainderTo = undefined;
      // We are sending 0 assets
      let amount = 0;

      // signing and sending "txn" allows sender to begin accepting asset specified by creator and index
      let opttxn = algosdk.makeAssetTransferTxnWithSuggestedParams(
        sender,
        recipient,
        closeRemainderTo,
        revocationTarget,
        amount,
        note,
        assetID,
        params
      );

      // Must be signed by the account wishing to opt in to the asset
      let rawSignedTxn = opttxn.signTxn(recoveredAccount3.sk);
      let opttx = await AlgorandClient.sendRawTransaction(rawSignedTxn).do();
      // console.log("Transaction : " + opttx.txId);
      // wait for transaction to be confirmed
      await waitForConfirmation(AlgorandClient, opttx.txId);

      //You should now see the new asset listed in the account information
      // console.log("Account 3 = " + recoveredAccount3.addr);
      await printAssetHolding(AlgorandClient, recoveredAccount3.addr, assetID);
      //////////
      showTaleData();
    })().catch((e) => {
      // console.log(e);
      console.trace();
      toast.error(
        "Your wallet should have atleast 0.451 ALGOS to opt In Tale Coin token and claim reward"
      );
      setOptInSuccessfull(false);
      // setOptIn(false)
    });
  };
  const showTaleData = useCallback(
    async (address) => {
      if (appCtx.usersblockchain === "ALGORAND") {
        let accountInfo = await AlgorandClient.accountInformation(address).do();
        setAmount(accountInfo.amount / 1000000);
        let array = [];

        if (accountInfo.assets === undefined) {
          // setAccountAsset([]);
          // document.getElementById('wallet_asset_div').innerHTML = '<div class="container" style="background-color:#f1f1f1; line-height: 2">No asset found</div>';
        } else {
          var assetobj = accountInfo.assets;
          let assetUrl = [];
          assetobj?.map((asset) => {
            array = [
              ...array,
              { key: asset["asset-id"], amount: asset.amount },
            ];
          });
          // console.log(array);
        }

        const isassetIdPresent = array?.filter((assets) => {
          return assets.key == process.env.REACT_APP_TAIL_COIN_TOKEN;
        });

        if (isassetIdPresent?.length > 0) {
          setTaleAmount(isassetIdPresent[0]?.amount / 100);
          // console.log(isassetIdPresent);
          setOptInSuccessfull(false);
          setOptIn(true);
        } else {
          setOptIn(false);
          // setOptInSuccessfull(false)
        }
      }
    },
    [appCtx.walletAddress, optedIn, appCtx.usersblockchain]
  );

  const getAllCoin = (blockchain) => {
    setIsCoinLOading(true);

    let config = {
      method: "get",
      url: `${
        process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE
      }/token/list?blockchain=${
        blockchain || appCtx.usersblockchain
      }&page=0&size=60`,
      headers: {
        "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
      },
    };
    axios
      .request(config)
      .then((response) => {
        setIsCoinLOading(false);
        setAllcoin(response.data.content);
        const token =
          appCtx.usersblockchain === "POLYGON"
            ? "MATIC"
            : appCtx.usersblockchain === "BSC"
            ? "BNB"
            : appCtx.usersblockchain === "ETHEREUM"
            ? "ETH"
            : appCtx.usersblockchain === "SOLANA"
            ? "SOL"
            : "";
        const icon = response.data.content.find(
          (coin) => coin.symbol === token
        ).tokenIcon;
        setIcon(icon);

        setIsCoinLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getAllCoinPrice = (blockchain) => {
    setIsCoinPriceLoading(true);
    let config = {
      method: "get",
      url: `${
        process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE
      }/token/price?blockchain=${blockchain || appCtx.usersblockchain}`,
      headers: {
        "X-APP-Token": process.env.REACT_APP_X_APP_TOKEN,
      },
    };
    axios
      .request(config)
      .then((response) => {
        setAllcoinPrice(response.data);
        setIsCoinPriceLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsCoinPriceLoading(false);
      });
  };

  const getUsersToken = (id, blockchain) => {
    setIsAssetLOading(true);
    const blockchainMap = nativeToken;
    const blockchaintoken = blockchainMap[appCtx.usersblockchain];
    // blockchaintoken && address;
    if (address) {
      // Axios request to get balance of a specific chain
      const axios = require("axios");
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/fundMagnet/user/${id}/token`,
        headers: {
          "Content-Type": "application/json",
          "X-App-Token": process.env.REACT_APP_X_APP_TOKEN,
        },
      };
      axios
        .request(config)
        .then((response) => {
          setUsersToken(response.data);
          const balance = response?.data?.find(
            (balance) =>
              balance.userId === id &&
              balance.blockchain === appCtx.usersblockchain &&
              balance.token === blockchaintoken
          );
          setAmount(balance);
          console.log("hello balance", balance);
          // setMinBalance(response.data.minBalance);
          // setToken(response.data.token);
          setIsAssetLOading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleOptIn = () => {
    const isAssetIdPresent = accountAsset?.filter((asset) => {
      return asset.key === process.env.REACT_APP_TAIL_COIN_TOKEN;
    });
    if (isAssetIdPresent?.length === 0) {
      try {
        optInAsset(process.env.REACT_APP_TAIL_COIN_TOKEN);
      } catch {
        setOptInSuccessfull(false);
        toast.error(
          "Your wallet should have atleast 0.451 ALGOS to opt In Tale Coin token and claim reward"
        );
      }
    }
  };
  const handleCustodialOptIn = () => {
    if (amount >= 0.451) {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/asset/${process.env.REACT_APP_TAIL_COIN_TOKEN}/optin`,
        headers: {
          "Content-Type": "application/json",
          "X-APP-Token": process.env.REACT_APP_X_APP_TOKEN,
        },
      })
        .then((res) => {
          setOptInSuccessfull(false);
          setOptIn(true);
        })
        .catch(() => {
          setOptInSuccessfull(false);
          toast.error("Failed to optIn asset unsuccessfull");
        });
    } else {
      toast.error(
        "Your wallet should have atleast 0.451 ALGOS to opt In Tale Coin Token"
      );
      setOptInSuccessfull(false);
    }
  };

  const getFundManagersBalance = async () => {
    if (appCtx.blockchain === "ALGORAND" && address) {
      let accountInfo = await AlgorandClient.accountInformation(address).do();

      setManagersBalance(parseFloat(accountInfo.amount / 1000000));
    } else {
      const blockchainMap = nativeToken;
      const blockchaintoken = blockchainMap[appCtx.blockchain];
      let url;
      if (appCtx.blockchain === "SOLANA") {
        url = `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/wallet/balance?blockchain=${appCtx.blockchain}&address=${address}`;
      } else {
        url = `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/evm/wallet/${address}/token/${blockchaintoken}/balance?blockchain=${appCtx.blockchain}`;
      }

      // blockchaintoken && address;
      if (blockchaintoken && address) {
        // Axios request to get balance of a specific chain
        let config = {
          method: "get",
          url: url,
          headers: {
            "X-Auth-Token": appCtx.authToken,
            "Content-Type": "application/json",
          },
        };

        axios.request(config).then((response) => {
          setManagersBalance(response.data.balance);
          // setMinBalance(response.data.minBalance);
          // setToken(response.data.token);
        });
        // .finally(() => setIsAssetLOading(false));
      }
    }
  };

  const callBlockchainSetupApi = (blockchain) => {
    let data = JSON.stringify({
      blockchain: blockchain,
      wallet: "TALEWALLET",
      marketplaceAddress: "0",
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/blockchain/wallet/setup`,
      headers: {
        "X-Auth-Token": appCtx.authToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        dispatch(appActions.setBlockChain(response.data.blockchain));
        // dispatch(appActions.setWalletAddress(walletData));

        toast.success("new network added successfully  ");
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return {
    token,
    accountAsset,
    setAccountAsset,
    amount,
    setAmount,
    assetUrl,
    setAssetUrl,
    appCtx,
    handleOptIn,
    showAssets,
    optedIn,
    taleAmount,
    showTaleData,
    setOptInSuccessfull,
    optInSuccessfull,
    handleCustodialOptIn,
    minBalance,
    getAssetsById,
    assetLoader,
    allCoin,
    allCoinPrice,
    usersToken,
    isAssetLoading,
    isCoinLoading,
    isCoinLoaded,
    icon,
    otheraccountAsset,
    otherassetLoader,
    setIsAssetLOading,
    openSwapModal,
    setopenSwapModal,
    openSellModal,
    setopenSellModal,
    getAllCoin,
    getAllCoinPrice,
    getUsersToken,
    setManagersBalance,
    managersBalance,
    getFundManagersBalance,
    callBlockchainSetupApi,
    setAllcoinPrice,
    isCoinPriceLoading,
  };
};
