import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useWalletView } from "../hooks/useWalletView";
import { useTailCoin } from "../hooks/useTailCoin";
import { useCirclePayment } from "../hooks/useCirclePayment";
import { AccountAddressBar } from "../components/ui/AccountAddressBar";
import { Skeleton, Stack, Tooltip } from "@mui/material";
import Areachart from "../components/ui/Areachart/Areachart";
import Priceperformance from "../components/ui/Priceperformance/Priceperformance";
import TaleWalletOnRampModal from "../components/TaleWalletOnRampModal/TaleWalletOnRampModal";
import SendAlgosModal from "../components/ui/BuyAndSellAlgos/sendAlgos/SendAgosModal";
import PaymentStatusLoader from "../components/ui/paymentStatusLoader/PaymentStatusLoader";
import SwapModal from "../components/ui/swap/SwapModal";
import ModalPaymentSuccess from "../components/ui/modal-payment-success/ModalPaymentSuccess";
import TaleWalletOffRampModal from "../components/TaleWalletOffRamp/TaleWalletOffRampModal";
import axios from "axios";
import ModalPaymentFailure from "../components/ui/modal-payment-failure/ModalPaymentFailure";
import SelectClientModal from "../components/ui/SelectClientModal/SelectClientModal";

export const TokenDetailsPage = () => {
  const appCtx = useSelector((state) => state.app);
  const navigate = useNavigate();
  const [openBuyTaleModal, setOpenBuyTaleModal] = useState(false);
  const [openSendAlgosModal, setOpenSendAlgosModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedChartTab, setSelectedChartTab] = useState(0);
  const [isModalOpen, setModalIsOpen] = useState(false);
  const [userWallet, setUserWallet] = useState([]);

  const [selectedPerformanceTab, setSelectedPerformancetTab] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("1D");
  const [openTalewalletOnrampModal, setOpenTalewalletOnrampModal] =
    useState(false);
  const [isTokenDetailsPage, setIsTokenDetailsPage] = useState(true);
  const [openOfframpModal, setOpenOfframpModal] = useState(false);
  const [blockchainobject, setBlockchainobject] = useState(false);
  const [openSelectClientModal, setOpenSelectClientModal] = useState(false);

  const [isManager, setIsManager] = useState(false);
  const { userId, tokenid, tokoenname } = useParams();

  const toggleModal = () => {
    setModalIsOpen(!isModalOpen);
  };

  useEffect(() => {
    window.scroll(0, 0);
    if (userId == appCtx?.userDetails?.userId) {
      setIsManager(true);
    }
  }, []);

  const {
    taleAmount,
    showTaleData,
    // showAssets,
    // getAssetsById,
    allCoin,
    allCoinPrice,
    usersToken,
    openSwapModal,
    setopenSwapModal,
    openSellModal,
    setopenSellModal,
    getAllCoin,
    getAllCoinPrice,
  } = useWalletView();
  const {
    userToken,
    tokenDetails,
    getTokenDetailsbyId,
    getAllcoinprice,
    // allCoinPrice,
    getDescriptionData,
    descriptionData,
  } = useTailCoin();

  var address = userWallet?.find(
    (addr) => addr.blockchain === appCtx.usersblockchain
  )?.address;

  useEffect(() => {
    if (userId) {
      let data = [userId];

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/user/address/list`,
        headers: {
          "X-Auth-Token": appCtx.authToken,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          let data = response.data;
          setUserWallet(response.data);
          const blockchainObjects = data.reduce((obj, item) => {
            obj[item.blockchain] = true;
            return obj;
          }, {});

          setBlockchainobject(blockchainObjects);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [appCtx.usersblockchain]);

  useEffect(() => {
    if (address) {
      if (isManager) {
        getAllCoinPrice(appCtx.managersBlockchain);
      } else {
        getAllCoinPrice(appCtx.usersblockchain);
      }

      getTokenDetailsbyId(tokenid);
      getDescriptionData(tokenid);
    }
  }, [address, appCtx.usersblockchain, tokoenname]);

  const {
    openModalPaymentFailure,
    openModalPaymentSuccess,
    setOpenModalPaymentFailure,
    setOpenModalPaymentSuccess,
    paymentStatusLoader,
    handleCirclePayment,
    getUserCardList,
    handleCountryCode,
  } = useCirclePayment();

  useEffect(() => {
    showTaleData();
  }, []);

   const Data = [
     {
       id: 0,
       value: "1H",
       range: "h",
     },
     {
       id: 1,
       value: "1D",
       range: "d",
     },
     {
       id: 2,
       value: "7D",
       range: "w",
     },
     {
       id: 3,
       value: "1M",
       range: "m",
     },
     {
       id: 4,
       value: "3M",
       range: "3m",
     },
     {
       id: 5,
       value: "6M",
       range: "6m",
     },
     {
       id: 6,
       value: "1Y",
       range: "y",
     },
   ];

  return (
    <div
      className=""
      style={{
        background: "linear-gradient(180deg, #3C0B8C 0%, #FFFFFF 119.14%)",
      }}
    >
      <div className="flex flex-col justify-between min-h-screen h-fit text-gray-800 home">
        {/* <div className="absolute left-[-11px] sm:left-[30px] top-[65px]  flex justify-start ml-[20px] pt-[30px]">
            <div className="flex flex-col gap-5"></div>

            <button
              className=" font-medium flex items-center leading-[10px] mr-[10px] mt-[10px]  text-white"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIosOutlinedIcon className="bg-gray-200 shadow-md rounded-md p-1 mr-[5px]" />{" "}
              Back
            </button>
          </div> */}
        <div className="w-[100%] pt-[70px] lg:mt-[20px] flex flex-col justify-center">
          {/* top div  */}
          <div className="flex flex-col justify-center  text-white">
            {!isManager && (
              <div className="flex justify-center  ">
                <AccountAddressBar address={address} />
              </div>
            )}

            {tokenDetails && allCoinPrice ? (
              <div className="flex justify-center items-center gap-5 w-[80%] md:w-[30%] min-h-[70px]  mx-auto mt-5 mb-10 text-white">
                <div>
                  <img
                    src={tokenDetails?.tokenIcon}
                    alt=""
                    className="w-[50px]"
                  />
                </div>
                <div>
                  <h4 className="text-lg">
                    {tokenDetails?.tokenName} (
                    {tokenDetails?.symbol?.toLowerCase()})
                  </h4>

                  <h4 className="text-lg">
                    {isManager ? (
                      <>Price: ${allCoinPrice[tokoenname]?.price?.toFixed(4)}</>
                    ) : (
                      <>
                        Balance:{" "}
                        {userToken[0]?.balance
                          ? userToken[0]?.balance?.toFixed(4)
                          : "0"}
                      </>
                    )}
                  </h4>
                </div>
              </div>
            ) : (
              <Stack
                className="flex justify-center items-center  w-[80%] md:w-[30%] min-h-[70px]  mx-auto mt-5 mb-10"
                style={{ color: "white" }}
              >
                {/* For variant="text", adjust the height via font-size */}
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem", bgcolor: "white" }}
                  className="w-[80%] mx-auto"
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem", bgcolor: "white" }}
                  className="w-[80%] mx-auto"
                />
              </Stack>
            )}
          </div>

          {/* bottom div  */}

          <div className=" w-[100%] bg-white rounded-tl-[40px] md:rounded-tl-[80px] rounded-tr-[40px] md:rounded-tr-[80px]">
            <div className="flex gap-5 justify-center my-10">
              <button
                onClick={() => {
                  if (isManager) {
                    setOpenSelectClientModal(true);
                  } else {
                    setOpenTalewalletOnrampModal(true);
                  }
                }}
              >
                <img
                  src="/images/buyicon.svg"
                  alt="Button Image"
                  className="w-15"
                />
                <h5>Buy</h5>
              </button>
              <Tooltip title={!appCtx.custodial ? "Coming Soon" : ""}>
                <button
                  disabled={!appCtx.custodial}
                  onClick={() => {
                    showTaleData();
                    allCoin &&
                      allCoinPrice &&
                      usersToken &&
                      setOpenSendAlgosModal(true);
                  }}
                >
                  <img
                    src="/images/sendicon.svg"
                    alt="Button Image"
                    className="w-15"
                  />
                  <h5>Send</h5>
                </button>
              </Tooltip>
              <button
                onClick={() => {
                  setOpenOfframpModal(true);
                }}
              >
                <img
                  src="/images/sellicon.svg"
                  alt="Button Image"
                  className="w-15"
                />
                <h5>Sell</h5>
              </button>

              <button
                onClick={() => {
                  setopenSwapModal(true);
                }}
              >
                <img
                  src="/images/swap.svg"
                  alt="Button Image"
                  className="w-15"
                />
                <h5>Swap</h5>
              </button>
            </div>
            <div>
              <div
                className="w-[90%] md:w-[40%] mx-auto  text-lg flex justify-between"
                style={{
                  background:
                    "linear-gradient(134.72deg, #DCDCDC 3.17%, rgba(212, 210, 216, 0.92) 97.26%)",
                  borderRadius: "2px",
                }}
              >
                <button
                  className={` ${
                    selectedTab === 0 &&
                    "text-white bg-gradient-to-br from-purple-600 to-purple-900 shadow-md rounded-md"
                  } w-[48%] py-1 m-1`}
                  onClick={() => {
                    setSelectedTab(0);
                    showTaleData();
                    // showAssets()
                  }}
                >
                  Token Details
                </button>
                <button
                  className={` ${
                    selectedTab === 1 &&
                    "text-white bg-gradient-to-br from-purple-600 to-purple-900 shadow-md rounded-md"
                  } w-[48%] py-1 m-1`}
                  onClick={() => {
                    setSelectedTab(1);
                    showTaleData();
                    // showAssets()
                  }}
                >
                  Activity
                </button>
              </div>
              {/* <button
            className={`border-b-4 p-5 transition-all ease-out duration-500 ${"border-b-borderGreen"}`}
          >
            Activity
          </button> */}
            </div>
            {selectedTab === 0 ? (
              <div className="w-[100%] md:w-[90%] mx-auto">
                <div
                  className="w-[100%] md:w-[50%] mx-auto mt-20 mb-3  text-lg flex justify-between"
                  style={{
                    background:
                      "linear-gradient(134.72deg, #DCDCDC 3.17%, rgba(212, 210, 216, 0.92) 97.26%)",
                    borderRadius: "2px",
                  }}
                >
                  {Data.map((item, index) => (
                    <button
                      key={item.id}
                      className={`${
                        selectedChartTab === index &&
                        "bg-white shadow-md rounded-md"
                      } py-1 px-3 m-1`}
                      onClick={() => setSelectedChartTab(index)}
                    >
                      {item.value}
                    </button>
                  ))}
                </div>

                {tokoenname &&
                  Data.map((item, index) => {
                    if (selectedChartTab === index) {
                      return (
                        <Areachart
                          selecteddate={item.range}
                          tokenid={tokenid}
                        />
                      );
                    }
                    return null;
                  })}

                <div className="">
                  <div className="flex justify-between items-center ">
                    <h3 className="font-semibold mt-10">Price performance</h3>
                    {/* dropdown  */}
                    <div className="relative ">
                      <button
                        className="px-5 py-2  focus:outline-none focus:ring-2 focus:ring-blue-500 ring-inset ring-offset-2 ring-offset-blue-200 transition flex items-center gap-3"
                        style={{
                          background:
                            "linear-gradient(134.72deg, #EBEBEB 3.17%, rgba(219, 219, 219, 0.92) 97.26%)",
                          borderRadius: "2px",
                        }}
                        type="button"
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        <p>{selected}</p>
                        <img
                          src="/images/arrowdownnew.svg"
                          alt=""
                          className="w-[15px]"
                        />
                      </button>
                      {isOpen && (
                        <div className="absolute bg-white w-30 mt-2  overflow-hidden rounded-md shadow-xl z-10">
                          <div className="px-5 py-2">
                            <button
                              className="block px-3 py-2 text-xs text-gray-700 uppercase hover:bg-gray-200 rounded transition"
                              onClick={() => {
                                setSelected("1D");
                                setIsOpen(false);
                                setSelectedPerformancetTab(0);
                              }}
                            >
                              1D
                            </button>
                            <button
                              className="block px-3 py-1 text-xs text-gray-700 uppercase hover:bg-gray-200 rounded transition"
                              onClick={() => {
                                setSelected("7D");
                                setIsOpen(false);
                                setSelectedPerformancetTab(1);
                              }}
                            >
                              7D
                            </button>
                            <button
                              className="block px-3 py-1 text-xs text-gray-700 uppercase hover:bg-gray-200 rounded transition"
                              onClick={() => {
                                setSelected("1M");
                                setIsOpen(false);
                                setSelectedPerformancetTab(2);
                              }}
                            >
                              1M
                            </button>
                            <button
                              className="block px-3 py-1 text-xs text-gray-700 uppercase hover:bg-gray-200 rounded transition"
                              onClick={() => {
                                setSelected("6M");
                                setIsOpen(false);
                                setSelectedPerformancetTab(3);
                              }}
                            >
                              6M
                            </button>
                            <button
                              className="block px-3 py-1 text-xs text-gray-700 uppercase hover:bg-gray-200 rounded transition"
                              onClick={() => {
                                setSelected("ALL");
                                setIsOpen(false);
                                setSelectedPerformancetTab(4);
                              }}
                            >
                              ALL
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {selectedPerformanceTab === 0 ? (
                    <Priceperformance
                      selectedPerformanceDate={"d"}
                      tokoenname={tokoenname}
                    />
                  ) : (
                    ""
                  )}
                  {selectedPerformanceTab === 1 ? (
                    <Priceperformance
                      selectedPerformanceDate={"w"}
                      tokoenname={tokoenname}
                    />
                  ) : (
                    ""
                  )}
                  {selectedPerformanceTab === 2 ? (
                    <Priceperformance
                      selectedPerformanceDate={"30d"}
                      tokoenname={tokoenname}
                    />
                  ) : (
                    ""
                  )}
                  {selectedPerformanceTab === 3 ? (
                    <Priceperformance
                      selectedPerformanceDate={"180d"}
                      tokoenname={tokoenname}
                    />
                  ) : (
                    ""
                  )}
                  {selectedPerformanceTab === 4 ? (
                    <Priceperformance
                      selectedPerformanceDate={"365d"}
                      tokoenname={tokoenname}
                    />
                  ) : (
                    ""
                  )}
                </div>
                {/* Description  */}
                <div className="mb-10">
                  <h3 className="font-semibold">Description</h3>
                  <div className="flex flex-col md:flex-row w-[100%] my-5 ">
                    <div
                      className="w-[100%] ml-5 md:ml-0 md:w-[50%]  flex flex-col gap-2 "
                      style={{ borderRight: "2px solid #D9D9D9" }}
                    >
                      <h5 className="font-semibold">
                        Market Cap Rank : #{descriptionData?.rank}
                      </h5>
                      <h5 className="font-semibold">
                        Market Capital : ${" "}
                        {descriptionData?.market_data?.price[0]?.market_cap}
                      </h5>
                      <h5 className="font-semibold">
                        Volume :$
                        {descriptionData?.market_data?.circulating_supply}
                      </h5>
                    </div>
                    <div className="w-[100%] md:w-[50%] pl-5 md:pl-10  flex flex-col gap-2">
                      <div>
                        {/* <h5 className="font-semibold">
                          Avg Ext Rating : {renderStars()}
                          <span>
                            <FontAwesomeIcon icon="fa-solid fa-star" />
                          </span>
                        </h5> */}
                        <h5 className="font-semibold">
                          Token Category : {tokenDetails?.category}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                {/* official link  */}
                <div className="mb-10">
                  <h3 className="font-semibold mb-5">Official Links</h3>
                  <div className="flex flex-row gap-[10%]">
                    {tokenDetails && tokenDetails?.website !== null && (
                      <div
                        className="cursor-pointer flex gap-2 items-center"
                        onClick={() => {
                          window.open(`${tokenDetails.website}`, "_blank");
                        }}
                      >
                        <img
                          src="/images/social/websiteicon.svg"
                          alt=""
                          className="w-[25px]"
                        />
                        <h5>Website</h5>
                      </div>
                    )}
                    {tokenDetails && tokenDetails?.whitepaper !== null && (
                      <div
                        className="cursor-pointer flex gap-2 items-center"
                        onClick={() => {
                          window.open(`${tokenDetails.whitepaper}`, "_blank");
                        }}
                      >
                        <img
                          src="/images/social/whitepapericon.svg"
                          alt=""
                          className="w-[25px]"
                        />
                        <h5>Whitepaper</h5>
                      </div>
                    )}
                    {tokenDetails && tokenDetails?.github !== null && (
                      <div
                        className="cursor-pointer flex gap-2 items-center"
                        onClick={() => {
                          window.open(`${tokenDetails.github}`, "_blank");
                        }}
                      >
                        <img
                          src="/images/social/githubicon.svg"
                          alt=""
                          className="w-[25px]"
                        />
                        <h5>Github</h5>
                      </div>
                    )}
                  </div>
                </div>
                {/* Social Links */}
                <div className="mb-10">
                  <h3 className="font-semibold mb-5">Social Links</h3>
                  <div className="flex flex-row gap-[10%]">
                    {tokenDetails && tokenDetails?.twitter !== null && (
                      <div
                        className="cursor-pointer flex gap-2 items-center"
                        onClick={() => {
                          window.open(`${tokenDetails.twitter}`, "_blank");
                        }}
                      >
                        <img
                          src="/images/social/twittericon.svg"
                          alt=""
                          className="w-[25px]"
                        />
                        <h5>Twitter</h5>
                      </div>
                    )}
                    {tokenDetails && tokenDetails?.facebook !== null && (
                      <div
                        className="cursor-pointer flex gap-2 items-center"
                        onClick={() => {
                          window.open(`${tokenDetails.facebook}`, "_blank");
                        }}
                      >
                        <img
                          src="/images/social/facebookicon.svg"
                          alt=""
                          className="w-[25px]"
                        />
                        <h5>facebook</h5>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* {selectedTab === 1 ? (
              <>
                <div className="flex items-center justify-center flex-col">
                  <Transaction tokoenname={tokoenname} />
                </div>
              </>
            ) : (
              ""
            )} */}
            {openTalewalletOnrampModal && (
              <TaleWalletOnRampModal
                openModal={openTalewalletOnrampModal}
                setOpenModal={setOpenTalewalletOnrampModal}
                // closeBuyModal={closeModal}
                // allCoin={allCoin}
                // allCoinPrice={allCoinPrice}
                // amount={amount}
                // basketId={basketId}
                // tokenPercentArray={tokenPercentArray}
                // tokenArray={tokenArray}
                // basketBlockchain={basketBlockchain}
                symbol={tokoenname}
                isTokenDetailsPage={isTokenDetailsPage}
              />
            )}
            {openSendAlgosModal && (
              <SendAlgosModal
                openModal={openSendAlgosModal}
                setOpenModal={setOpenSendAlgosModal}
                allCoin={allCoin}
                allCoinPrice={allCoinPrice}
                usersToken={usersToken}
              />
            )}
            {paymentStatusLoader && (
              <PaymentStatusLoader openModal={paymentStatusLoader} />
            )}
            {openModalPaymentFailure && (
              <ModalPaymentFailure
                openModalPaymentFailure={openModalPaymentFailure}
                setOpenModalPaymentFailure={setOpenModalPaymentFailure}
              />
            )}
            {openModalPaymentSuccess && (
              <ModalPaymentSuccess
                openModalPaymentSuccess={openModalPaymentSuccess}
                setOpenModalPaymentSuccess={setOpenModalPaymentSuccess}
              />
            )}
            {openSwapModal && (
              <SwapModal
                openModal={openSwapModal}
                setOpenModal={setopenSwapModal}
                allCoinPrice={allCoinPrice}
                usersToken={usersToken}
                allCoin={allCoin}
              />
            )}
            {openOfframpModal && (
              <TaleWalletOffRampModal
                openModal={openOfframpModal}
                setOpenModal={setOpenOfframpModal}
                // closeBuyModal={closeModal}
                allCoin={allCoin}
                allCoinPrice={allCoinPrice}
                // amount={amount}
                // basketId={basketId}
                // tokenPercentArray={tokenPercentArray}
                // tokenArray={tokenArray}
                // basketBlockchain={basketBlockchain}
                symbol={tokoenname}
                isTokenDetailsPage={isTokenDetailsPage}
                userId={userId}
              />
            )}

            <SelectClientModal
              openModal={openSelectClientModal}
              setOpenModal={setOpenSelectClientModal}
              symbol={tokoenname}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
