import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import styles from "./BuyModal.module.css";
import { usehandleBuyTaleCoin } from "../../hooks/handleBuyTaleCoin";
import { useWalletView } from "../../hooks/useWalletView";
import TaleWalletOnRampModal from "../TaleWalletOnRampModal/TaleWalletOnRampModal";

const BuyModal = ({
  openModal,
  setOpenModal,
  symbol,
  amount,
  basketId,
  tokenPercentArray,
  tokenArray,
  basketBlockchain,
  paramsfromMobile,
  singleBasket,
  totalInvestment,
  useraddress,
}) => {
  const [openZokshpayModal, setOpenZokshpayModal] = useState(false);
  const [isBasketDetailsPage, setIsBasketDetailsPage] = useState(false);
  const [openTalewalletOnrampModal, setOpenTalewalletOnrampModal] =
    useState(false);
  const appCtx = useSelector((state) => state.app);
  const queryParams = new URLSearchParams(window.location.search);

  let isOpenedfromMobile = queryParams.get("address");

  const navigate = useNavigate();
  let params = {
    walletAddress: [
      {
        address: queryParams.get("address"),
      },
    ],
  };
  if (isOpenedfromMobile) {
    var { handleBuyAlgos } = usehandleBuyTaleCoin(params);
  } else {
    var { handleBuyAlgos } = usehandleBuyTaleCoin(appCtx);
  }
  var { handleBuyAllToken, handleBuyNativeTokenFromWeb } =
    usehandleBuyTaleCoin(appCtx);
  const { getAllCoin, getAllCoinPrice, allCoin, allCoinPrice } =
    useWalletView();
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      top: "53%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "0",
      borderRadius: "10px",
      transform: "translate(-50%, -50%)",
      overflow: "inherit",
      // width: width > 1200 ? "600px" : "370px",
    },
  };

  useEffect(() => {
    getAllCoin();
    getAllCoinPrice();
  }, []);
  function closeModal() {
    setOpenModal(false);
  }

  const handleBasketSubscribe = () => {
    let fiatAmount = parseInt(totalInvestment);
    const address = appCtx.walletAddress.find(
      (addr) => addr.blockchain === appCtx.blockchain
    )?.address;
    handleBuyNativeTokenFromWeb(
      fiatAmount,
      singleBasket?.basketId,
      singleBasket?.minAmountCurrency,
      singleBasket?.blockchain,
      address
    );
  };

  useEffect(() => {
    const url = window.location.href;
    const searchStrings = ["subscribe-crypto-basket", "crypto-basket-details"];
    let isPageFound = false;

    for (const searchString of searchStrings) {
      if (url.includes(searchString)) {
        isPageFound = true;
        break;
      }
    }

    setIsBasketDetailsPage(isPageFound);
  }, []);

  const walletAddress = isBasketDetailsPage
    ? appCtx?.walletAddress?.find(
        (addr) => addr?.blockchain === appCtx?.blockchain
      )?.address
    : useraddress;

      

  return (
    <>
      <Modal
        isOpen={openModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        className={`${styles.popup}  `}
      >
        <div className="absolute top-[10px] right-0">
          <button
            className={`font-medium text-[#7E7E7E]  text-2xl flex items-center leading-10 mr-8 ${
              isOpenedfromMobile ? "hidden" : "block"
            }`}
            onClick={closeModal}
          >
            X
          </button>
        </div>
        <div className="p-10">
          <div>
            <h3 className="font-semibold	text-normal md:text-2xl	mt-2">
              Select your payment method
            </h3>
          </div>
          <div className="flex flex-row gap-5 justify-center items-center mt-5">
            <button
              onClick={() => {
                setOpenTalewalletOnrampModal(true);
              }}
              type="button"
              className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2 h-12"
            >
              Tale Wallet
              <img src="/images/talelogo.png" alt="" className="w-6 ml-3" />
            </button>
            <button
              onClick={() => {
                if (
                  window.location.href.includes("wallet") ||
                  window.location.href.includes("home")
                ) {
                  handleBuyAllToken(useraddress, amount);
                } else if (
                  window.location.href.includes("crypto-basket-details")
                ) {
                  handleBasketSubscribe();
                } else {
                }
                closeModal();
              }}
              type="button"
              className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2 h-12"
            >
              On-ramp
              <img src="/images/ramplogo.png" alt="" className="w-6 ml-3" />
            </button>
          </div>
        </div>
      </Modal>
      <TaleWalletOnRampModal
        openModal={openTalewalletOnrampModal}
        setOpenModal={setOpenTalewalletOnrampModal}
        closeBuyModal={closeModal}
        allCoin={allCoin}
        allCoinPrice={allCoinPrice}
        amount={amount}
        basketId={basketId}
        tokenPercentArray={tokenPercentArray}
        tokenArray={tokenArray}
        basketBlockchain={basketBlockchain}
        useraddress={walletAddress}
      />
    </>
  );
};
export default BuyModal;
