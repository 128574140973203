import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import styles from "./TaleWalletOffRampModal.module.css";
import { useSelector } from "react-redux";
import {
  faArrowRightFromBracket,
  faCheck,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddBankModal from "../Bank/AddBankModal";
import { toast } from "react-toastify";
import useAuthorizedHttp from "../../hooks/use-authorized-http";
import { useWalletView } from "../../hooks/useWalletView";
import { nativeToken } from "../../config";
import SignupPageModal from "../ui/BuyAndSellAlgos/CustodialWalletSetup/SignupPageModal";

const TaleWalletOffRampModal = ({
  openModal,
  setOpenModal,
  amount,
  basketId,
  tokenPercentArray,
  tokenArray,
  basketBlockchain,
  isTokenDetailsPage,
  symbol,
  userId,
}) => {
  const appCtx = useSelector((state) => state.app);
  const [receiveToken, setReceiveToken] = useState("INR");
  const [sendTokenPrice, setsendTokenPrice] = useState(null);
  const [sendAmount, setSendAmount] = useState(null);
  const [openAddBankModal, setOpenAddBankModal] = useState(false);
  const [openSignupPageModal, setOpenSignupPageModal] = useState(false);
  const [selectedtokenBalance, setSelectedtokenBalance] = useState(null);

  const [isBankDataCopied, setIsBankDataCopied] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

 
  const symbolFromMobile = new URLSearchParams(window.location.search).get(
    "symbol"
  );
  const blockchainFromMobile = new URLSearchParams(window.location.search).get(
    "blockchain"
  );

  const [sendToken, setsendToken] = useState(
    isTokenDetailsPage
      ? symbol
      : symbolFromMobile
      ? symbolFromMobile
      : nativeToken[appCtx.blockchain]
  );

  const networks = {
    POLYGON: "matic20",
    BSC: "bep20",
    ETHEREUM: "erc20",
    ALGORAND: "algo",
    SOLANA: "sol",
  };

  var network;
  network =
    networks[blockchainFromMobile || basketBlockchain || appCtx.blockchain];

  const [receiveAmount, setReceiveAmount] = useState(null);
  const [currentComponent, setCurrentComponent] = useState(1);

  const [upiClicked, setUpiClicked] = useState(false);
  const [bankClicked, setBankClicked] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [fourDigit, setFourDigit] = useState(null);
  const [loader, setLoader] = useState(false);
  const [networkFee, setNetworkFee] = useState(20);
  const [isBasketDetailsPage, setIsBasketDetailsPage] = useState(false);
  const [tokenPrices, setTokenPrices] = useState({});
  const [bankDatas, setBankDatas] = useState([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);

  const makeRequest = useAuthorizedHttp();
  const queryParams = new URLSearchParams(window.location.search);

  let isOpenedfromMobile = queryParams.get("address");

  const {
    getAllCoin,
    getAllCoinPrice,
    allCoin,
    allCoinPrice,
    getUsersToken,
    usersToken,
  } = useWalletView();

  useEffect(() => {
    getAllCoin();
    getAllCoinPrice();
    getUsersToken(userId);
  }, []);

  function closeModal() {
    setOpenModal(false);
  }
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9998,
    },
    content: {
      top: "53%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      borderRadius: "50px",
      padding: "0",
      transform: "translate(-50%, -50%)",
      overflow: "inherit",
      // width: width > 1200 ? "600px" : "370px",
    },
  };
  const formattedDate = moment().format("D MMM'YY, h:mm a");

  const handleCardClick = (accountNumber) => {
    if (selectedBankAccount === accountNumber) {
      setSelectedBankAccount(null);
    } else {
      setSelectedBankAccount(accountNumber);
    }
  };

 

  useEffect(() => {
    const url = window.location.href;
    const searchStrings = ["subscribe-crypto-basket", "crypto-basket-details"];
    let isPageFound = false;

    for (const searchString of searchStrings) {
      if (url.includes(searchString)) {
        isPageFound = true;
        break;
      }
    }

    setIsBasketDetailsPage(isPageFound);
  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleBankDataCopy = (value, index) => {
    navigator.clipboard.writeText(value);
    const updatedCopiedState = [...isBankDataCopied];
    updatedCopiedState[index] = true;
    setIsBankDataCopied(updatedCopiedState);
  };
  const handlereceiveTokenChange = (event) => {
    setReceiveToken(event.target.value);
    // setSendAmount("");
    // setsendToken("");
    // setReceiveAmount("");
  };

  const handlesendTokenChange = (event) => {
    setsendToken(event.target.value);
  };

  // fetching receive token details

  useEffect(() => {
    setReceiveAmount(
      Math.ceil(sendAmount * allCoinPrice[sendToken]?.price * 84)
    );
  }, [sendAmount, sendToken]);

  useEffect(() => {
    let sendTokenPriceinUsd = allCoinPrice[sendToken]?.price;
    setsendTokenPrice(Math.ceil(sendTokenPriceinUsd * 84));
  }, [sendToken, sendAmount, allCoinPrice, receiveToken]);
  useEffect(() => {
    const min = 1000; // Minimum 4-digit number
    const max = 9999; // Maximum 4-digit number
    const fourDigit = Math.floor(Math.random() * (max - min + 1)) + min;
    setFourDigit(fourDigit);
  }, []);

  const handleSendAmountChange = (event) => {
    setSendAmount(event.target.value);
  };

  const availableTokens = allCoin?.map((token) => token);
  const filteredTokens = availableTokens.filter(
    (token) => token.tokenShortName !== receiveToken
  );
  console.log("filteredTokens", filteredTokens);

  let platformFee;
  let totalFee;

  if (sendAmount !== null && sendAmount !== undefined) {
    platformFee = Math.ceil((receiveAmount * 0.4) / 100);
  } else {
    // Handle the case when sendAmount is null or undefined
    // For example:
    platformFee = "0.00";
  }

  if (networkFee !== null && networkFee !== undefined) {
    totalFee = (parseFloat(platformFee) + parseFloat(networkFee))?.toFixed(2);
  } else {
    // Handle the case when networkFee is null or undefined
    // For example:
    totalFee = platformFee;
  }

  let userAddressFromUrl = queryParams.get("address");
  const userAddress =
    appCtx?.walletAddress?.find(
      (addr) => addr?.blockchain === appCtx?.blockchain
    )?.address || userAddressFromUrl;

  const handlePaid = () => {
    setLoader(true);
    makeRequest(
      {
        url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/inhouse/onramp/transaction`,
        data: {
          fiatAmount: sendAmount,

          fiatCurrency: "INR",

          cryptoToken: isBasketDetailsPage
            ? nativeToken[basketBlockchain]
            : sendToken,

          cryptoAmount: receiveAmount,

          blockchain: isBasketDetailsPage
            ? basketBlockchain
            : isTokenDetailsPage
            ? blockchainFromMobile
            : appCtx.blockchain,

          note: fourDigit,
          platformFee: platformFee,
          networkFee: networkFee,
          tokenPrice: sendTokenPrice,
          address: userAddress,
          ...(isBasketDetailsPage && { basketId: basketId }),
        },
        method: "post",
      },
      (data) => {
        setLoader(false);
        setCurrentComponent(3);
        // setOpenModal(false);
      },
      (error) => {
        // setLoader(false);
      },
      () => {}
    );
  };

  // get clients bank account
  useEffect(() => {
    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/fundMagnet/client/${userId}/bank/account/list`,
      },
      (res) => {
        setBankDatas(res);
      }
    );
  }, [openAddBankModal]);

  useEffect(() => {
    const bal =
      usersToken &&
      usersToken.some((singleToken) => singleToken.token === sendToken)
        ? usersToken
            .filter((singleToken) => singleToken.token === sendToken)
            .map((singleToken) => Number(singleToken.balance).toFixed(5))
            .join("")
        : "0";

    setSelectedtokenBalance(bal);
  }, [sendAmount]);

  useEffect(() => {
    if (selectedBankAccount) {
      setOpenSignupPageModal(true);
      // setOpenModal(false);
    }
  }, [currentComponent]);

  let OffRampTransactionData = {
    fiatAmount: receiveAmount,

    fiatCurrency: receiveToken,

    cryptoToken: sendToken,

    cryptoAmount: sendAmount,

    blockchain: appCtx.usersblockchain,

    platformFee: platformFee,
    networkFee: networkFee,
    tokenPrice: allCoinPrice[sendToken]?.price,
    address: userAddress,
  };

  return (
    <>
      <Modal
        isOpen={openModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
        className={`${styles.popup} `}
      >
        <div
          className="w-[400px] flex flex-row justify-between items-center bg-[#9376B1] py-3 relative px-5  rounded-t-lg

"
        >
          <div className="w-[15%]">
            <img src="/images/talelogo.png" alt="" />
          </div>

          <p className="w-full text-center text-lg font-semibold">Talewallet</p>
          <div className="w-[15%]">
            <button
              className={`font-medium   text-2xl ${
                isOpenedfromMobile ? "hidden" : "block"
              } `}
              onClick={closeModal}
            >
              X
            </button>
          </div>
        </div>
        <div className="w-[400px] max-h-[550px] overflow-x-hidden overflow-y-visible bg-[#EEEEEE] rounded-xl ">
          {currentComponent === 1 && (
            <div>
              <div className="px-5 py-2">
                <p className="text-md  my-2">You sell</p>

                <div className="flex flex-row w-full rounded-md  bg-white overflow-hidden border hover:border-violet-500 border-[#EEEEEE]">
                  <div className=" w-[50%]">
                    <input
                      id="sendAmount"
                      placeholder="Enter amount"
                      name="sendAmount"
                      type="number"
                      min="0"
                      step="any"
                      value={sendAmount}
                      disabled={isBasketDetailsPage}
                      onChange={handleSendAmountChange}
                      className="block      shadow-sm  h-12 px-3 text-black text-lg leading-tight focus:outline-none p "
                    />
                  </div>
                  <div className="w-[2px] h-12   flex justify-center items-center">
                    <div className="w-[2px] h-10  border border-r border-gray-300"></div>
                  </div>
                  <div className=" w-[48%]">
                    <div className=" h-12   leading-tight focus:outline-none     shadow-xs  w-full flex flex-row  pl-5 items-center gap-3">
                      <img
                        src={
                          allCoin
                            ? allCoin.find((obj) => obj.symbol === sendToken)
                                ?.tokenIcon
                            : ""
                        }
                        alt=""
                        className="w-6"
                      />
                      <div className="flex flex-col">
                        <select
                          id="sendToken"
                          name="sendToken"
                          value={sendToken}
                          onChange={handlesendTokenChange}
                          className="block  bg-white text-sm leading-tight focus:outline-none  w-full"
                        >
                          {filteredTokens?.map((token) => (
                            <option key={token?.tokenId} value={token?.symbol}>
                              {token?.tokenName}
                            </option>
                          ))}
                        </select>
                        <p className="text-xs">On {network}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <p className=" text-md mt-5 mb-2">You receive</p>

                <div className="flex flex-row w-full rounded-md  bg-white overflow-hidden border hover:border-violet-500 border-[#EEEEEE]">
                  <div className=" w-[50%]">
                    <input
                      id="receiveAmount"
                      placeholder="you will receive"
                      name="receiveAmount"
                      type="number"
                      min="0"
                      step="any"
                      value={receiveAmount}
                      readOnly
                      className="block   shadow-sm  h-12 px-3 text-black text-lg leading-tight focus:outline-none p "
                    />
                  </div>
                  <div className="w-[2px] h-12   flex justify-center items-center">
                    <div className="w-[2px] h-10  border border-r border-gray-300"></div>
                  </div>
                  <div className=" w-[48%]">
                    <div
                      id="receiveToken"
                      name="receiveToken"
                      className=" h-12   leading-tight focus:outline-none   shadow-xs  w-full flex flex-row pl-5  items-center gap-3"
                    >
                      <img
                        src="/images/indianflag.png"
                        alt=""
                        className="w-6"
                      />
                      <div className="">
                        <p className="text-sm">INR</p>
                        <p className="text-xs">Indian Rupee</p>
                      </div>
                    </div>
                  </div>
                </div>

                <p className=" text-sm mt-2  ">
                  {sendTokenPrice && (
                    <>
                      1 {sendToken} = ₹{sendTokenPrice}
                    </>
                  )}
                </p>

                <div className="mt-5 mb-3">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className="text-[#3B0076] font-medium	 text-md ">
                        You get ₹{receiveAmount - totalFee}
                      </p>
                      <p className="ml-10">View Breakup</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <hr />
                      <div className="my-2 text-sm font-medium">
                        <div className="flex justify-between items-center mb-2 ">
                          <p>Platform Fee(0.4%)</p>
                          <p>₹{platformFee || 0}</p>
                        </div>

                        <div className="flex justify-between items-center">
                          <p>Network Fee</p>
                          <p>₹{networkFee || 0}</p>
                        </div>
                      </div>
                      <hr />
                      <div className="flex justify-between items-center mt-3 text-sm font-medium	">
                        <p>Total Fee</p>
                        <p>₹{totalFee || 0}</p>
                      </div>
                      {isBasketDetailsPage && (
                        <p className="text-sm font-medium mt-1">
                          Network fee includes{" "}
                          {tokenArray?.map((token, index) => {
                            const isLastToken = index === tokenArray.length - 1;
                            return (
                              <span
                                key={index}
                                className="text-sm font-medium -ml-[2px]"
                              >
                                {nativeToken[basketBlockchain]} to {token}
                                {!isLastToken &&
                                  index !== tokenArray.length - 2 &&
                                  ", "}
                                {!isLastToken &&
                                  index === tokenArray.length - 2 &&
                                  " and "}
                              </span>
                            );
                          })}
                        </p>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
          )}
          {currentComponent === 2 && (
            <div className="px-5 py-5">
              <p className="text-xl font-bold text-center">Select the Bank</p>
              <p className="text-lg font-medium text-center my-3">
                We'll send funds to the selected account
              </p>
              <div className="flex flex-col gap-3">
                {bankDatas && bankDatas.length === 0 ? (
                  <p className="text-center my-5">
                    You haven't added any bank accounts. Please add an account
                    to proceed further.
                  </p>
                ) : (
                  bankDatas.length !== 0 &&
                  bankDatas.map((bankData, index) => (
                    <div
                      key={index}
                      className={`w-full h-14 px-4 py-2 rounded-lg border ${
                        selectedBankAccount === bankData?.accountNumber
                          ? "border-violet-500 text-violet-500"
                          : "border-gray-600 hover:border-violet-500 hover:text-violet-500"
                      } justify-between items-center inline-flex cursor-pointer`}
                      onClick={() => handleCardClick(bankData?.accountNumber)}
                    >
                      <div className="grow shrink basis-0 h-5 justify-between items-center flex">
                        <div className="h-5 justify-start items-center gap-3 flex">
                          <div className="justify-start items-center gap-2 flex">
                            <div className="text-base font-normal leading-tight">
                              {bankData?.accountNumber}
                            </div>
                          </div>
                        </div>
                        <div className="w-5 h-5 relative">
                          {/* Replace with your FontAwesomeIcon */}
                          <FontAwesomeIcon icon={faArrowRightFromBracket} />
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
              <button
                onClick={() => {
                  setOpenAddBankModal(true);
                }}
                className="w-full text-md font-medium text-[#2D5BFE] mt-3"
              >
                + Add New Bank
              </button>
            </div>
          )}
          {currentComponent === 3 && (
            <div className="px-5 py-5">
              <>
                <p>
                  <span className="font-medium">Account No:</span>
                  <span className="">{selectedBankAccount}</span>
                </p>
                <p>
                  <span className="font-medium">You are sending:</span>
                  <span className="">
                    {sendAmount} {sendToken}
                  </span>
                </p>
                <p>
                  <span className="font-medium">You will receive:</span>
                  <span className="">
                    {receiveAmount - totalFee} {receiveToken}
                  </span>
                </p>
              </>
            </div>
          )}
          <div className="px-5 mb-10">
            {!loader ? (
              <button
                onClick={() => {
                  if (sendAmount > selectedtokenBalance) {
                    toast.error("You dont have enough  coin !!");
                  } else {
                    setCurrentComponent(currentComponent + 1);
                  }
                }}
                disabled={
                  !sendAmount ||
                  (!selectedBankAccount && currentComponent === 2)
                }
                className="  w-full py-2 text-md font-bold text-white bg-violet-900 border border-violet-900 rounded active:text-violet-500 hover:bg-transparent hover:text-violet-900 focus:outline-none focus:ring cursor-pointer"
              >
                Proceed
              </button>
            ) : (
              <button className="  w-full py-2 text-md font-bold text-white bg-violet-900 border border-violet-900 rounded active:text-violet-500 hover:bg-transparent hover:text-violet-900 focus:outline-none focus:ring">
                <CircularProgress size={16} />
              </button>
            )}
          </div>
        </div>
      </Modal>

      <AddBankModal
        openModal={openAddBankModal}
        setOpenModal={setOpenAddBankModal}
      />

      <SignupPageModal
        openModal={openSignupPageModal}
        setOpenModal={setOpenSignupPageModal}
        offRampSell={true}
        // recieverAddress={appCtx.walletAddress[0]?.address}
        // option={option}
        // amount={nftPrice}
        // nftId={nftId}
        // blockchainNftId={blockchainNftId}
        // setOpenModalPaymentFailure={setOpenModalPaymentFailure}
        // setOpenModalPaymentSuccess={setOpenModalPaymentSuccess}
        OffRampTransactionData={OffRampTransactionData}
      />
    </>
  );
};

export default TaleWalletOffRampModal;
