import {
  AccountBox,
  AccountCircle,
  CopyAll,
  Launch,
  RemoveRedEye,
  Storefront,
  Wallet,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getImageUrl, nativeToken } from "../../config";
import { useWalletView } from "../../hooks/useWalletView";

function NavWallet({ marketplace, wallet }) {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("wallet");
  const appCtx = useSelector((state) => state.app);

  const walletAddress = appCtx.walletAddress.find(
    (addr) => addr.blockchain === appCtx.blockchain
  )?.address;

  const { managersBalance, getFundManagersBalance } = useWalletView();

  useEffect(() => {
    getFundManagersBalance();
  }, [appCtx.walletAddress, appCtx.blockchain]);

  return (
    <div className={`flex flex-col gap-4 min-h-[270px] ${appCtx.isDarkMode}`}>
      <div className="flex flex-col">
        <div className="flex  px-[100px] pt-5 flex-wrap justify-evenly">
          <div className="flex flex-col grow">
            <div className="font-bold">Address Associated</div>
            <div>
              {`${walletAddress?.slice(0, 6)} .... ${walletAddress?.slice(
                walletAddress?.length - 3,
                walletAddress?.length
              )}`}
              <button
                onClick={() => {
                  navigator.clipboard.writeText(walletAddress);
                }}
              >
                <CopyAll fontSize="small" />
              </button>
            </div>
          </div>
          {/* <div className="flex flex-col grow">
            <div className="font-bold">Status</div>
            <div className="font-bold">
              {marketplace?.status === true ? (
                <div className="text-green-500">Active</div>
              ) : (
                <div className="text-black">Inactive</div>
              )}
            </div>
          </div> */}
        </div>
        <div className="p-3">
          <div className="flex flex-col border border-gray-400 rounded-lg overflow-hidden">
            <div className="flex flex-col items-center py-3">
              <div className="font-bold">Account balance</div>
              <div className="text-xl flex flex-col items-center">
                <div className="w-8 h-8 rounded-full overflow-hidden">
                  <img
                    src={getImageUrl(appCtx?.blockchain)}
                    className="object-contain"
                    alt="blockchain_image"
                  />
                </div>
                <div className="flex flex-col items-center">
                  <div className="font-bold">
                    {managersBalance} {nativeToken[appCtx?.blockchain]}
                  </div>{" "}
                  {managersBalance < 0.2 && (
                    <div className="text-red-600 text-xs">
                      Low balance , Please add funds
                    </div>
                  )}
                </div>
              </div>
            </div>
            <button
              onClick={() => window.open("https://www.talewallet.com/wallet")}
              className="bg-secBlue py-3 overflow-hidden text-white"
            >
              View on Tale wallet
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavWallet;
