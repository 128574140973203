import { Card, CardActions, CardContent, CardMedia } from "@mui/material";

import "./NftItem.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const OtherAssestsView = ({ item }) => {
  let metadatas = JSON.parse(item?.metadata);
  let hash = metadatas?.media_url?.split("://")[1];
  const navigate = useNavigate();

  return (
    <>
      <div className="mt-[10px] w-[100%] marketplace-nft-card group">
        <Card
          className=" h-[184px] sm:h-[270px] lg:h-[256px]"
          style={{ borderRadius: "14px" }}
          onClick={() => {
            navigate(`/asset/${item?.id}`);
          }}
        >
          <img
            className="  h-[140px] sm:h-[200px] w-full"
            src={`${process.env.REACT_APP_GATEWAY_IPFS}/${hash}`}
            alt="NFT"
          />

          <CardActions className="flex justify-between ml-[20px] mr-[20px] groupPrice">
            {/* <button size="small" className="flex">
                                <div>
                                    <div className="text-left">Price</div>
                                    <div className="font-bold text-[11px] ">{parsedMetaData.price ? parsedMetaData.price : "Not Listed"}</div>
                                </div>
                            </button> */}
            {/* <button size="small" className="flex"><div><div>Current Bid</div><div className='font-bold'>2 ETH</div></div></button> */}
          </CardActions>
          {/* {!domain && ( */}
          <button
            onClick={() => {
              // window.open("http://" + domain + "/" + nft.nftId);
            }}
            className="bg-green-500 text-slate-50 p-3 w-full rounded-b hidden mt-[-8px] lg:mt-[2px] sm:mt-[18px]  card_btn"
          >
            View NFT
          </button>
          <div className="flex justify-start ml-[10px] items-end h-[11%] sm:h-[12%] lg:h-[9%]">
            <div className="flex items-center groupTitle">
              <img
                src="/images/addresslogo.svg"
                alt="logo"
                className="w-[22px] h-[22px] rounded-full"
              />
              <div className="ml-[6px] text-[10px]">{metadatas?.name}</div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default OtherAssestsView;
