import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Close } from "@mui/icons-material";
import useAuthorizedHtttp from "../../../hooks/use-authorized-http";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axios from "axios";
import { usehandleBuyTaleCoin } from "../../../hooks/handleBuyTaleCoin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";
import { nativeToken } from "../../../config";
import { useParams } from "react-router";
import BuyModal from "../../BuyModal/BuyModal";

function AddFundModal({
  openModal,
  setOpenModal,
  clientData,
  clientId,
  clientDetails,
  useraddress,
}) {
  const [showComponent, setShowComponent] = useState(1);
  const [isCopied, setIsCopied] = useState(false);
  const [amount, setAmount] = useState(null);
  const [openBuyModal, setOpenBuyModal] = useState(false);

  const appCtx = useSelector((state) => state.app);
  const makeRequest = useAuthorizedHtttp();

  const { handleBuyAlgos } = usehandleBuyTaleCoin(appCtx);
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 9999,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      borderRadius: "15px",
      zIndex: 9999,
    },
  };

  function closeModal() {
    setOpenModal(false);
    setShowComponent(1);
    setIsCopied(false);
  }

  console.log("clientDetails", clientDetails);
  console.log("clientData", clientData);

  const handleSendPaymentLink = () => {
    makeRequest(
      {
        url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/fundMagnet/client/send/payment/link`,
        data: {
          address: clientData?.address,
          amount: amount,
          blockchain: clientData?.blockchain || "BSC",
          clientName: `${clientDetails?.firstName} ${clientDetails?.lastName}`,
          email: clientDetails?.email,
          mobile: clientDetails?.mobile,
          token: nativeToken[clientData?.blockchain] || "BNB",
          type: "subscribe/token",
          wmName: appCtx?.wealthManager?.name,
        },
        method: "post",
      },
      (data) => {
        // setLoader(false);
        toast.success("Payment link Send Successfull");
        closeModal();
        setAmount(null);
      },
      (error) => {
        toast.error(" Payment link Send failed !");
        // setLoader(false);
      },
      () => {}
    );
  };

  return (
    <>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="flex justify-end">
          <button className="" onClick={closeModal}>
            <Close />
          </button>
        </div>
        <div className="bg-opacity-50 flex justify-center items-center">
          <div className="">
            <div className="px-10 pb-8  flex flex-col gap-2">
              <p className="text-2xl font-bold mb-5">
                Add Fund To Your Client's Wallet
              </p>
              {showComponent === 1 && (
                <div className="flex flex-col gap-3">
                  <label className="text-xl font-bold text-center">
                    Enter Amount:
                  </label>
                  <input
                    type="number"
                    value={amount}
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                    placeholder="Enter Amount"
                    className="outline-none border border-gray-300 rounded-lg p-2 mt-2"
                  />

                  <button
                    onClick={() => {
                      setShowComponent(2);
                    }}
                    disabled={amount === null}
                    className="px-5 py-2 relative rounded group overflow-hidden font-medium bg-blue-100 text-blue-500  inline-block"
                  >
                    <span className="absolute top-0 left-0 flex w-full h-0 mb-0 transition-all duration-200 ease-out transform translate-y-0 bg-blue-600 group-hover:h-full opacity-90"></span>
                    <span className="relative group-hover:text-white text-sm">
                      Next
                    </span>
                  </button>
                </div>
              )}

              {showComponent === 2 && (
                <div className="flex flex-col gap-3">
                  <p>
                    Payment Link:
                    <span className="relative inline-flex items-center ml-3">
                      <input
                        type="text"
                        value={`https://www.talewallet.com/onramp?address=${
                          clientData?.address
                        }&blockchain=${clientData?.blockchain || "BSC"}`}
                        readOnly
                        className="outline-none border border-gray-300 rounded-lg p-2 mr-2"
                        onClick={(e) => e.target.select()}
                      />
                      <button
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `https://www.talewallet.com/onramp?address=${
                              clientData?.address
                            }&blockchain=${clientData?.blockchain || "BSC"}`
                          );
                          setIsCopied(true);
                        }}
                        className="bg-gray-100 hover:bg-gray-200 active:bg-gray-300 rounded-lg px-3 py-2 text-gray-600"
                      >
                        <FontAwesomeIcon icon={isCopied ? faCheck : faCopy} />
                      </button>
                    </span>
                  </p>

                  <button
                    onClick={() => {
                      handleSendPaymentLink();
                    }}
                    className="px-5 py-2 relative rounded group overflow-hidden font-medium bg-purple-100 text-purple-600 inline-block"
                  >
                    <span className="absolute top-0 left-0 flex w-full h-0 mb-0 transition-all duration-200 ease-out transform translate-y-0 bg-purple-600 group-hover:h-full opacity-90"></span>
                    <span className="relative group-hover:text-white text-sm">
                      Send Link Via Email
                    </span>
                  </button>
                  <button
                    onClick={() => {
                      // handleBuyAlgos(clientData?.address);
                      setOpenBuyModal(true);
                      closeModal();
                    }}
                    className="px-5 py-2 relative rounded group overflow-hidden font-medium bg-blue-100 text-blue-500  inline-block"
                  >
                    <span className="absolute top-0 left-0 flex w-full h-0 mb-0 transition-all duration-200 ease-out transform translate-y-0 bg-blue-600 group-hover:h-full opacity-90"></span>
                    <span className="relative group-hover:text-white text-sm">
                      Add Fund Yourself
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
      {openBuyModal && (
        <BuyModal
          openModal={openBuyModal}
          setOpenModal={setOpenBuyModal}
          useraddress={useraddress}
          amount={amount}
          setAmount={setAmount}
        />
      )}
    </>
  );
}

export default AddFundModal;
