import React, { useEffect, useState } from "react";
import useHttp from "../hooks/use-http";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useAuthorizedHttp from "../hooks/use-authorized-http";
import { CircularProgress, Skeleton } from "@mui/material";
import QuestionTypeModal from "../components/QuestionTypeModal/QuestionTypeModal";
import SendQuestionModal from "../components/SendQuestionModal/SendQuestionModal";
import { useNavigate, useParams } from "react-router";

const AddQuestion = () => {
  const [loader, setLoader] = useState(false);
  const [savingQuestion, setSavingQuestion] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const makeAuthorizedRequest = useAuthorizedHttp();
  const appCtx = useSelector((state) => state.app);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setLoader(true);
    makeAuthorizedRequest(
      {
        url: `${
          process.env.REACT_APP_URL_MARKETPLACE_SERVICE
        }/fund/magnet/risk/profile/question/list?wmUserId=${
          id === "General" ? 0 : 1
        }`,
        method: "get",
      },
      (data) => {
        const updatedQuestions = data.map((question) => {
          const { questionId, options, ...rest } = question;
          const parsedOptions = JSON.parse(options);
          return {
            ...rest,
            wmUserId: appCtx?.userDetails?.userId,
            options: parsedOptions,
          };
        });

        setQuestionList(updatedQuestions);
        setLoader(false);
      },
      (rej) => {
        setLoader(false);
      }
    );
  }, []);

  const handleSubmit = () => {
    setSavingQuestion(true);
    makeAuthorizedRequest(
      {
        url: `${process.env.REACT_APP_URL_MARKETPLACE_SERVICE}/fund/magnet/risk/profile/question/save`,
        data: questionList,
        method: "post",
      },
      (data) => {
        setSavingQuestion(false);
        toast.success("Questionnaire saved successfully");
        navigate("/risk-profiler");
      },
      (error) => {
        setSavingQuestion(false);
        toast.error("Failed to save questionnaire");
      },
      () => {}
    );
  };

  return (
    <div>
      <p className="text-3xl font-medium text-center">Add question</p>

      <div className="bg-white w-[80%] mx-auto my-10 min-h-[200px]">
        {loader ? (
          <div className="p-10">
            {Array.from({ length: 5 }, (_, index) => (
              <div key={index} className="flex flex-col ">
                <Skeleton
                  key={index}
                  variant="rectangular"
                  height={120}
                  className=" mt-[5px] w-[100%] mx-auto"
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="mx-auto p-8">
            {questionList.length !== 0 &&
              questionList.map((item, index) => {
                const isEvenIndex = index % 2 === 0;
                const backgroundColor = isEvenIndex ? "#f5f5f5" : "#ffffff";
                return (
                  <div
                    key={index}
                    className={`p-[20px] bg-[${backgroundColor}] border border-[#e5e5e5] mt-[5px]`}
                  >
                    <p className="text-md font-medium mt-2">
                      Q{index + 1}. {item.question}
                    </p>
                    <div className="flex flex-col w-[100%] mt-1">
                      <div className="flex flex-row flex-wrap gap-x-3">
                        {item?.options
                          .slice(0, 2)
                          .map((option, optionIndex) => (
                            <div key={optionIndex} className="w-[300px]">
                              <p className="text-md ">
                                {String.fromCharCode(97 + optionIndex)}.{" "}
                                {option.option}
                              </p>
                            </div>
                          ))}
                      </div>
                      <div className="flex flex-row flex-wrap gap-x-3">
                        {item?.options
                          .slice(2, 4)
                          .map((option, optionIndex) => (
                            <div key={optionIndex} className="w-[300px]">
                              <p className="text-md">
                                {String.fromCharCode(97 + optionIndex + 2)}.{" "}
                                {option.option}
                              </p>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                );
              })}

            <div className="flex justify-center mt-5">
              <button
                className="bg-gradient-to-r from-purple-700 to-purple-900 rounded-full  text-white px-20  py-2 md:py-2  "
                type="submit"
                onClick={handleSubmit}
              >
                Save{" "}
                <span>
                  {savingQuestion && (
                    <CircularProgress size={20} className="ml-[5px]" />
                  )}
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddQuestion;
