import { Search } from "@mui/icons-material";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuthorizedHttp from "../../../../hooks/use-authorized-http";
import { useWalletView } from "../../../../hooks/useWalletView";
import { toast } from "react-toastify";
import AlgorandClient from "../../../services/algorandsdk";
import Input from "../../../Input";
import DoneIcon from "@mui/icons-material/Done";
import CustomButton from "../../../CustomButton";
import ConfirmSendPaymentModal from "./ConfirmSendPayment";

function SendAlgosModal({
  openModal,
  setOpenModal,
  allCoin,
  allCoinPrice,
  usersToken,
  useraddress,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const makeAuthorizedRequest = useAuthorizedHttp();
  const [amount1, setAmount1] = useState(0);
  const [algos, setAlgos] = useState(0);
  const [openPayment, setOpenPayment] = useState(false);
  const [address, setAddress] = useState("");
  const [info, setInfo] = useState(false);

  const appCtx = useSelector((state) => state.app);
  const width = window.screen.width;
  const [openSetupPassphrase, setOpenSetupPassphrase] = useState(false);
  const [allTokenName, setAllTokenName] = useState(null);

  console.log("i am inside sendagosmodal");

  const [option, setOption] = useState(() => {
    if (appCtx.usersblockchain === "POLYGON") {
      return "MATIC";
    } else if (appCtx.usersblockchain === "BSC") {
      return "BNB";
    } else if (appCtx.usersblockchain === "ETHEREUM") {
      return "ETH";
    } else if (appCtx.usersblockchain === "FIRECHAIN") {
      return "5ire";
    } else {
      return "algo";
    }
  });

  const [icon, setIcon] = useState(null);

    const customStyles = {
      overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        zIndex: 9998,
      },
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        borderRadius: "15px",
        zIndex: 9999,
      },
    };

    function closeModal() {
      setOpenModal(false);
      setInfo(false);
      setAlgos(0);
    }

    const {
      amount,
      optedIn,
      showAssets,
      taleAmount,
      showTaleData,
      minBalance,
    } = useWalletView();

    // useEffect(() => {
    //   const address = useraddress;
    //   showTaleData(address);
    // }, []);

    const checkDollars = () => {
      makeAuthorizedRequest(
        {
          url: `${process.env.REACT_APP_URL_BLOCKCHAIN_SERVICE}/crypto/convert/algorand/${algos}/to/usd`,
          method: "get",
        },
        (data) => {
          setAmount1(data.usd);
        },
        (rej) => {
          setAmount1(0);
        }
      );
    };

    useEffect(() => {
      if (appCtx.isLoggedIn) {
        setOpenSetupPassphrase(false);
      }
    }, [appCtx.custodial, appCtx.isLoggedIn]);

    const handleNext = () => {
      if (appCtx.usersblockchain === "ALGORAND") {
        if (option !== "tale") {
          if (algos > 0) {
            if (algos <= amount) {
              setOpenPayment(true);
            } else {
              toast.error("You dont have enough Algos or Tale coin !!");
            }
          } else {
            toast.error(
              "Algos or Tale Coin you are sending should be greater than 0"
            );
          }
        } else {
          if (optedIn) {
            if (algos > 0) {
              if (algos <= taleAmount) {
                setOpenPayment(true);
              } else {
                toast.error("You dont have enough Algos or Tale coin !!");
              }
            } else {
              toast.error(
                "Algos or Tale Coin you are sending should be greater than 0"
              );
            }
          } else {
            toast.error(
              "You need to first optIn tale coin asset to send tale coins!!!"
            );
          }
        }
      } else {
        // need to modify this
        if (algos > 0) {
          if (algos <= getsinglebal) {
            setOpenPayment(true);
          } else {
            toast.error("You dont have enough coin !!!");
          }
        } else {
          toast.error("Coin you are sending should be greater than 0");
        }
      }
    };
    const handleConfirmChange = async (address) => {
      setAddress(address);
      showAssets();
      showTaleData();
      if (address !== appCtx.walletAddress[0]?.address) {
        try {
          if (appCtx.usersblockchain === "ALGORAND") {
            let accountInfo = await AlgorandClient.accountInformation(
              address
            ).do();
            if (accountInfo) {
              setInfo(true);
            } else {
              setInfo(false);
            }
          } else {
            setInfo(true);
          }
        } catch {
          // toast.error('Please enter algorand wallet address !!!');
          setInfo(false);
          setAlgos(0);
        }
      } else {
        toast.error("You cannot transfer Coin to your own account ");
      }
    };

    useEffect(() => {
      const allTokenShortName = allCoin?.map((token) => token?.tokenShortName);
      setAllTokenName(allTokenShortName);
    }, []);

    const getsinglebal =
      usersToken &&
      usersToken.some(
        (singleToken) =>
          singleToken.token === option &&
          singleToken.blockchain === appCtx.usersblockchain
      )
        ? usersToken
            .filter(
              (singleToken) =>
                singleToken.blockchain === appCtx.usersblockchain &&
                singleToken.token === option
            )
            .map((singleToken) => `${singleToken.balance}`)
            .join("")
        : "0";

    console.log("getsinglebal", getsinglebal);
    return (
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className=" flex justify-end pr-[20px] pt-[10px]">
          <button
            className=" font-medium text-xl flex items-center leading-[10px] mr-[10px] mt-[10px]"
            onClick={closeModal}
          >
            X
          </button>
        </div>
        <div className="flex flex-col justify-center items-center px-[20px] pb-[30px] gap-5 ">
          <div className="font-medium text-[20px]">Send To</div>
          <div className="w-[100%] h-[64px]">
            <div className="relative">
              <Input
                className={`w-full rounded-lg border border-gray-300 pl-30 pr-7 text-12px`}
                placeholder={`Paste ${appCtx.usersblockchain.toLowerCase()} wallet address`}
                onChange={(e) => {
                  handleConfirmChange(e.target.value);
                }}
              />
              {!info ? (
                <Search className="absolute right-7 top-1/2 transform -translate-y-1/2 text-gray-300" />
              ) : (
                <DoneIcon className="absolute right-7 top-1/2 transform -translate-y-1/2 text-white bg-green-400 rounded-full" />
              )}
            </div>
          </div>
          {info ? (
            <>
              <div className="w-[100%] flex gap-10 items-center ">
                <div>Asset:</div>
                <div className="w-full flex gap-2 justify-start text-[13px] items-center border border-gray-300 p-2">
                  <div>
                    {appCtx.usersblockchain === "ALGORAND" ? (
                      <img
                        src={
                          option === "tale"
                            ? `/images/talecoinstack.svg`
                            : `/images/Algorand.png`
                        }
                        className="w-[60px]"
                        alt="Algorand"
                      />
                    ) : (
                      <img
                        src={
                          allCoin
                            ? allCoin.find(
                                (obj) => obj.tokenShortName === option
                              )?.tokenIcon
                            : ""
                        }
                        className="w-[60px]"
                        alt={option}
                      />
                    )}
                  </div>
                  <div className="flex flex-col w-[100%] justify-between">
                    <div>Balance: </div>
                    <div>{`${
                      appCtx.usersblockchain === "ALGORAND"
                        ? option === "tale"
                          ? `${taleAmount} Tale`
                          : `${amount} Algo`
                        : `$${getsinglebal}`
                    }`}</div>
                  </div>
                  <div className=" top-[-13px] p-1 border border-gray-300">
                    {appCtx.usersblockchain === "ALGORAND" ? (
                      <select
                        value={option}
                        onChange={(e) => {
                          setOption(e.target.value);
                          setAlgos(0);
                        }}
                      >
                        <option value="algo">Algo</option>
                        <option value="tale">Tale Coin</option>
                      </select>
                    ) : (
                      <select
                        value={option}
                        onChange={(e) => {
                          setOption(e.target.value);
                          setAlgos(0);
                        }}
                      >
                        {allTokenName &&
                          allTokenName.map((tokenName) => (
                            <option key={tokenName} value={tokenName}>
                              {tokenName}
                            </option>
                          ))}
                      </select>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-[100%] flex gap-5 items-center ">
                <div>Amount:</div>
                <div className="w-full flex gap-2 justify-start text-[13px] items-center border border-gray-300 p-2">
                  <div>
                    {appCtx.usersblockchain === "ALGORAND" ? (
                      <img
                        src={
                          option === "tale"
                            ? `/images/talecoinstack.svg`
                            : `/images/Algorand.png`
                        }
                        className="w-[30px]"
                        alt="Algorand"
                      />
                    ) : (
                      <img
                        src={
                          allCoin
                            ? allCoin.find(
                                (obj) => obj.tokenShortName === option
                              )?.tokenIcon
                            : ""
                        }
                        className="w-[35px]"
                        alt={option}
                      />
                    )}
                  </div>
                  <div>
                    <div className="flex gap-2">
                      <div>
                        <Input
                          className="w-[100px] h-[20px] rounded-none"
                          placeholder={`Enter algos`}
                          value={algos}
                          onChange={(e) => {
                            setAlgos(e.target.value);
                            setAmount1(0);
                          }}
                        />
                      </div>
                      {appCtx.usersblockchain === "ALGORAND" ? (
                        option === "tale" ? (
                          <div>Tale</div>
                        ) : (
                          <div>Algo</div>
                        )
                      ) : (
                        option
                      )}
                      {appCtx.usersblockchain === "ALGORAND" &&
                        option !== "tale" && (
                          <CustomButton
                            className={`h-[20px] flex justify-center items-center rounded-lg w-[50px] ml-[20px]`}
                            onClick={() => {
                              checkDollars();
                            }}
                          >
                            check
                          </CustomButton>
                        )}
                    </div>
                    {appCtx.usersblockchain === "ALGORAND" &&
                      option !== "tale" &&
                      amount1 !== 0 &&
                      amount1 && <div>{amount1} USD</div>}
                  </div>
                </div>
              </div>
              <div className="w-[100%] flex justify-center gap-5">
                <CustomButton
                  primary={false}
                  className="w-[200px] h-[40px] "
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Canel
                </CustomButton>
                <CustomButton
                  primary={true}
                  className="w-[200px] h-[40px] "
                  onClick={() => {
                    if (appCtx.usersblockchain === "ALGORAND") {
                      if (
                        parseFloat(
                          parseFloat(amount) - parseFloat(algos) - 0.01
                        ) > parseFloat(minBalance)
                      ) {
                        if (amount1 === 0 && algos !== 0) checkDollars();
                        handleNext();
                      } else {
                        toast.error(
                          "You don't have enough token to pay the gas fee"
                        );
                      }
                    } else {
                      if (algos + 0.01 <= getsinglebal) {
                        handleNext();
                      } else {
                        toast.error(
                          "You don't have enough token to pay the gas fee"
                        );
                      }
                    }
                  }}
                >
                  Next
                </CustomButton>
              </div>
            </>
          ) : (
            <>
              <div className="w-[100%] bg-purple-100 border border-purple-300 px-[20px] rounded-lg h-[80px] flex flex-col justify-center items-start">
                <spam className="font-medium">Note :-</spam>
                <div className="font-normal text-[14px]">
                  You must enter an {appCtx.usersblockchain} address to transfer
                  Coins
                </div>
              </div>
            </>
          )}
        </div>

        <ConfirmSendPaymentModal
          openModal={openPayment}
          setOpenModal={setOpenPayment}
          setPreviousOpen={setOpenModal}
          address={address}
          amount={amount1}
          algos={algos}
          setAlgos={setAlgos}
          showAssets={showAssets}
          setInfo={setInfo}
          option={option}
        />
      </Modal>
    );
}

export default SendAlgosModal;
