import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../../context/app-slice";

const Profile = () => {
  const appCtx = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const [wealthManagerLoaded, setWealthManagerLoaded] = useState(false);
  const [wealthManagersData, setWealthManagersData] = useState(null);

  //   useEffect(() => {
  //     let config = {
  //       method: "get",
  //       url: `${process.env.REACT_APP_NFTVERSE_DEV_API}/wealth/manager/profile?userId=${appCtx?.userDetails?.userId}`,
  //       headers: {
  //         "X-Auth-Token": appCtx.authToken,
  //         "X-App-Token": process.env.REACT_APP_APP_TOKEN,
  //         "Content-Type": "application/json",
  //       },
  //     };

  //     axios
  //       .request(config)
  //       .then((response) => {
  //         dispatch(appActions.setWealthManager(response.data));
  //         setWealthManagerLoaded(true);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }, []);
  return (
    <div className="bg-gray-100">
      <div className="bg-gray-800 h-48">
        {/* Cover Image */}
        {appCtx?.wealthManager?.coverImage && (
          <img
            src={appCtx?.wealthManager?.coverImage}
            alt="Cover Image"
            className=""
          />
        )}
      </div>
      <div className="px-4 py-6 sm:px-6 max-w-3xl mx-auto">
        {/* Profile Picture */}
        {appCtx?.wealthManager?.profilePic ? (
          <img
            src={appCtx?.wealthManager?.profilePic}
            alt="Profile Picture"
            className="w-24 h-24 rounded-full mx-auto mb-4"
          />
        ) : (
          <div className="w-24 h-24 bg-gray-500 rounded-full mx-auto mb-4"></div>
        )}

        <div className="flex flex-col justify-center text-center">
          {/* Name */}
          <h1 className="text-3xl font-bold text-gray-800 mb-2">
            {appCtx?.wealthManager?.name}
          </h1>

          {/* Bio */}
          <p className="text-lg text-gray-600 mb-4">
            {appCtx?.wealthManager?.bio}
          </p>

          {/* Status */}
          {appCtx?.wealthManager?.status && (
            <p className="text-green-500 font-medium">
              {appCtx?.wealthManager?.status}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
